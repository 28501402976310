import React, { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { CartApiType, CartViewProps } from "./types"
import ShoppingCartView from "../shopping-cart-view"
import { Checkbox } from "primereact/checkbox"
import { useDispatch, useSelector } from "react-redux"
import {
  setBuyNowId,
  updateCart,
  updateSystemVoucher,
} from "src/features/shopping-cart"
import CartEmptyPage from "src/components/order-cart-view/empty-view"
import {
  CART_TYPE,
  ORDER_TAB_TYPE,
  ORDER_TYPE_MODE,
  SERVICE,
} from "src/constants/common"
import SkeletonList from "src/components/skeleton-list"
import {
  CartType,
  DeleteProductType,
  FeeShipType,
  ObjectDtoType,
} from "../type"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { configHeader } from "src/config/interceptors"
import type { RootState } from "src/app/store"
import {
  VoucherApplyType,
  VoucherType,
} from "src/components/modal/select-voucher-modal/types"
import EmptyPage from "src/pages/empty-page"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"
import { mathRoundNumber } from "src/utils/common"

export default function CartView(props: CartViewProps) {
  const { t } = useTranslation()
  const { stepView, isSearch, tab } = props
  const dispatch = useDispatch()
  const [cartData, setCartData] = useState<CartType[]>([])
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRenderView, setIsRenderView] = useState(false)
  const [isSuspendMode, setIsSuspendMode] = useState<boolean>(false)
  const authStore = useSelector((state: RootState) => state.auth)
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  // update detail of product shop
  const updateShopProduct = (newObjectDto: ObjectDtoType[], cartId: string) => {
    const newCarts = [] as CartType[]
    cartData.forEach((cart) => {
      if (cart.cartId === cartId) {
        newCarts.push({
          ...cart,
          objectDto: newObjectDto,
          shipVoucher: undefined,
          shopVoucher: undefined,
          systemVoucher: undefined,
        })
      } else {
        if (tab === ORDER_TYPE_MODE.ONLINE) newCarts.push({ ...cart })
        else {
          const newObj = cart?.objectDto?.map((o) => {
            return { ...o }
          })
          newObj.forEach((newO) => (newO.isSelected = false))
          newCarts.push({ ...cart, objectDto: newObj })
        }
      }
    })
    updateCartStore(newCarts)
  }
  const updateShopVoucher = (
    voucherShop: VoucherApplyType | undefined,
    shipVoucher: VoucherApplyType | undefined,
    systemVoucher: VoucherType | undefined,
    cartId: string
  ) => {
    const newCarts = [] as CartType[]
    cartData.forEach((cart) => {
      if (cart.cartId === cartId) {
        newCarts.push({
          ...cart,
          shopVoucher: voucherShop,
          shipVoucher: shipVoucher,
          systemVoucher: systemVoucher,
        })
      } else {
        newCarts.push({ ...cart })
      }
    })
    updateCartStore(newCarts)
  }
  const updateShopNote = (newNote: string, cartId: string) => {
    const newCarts = [] as CartType[]
    cartData.forEach((cart) => {
      if (cart.cartId === cartId) {
        newCarts.push({ ...cart, shopNote: newNote })
      } else {
        newCarts.push({ ...cart })
      }
    })

    updateCartStore(newCarts)
  }
  const updateShopFeeShip = (
    shopFeeShip: FeeShipType | undefined,
    cartId: string
  ) => {
    const newCarts = [] as CartType[]
    cartData.forEach((cart) => {
      if (cart.cartId === cartId) {
        newCarts.push({ ...cart, feeShip: shopFeeShip })
      } else {
        newCarts.push({ ...cart })
      }
    })

    updateCartStore(newCarts)
  }

  useEffect(() => {
    setIsRenderView(true)
  }, [])
  const getProductList = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productData: any[],
    entityId: string,
    isSuspension: boolean,
    isMarket: boolean,
    isAccept: string
  ) => {
    const result = [] as ObjectDtoType[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productData.forEach((obj: any) => {
      if (
        obj.quantity > 0 &&
        !isSuspension &&
        obj?.is_market_public &&
        isMarket &&
        isAccept === "ACCEPTED"
      ) {
        result.push({
          id: obj?.id,
          entityId: entityId,
          objectId: obj?.object_Id,
          objectImgUrl: obj?.avatar?.id,
          quantity: obj?.quantity,
          title: obj?.name,
          variation: obj?.stock?.variation_second_name
            ? `${obj?.stock?.variation_first_name}/${obj?.stock?.variation_second_name}`
            : obj?.stock?.variation_first_name,
          isSelected:
            shoppingCartStore.buyNowId === obj?.stock_detail?.id &&
            !isSuspension &&
            obj?.is_market_public &&
            isMarket &&
            isAccept === "ACCEPTED",
          stockPriceFull: obj?.stock_pricing_fulls,
          stockDetail: obj?.stock_detail,
          isFlashDeal: obj?.is_flash_deal,
          flashDeal: obj?.flash_deal || undefined,
          isLiveStream: obj?.is_livestream_deal,
          liveStreamDeal: obj?.livestream_deal || undefined,
          liveToken: obj?.token || "",
          isDisplay: true,
          tax: obj?.shop_tax,
          is_market_public: obj?.is_market_public || false,
          catalogs: obj?.catalogs || undefined,
          limit_per_item: obj?.limit_per_item,
        } as ObjectDtoType)
      }
    })
    return result
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapperCartData = (dataRes: any) => {
    const result = [] as CartType[]
    dataRes.forEach((res: CartApiType) => {
      result.push({
        cartId: res?.id,
        cartImg: res?.avatar?.id,
        cartName: res?.name,
        is_suspension_mode: res.is_suspension_mode,
        isShopActive:
          res?.is_market_public && res?.accepted_status === "ACCEPTED",
        objectDto: getProductList(
          res?.objectDTOs,
          res?.id || "",
          res.is_suspension_mode || false,
          res?.is_market_public || false,
          res?.accepted_status || ""
        ),
        shopVoucher: undefined,
        shipVoucher: undefined,
        systemVoucher: undefined,
        shopNote: "",
        feeShip: undefined,
        isInvoice: res?.is_invoice_exact,
        isShopInvoice: false,
        shopInvoice: undefined,
        shopAddress: {
          name: res?.shop_address?.name || "",
          phone: res?.shop_address?.phone || "",
          street: res?.shop_address?.street || "",
          ward: res?.shop_address?.ward?.id || "",
          district: res?.shop_address?.district?.id || "",
          city: res?.shop_address?.province?.id || "",
        },
        productOrder: [],
        limit_per_item: res?.limit_per_item,
      })
    })
    return result
  }

  const updateCartStore = (cartData: CartType[]) => {
    dispatch(updateCart(cartData))
    setCartData(cartData)
  }

  const getCartApi = () => {
    setIsLoading(true)
    updateCartStore([])
    const tabType =
      tab === ORDER_TYPE_MODE.ONLINE
        ? ORDER_TAB_TYPE.ALL
        : ORDER_TAB_TYPE.PURCHASE_DIRECT
    new CartControllerApi(configHeader(SERVICE.CART))
      .cartsMyCartGet(CART_TYPE.PRODUCT, "", tabType)
      .then((res) => {
        if (res?.data?.data?.entityDTOS?.length) {
          const cartDataRes = mapperCartData(res.data.data.entityDTOS)?.filter(
            (i) => i?.objectDto.length
          )
          updateCartStore(cartDataRes)
          dispatch(setBuyNowId(""))
          dispatch(updateSystemVoucher(undefined))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }
  async function fetchDataView() {
    if (authStore.value) getCartApi()
    else {
      if (isLoading) setIsLoading(false)
    }
  }
  useEffect(() => {
    if (isRenderView) fetchDataView()
  }, [stepView, isRenderView, tab])

  // handle when click check all
  const updateSelectedProductWhenCheck = (isCheck: boolean, cartId: string) => {
    const newCart = [] as CartType[]
    cartData.forEach((cart) => {
      if (cart?.cartId === cartId) {
        const newObj = cart?.objectDto?.map((o) => {
          return { ...o }
        })
        newObj.forEach((newO) => {
          if (newO.is_market_public) {
            newO.isSelected = isCheck
          }
        })
        newCart.push({ ...cart, objectDto: newObj })
      } else {
        if (tab === ORDER_TYPE_MODE.ONLINE) newCart.push(cart)
        else {
          const newObj = cart?.objectDto?.map((o) => {
            return { ...o }
          })
          newObj.forEach((newO) => (newO.isSelected = false))
          newCart.push({ ...cart, objectDto: newObj })
        }
      }
    })
    updateCartStore(newCart)
  }
  // handle click select all product of all shop
  const handleCheckAll = (isCheck: boolean) => {
    const newCart: CartType[] = []
    cartData.forEach((cart) => {
      const newObj = cart?.objectDto.map((obj) => {
        return { ...obj }
      })
      newObj.forEach((p) => {
        if (p.is_market_public) {
          p.isSelected = isCheck
        }
      })
      newCart.push({ ...cart, objectDto: newObj })
    })
    updateCartStore(newCart)
    if (isCheck) {
      // GA CHECK ALL
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataSelect = [] as any[]
      newCart.forEach((cart) => {
        cart.objectDto.forEach((prod) => {
          dataSelect.push({
            item_id: prod.id,
            item_name: prod.title,
            price: mathRoundNumber(prod.stockDetail.price_after_tax),
            quantity: 1,
          })
        })
      })
      const gaDataRequest = {
        item_list_id: "CART_LIST_SELECT",
        item_list_name: "Select all",
        items: dataSelect,
      }
      gaPostAction(TAG_TYPE.SELECT_ITEM, gaDataRequest)
    }
  }

  useEffect(() => {
    let selectedProduct = 0
    let countObj = 0
    setIsSuspendMode(false)
    cartData.forEach((cart) => {
      if (cart.is_suspension_mode) {
        setIsSuspendMode(cart.is_suspension_mode)
      }
      const newObj = cart.objectDto?.map((obj) => {
        return { ...obj }
      })
      newObj.forEach((obj) => {
        countObj += 1
        if (obj.isSelected) {
          selectedProduct += 1
        }
      })
    })
    setIsCheckAll(countObj === selectedProduct)
  }, [cartData])

  useEffect(() => {
    setCartData(shoppingCartStore.cartData)
  }, [shoppingCartStore.cartData])

  const deleteProduct = (data: DeleteProductType) => {
    const result: CartType[] = []
    let dataDelete = undefined as ObjectDtoType | undefined
    cartData.forEach((cart) => {
      if (cart?.cartId === data?.cartId) {
        if (cart?.objectDto.length) {
          const dObj: ObjectDtoType[] = []
          cart?.objectDto?.forEach((obj) => {
            if (obj.id !== data.productConfirm) {
              dObj.push({ ...obj })
            } else {
              dataDelete = obj
            }
          })
          cart = { ...cart, objectDto: dObj }
          if (cart?.objectDto?.length) {
            result.push({ ...cart })
          }
        }
      } else {
        result.push({ ...cart })
      }
    })
    if (authStore.value) {
      new CartControllerApi(configHeader(SERVICE.CART))
        .cartsIdDelete(data?.productConfirm as string)
        .then(() => {
          updateCartStore(result)
          if (data.callback) {
            data.callback()
          }
          if (dataDelete) {
            const gaData = {
              currency: "USD",
              value:
                mathRoundNumber(
                  dataDelete?.quantity * dataDelete?.stockDetail.price_after_tax
                ) || 0,
              items: [
                {
                  item_id: dataDelete.id,
                  item_name: dataDelete.title,
                  price: dataDelete.stockDetail.price_after_tax,
                  quantity: 1,
                },
              ],
            }
            gaPostAction(TAG_TYPE.REMOVE_FROM_CART, gaData)
          }
        })
        .catch((err) => console.log(err))
    } else {
      updateCartStore(result)
      if (data.callback) {
        data.callback()
      }
    }
  }

  const isHaveData = useMemo(() => {
    let dataDisplays = 0
    if (cartData.length) {
      cartData.forEach((cart) => {
        cart.objectDto.forEach((obj) => {
          if (obj.isDisplay) dataDisplays += 1
        })
      })
    }
    return dataDisplays > 0
  }, [cartData])

  // dummy loading for search
  useEffect(() => {
    if (isSearch) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isSearch])

  useEffect(() => {
    if (isHaveData && !isLoading && cartData.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const itemData = [] as any[]
      cartData.forEach((shop) => {
        shop.objectDto.forEach((p) => {
          itemData.push({
            item_id: p.id,
            item_name: p.title,
            price: mathRoundNumber(p.stockDetail.price_after_tax),
            quantity: p.quantity,
          })
        })
      })
      const gaData = {
        item_list_id: "MY_CART",
        item_list_name: "SELECT_ALL_ITEM",
        items: itemData,
      }
      gaPostAction(TAG_TYPE.VIEW_ITEM_LIST, gaData)
    }
  }, [isHaveData, isLoading, cartData])

  if (isLoading) {
    return (
      <div className="flex w-full">
        <SkeletonList />
      </div>
    )
  }

  return (
    <div className="flex flex-1 flex-col">
      {isHaveData ? (
        <>
          {!isSearch ? (
            <div className="flex w-full items-center justify-between truncate rounded-3 border border-gray-200 bg-white px-3 py-14px">
              {tab === ORDER_TYPE_MODE.ONLINE ? (
                <div>
                  <Checkbox
                    checked={isCheckAll}
                    onChange={(e) => handleCheckAll(e.checked as boolean)}
                    disabled={isSuspendMode}
                  />
                </div>
              ) : null}
              <div className="text-2 ml-1 flex flex-1 items-center font-medium leading-18 text-gray-600">
                <div className="flex-1"></div>
                <div className="flex w-full items-center justify-start pl-3 text-12 font-medium md:w-[110px]">
                  {capitalizedFirst(t("global.unitPrice"))}
                </div>
                <div className="flex w-full items-center justify-center text-12 font-medium md:w-[110px]">
                  {capitalizedFirst(t("global.quantity"))}
                </div>
                <div className="flex w-full items-center justify-start pl-3 text-12 font-medium md:w-[100px]">
                  {capitalizedFirst(t("global.totalPrice"))}
                </div>
                <div className="hidden w-full items-center justify-start pl-3 text-12 font-medium md:flex md:w-[50px]"></div>
              </div>
            </div>
          ) : null}
          <div className={`flex flex-col ${isSearch ? "" : "mt-1"}`}>
            {cartData.map((cart, index) => (
              <ShoppingCartView
                key={index}
                index={index}
                updateShopProduct={updateShopProduct}
                updateShopVoucher={updateShopVoucher}
                updateShopNote={updateShopNote}
                updateFeeShip={updateShopFeeShip}
                updateSelectAll={updateSelectedProductWhenCheck}
                deleteProduct={deleteProduct}
                refetch={fetchDataView}
                tab={tab}
                {...cart}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="h-full w-full">
            {isSearch ? (
              <EmptyPage
                message={capitalizedFirst(t("global.no-data-found"))}
              />
            ) : (
              <CartEmptyPage />
            )}
          </div>
        </>
      )}
    </div>
  )
}
