/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react"
import { Dialog } from "primereact/dialog"
import {
  ItemRatingViewProps,
  RatingProductModalProps,
  NoteReviewType,
  productDetail,
} from "./props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import ItemRatingView from "./item-rating-view"
import { ratingService } from "src/services"
import { handlePromiseAll, handlePromiseAllNoRejected } from "src/utils/common"
import { FactorListType } from "src/services/rating-service/props"
import EmptyPage from "src/pages/empty-page"
import i18n from "src/locales"
import {RatingControllerApi} from "@soctrip/axios-rating-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
export default function RatingProductModal(props: RatingProductModalProps) {
  const { t } = useTranslation()
  const {
    isShow,
    handleClose,
    handleConfirm,
    isRated,
    ratingIds,
    products,
    orderId,
    shopCode,
    ratingProductList,
  } = props
  const [ratingProductData, setRatingProductData] =
    useState<ItemRatingViewProps[]>()
  const [notesReview, setNotesReview] = useState<NoteReviewType[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const handleSubmit = () => {
    const mutateRatingApis = [] as any[]
    const getRatingApis = [] as any[]
    ratingProductList(ratingProductData)
    ratingProductData?.forEach((i) => {
      const productId = i.object_id as string
      getRatingApis.push(ratingService.getRating([productId]))
    })

    handlePromiseAllNoRejected(getRatingApis).then((data) => {
      setIsSubmitting(true)
      data.forEach((res, i) => {
        // const ratingData = res?.data?.data?.data
        const initRating = ratingProductData?.[i]
        if (initRating) {
          mutateRatingApis.push(
            ratingService.createRating({
              object_id: initRating.object_id as string,
              object_type: "PRODUCT",
              content: initRating.content as string,
              rating: initRating.rating,
              notes: initRating.notes || [],
              medias: initRating.medias || [],
              factors:
                getFactorList(initRating?.productDetail as productDetail) ||
                [],
              object_code: shopCode,
            })
          )
          // if (ratingData?.length > 0) {
          //   mutateRatingApis.push(
          //     ratingService.updateRating(ratingData?.[0]?.id ?? "", {
          //       object_id: initRating.object_id as string,
          //       object_type: "PRODUCT",
          //       content: initRating.content as string,
          //       rating: initRating.rating,
          //       notes: initRating.notes || [],
          //       medias: initRating.medias || [],
          //       factors:
          //         getFactorList(initRating?.productDetail as productDetail) ||
          //         [],
          //       object_code: shopCode,
          //     })
          //   )
          // } else {
          //   mutateRatingApis.push(
          //     ratingService.createRating({
          //       object_id: initRating.object_id as string,
          //       object_type: "PRODUCT",
          //       content: initRating.content as string,
          //       rating: initRating.rating,
          //       notes: initRating.notes || [],
          //       medias: initRating.medias || [],
          //       factors:
          //         getFactorList(initRating?.productDetail as productDetail) ||
          //         [],
          //       object_code: shopCode,
          //     })
          //   )
          // }
        }
      })

      handlePromiseAll(mutateRatingApis)
        .then(() => {
          handleConfirm()
          setIsSubmitting(false)
        })
    })
  }

  const getFactorList = (proDetail: productDetail) => {
    const result: FactorListType[] = []
    proDetail.variation.forEach((i) => {
      result.push({
        score: 0,
        id: proDetail.productId,
        type: i.type,
        value: i.value,
      })
    })
    result.push({
      id: orderId,
      type: "ORDER",
      value: "",
      score: 0,
    })
    return result
  }

  const getNotes = () => {
    const language = i18n.language
    return ratingService.getNote(language).then((res) => {
      if (res?.data?.data && res?.data?.data.length) {
        const notesResult: NoteReviewType[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res?.data?.data.forEach((n: any) => {
          notesResult.push({
            id: n?.id,
            name: n?.content,
          })
        })
        setNotesReview(notesResult)
      }
    })
  }

  const getCurrentRating = () => {
    return new RatingControllerApi(configHeader(SERVICE.RATING)).ratingsCodeGet("PRODUCT", ratingIds[0] || "", shopCode || "shopCode").then((res:any) => {
      if (res?.data?.data && res?.data?.data?.length) {
        const ratingResult: ItemRatingViewProps[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res?.data?.data.forEach((i: any) => {
          ratingResult.push({
            id: i?.id,
            object_id: i?.object_id,
            rating: i?.rating,
            object_type: i?.object_type,
            notes: i?.notes,
            content: i?.content,
            medias: i?.medias,
            productDetail: products.find((p) => p.productId === i?.object_id),
          })
        })
        setRatingProductData(ratingResult)
      }
    }).catch(err => console.log(err))
  }
  const initDataRate = () => {
    const ratingResult: ItemRatingViewProps[] = []
    products.forEach((p) => {
      ratingResult.push({
        id: p?.id as string,
        object_id: p.productId,
        rating: 5,
        object_type: "STOCK",
        notes: [],
        content: "",
        medias: [],
        productDetail: p,
      })
    })
    if (ratingResult.length) setRatingProductData(ratingResult)
  }

  useEffect(() => {
    async function fetchData() {
      const apis = []
      if (isRated) apis.push(getCurrentRating())
      apis.push(getNotes())
      handlePromiseAll(apis).then(() => {
        if (!isRated) initDataRate()
      })
    }

    fetchData()
  }, [])

  const isValidForm = useMemo(() => {
    const isNotContent = ratingProductData?.find(
      (rating) => rating.content?.trim()?.length == 0
    )
    return !isNotContent
  }, [ratingProductData])

  const updateRatingItem = (rateItem: ItemRatingViewProps) => {
    const result: ItemRatingViewProps[] = []

    ratingProductData?.forEach((i) => {
      if (i.id === rateItem.id) {
        result.push({ ...rateItem })
      } else {
        result.push({ ...i })
      }
    })
    setRatingProductData(result)
  }

  const FooterContent = (
    <div className="flex justify-end text-16 font-semibold leading-24 mt-3">
      {!isRated ? (
        <div className="flex items-center">
          <button
            className="rounded-3 border border-gray-300  py-10px px-18px text-gray-700 hover:bg-gray-100"
            onClick={() => handleClose()}
          >
            {capitalizedFirst(t("global.cancel"))}
          </button>
          <button
            disabled={!isValidForm || isSubmitting}
            className={`ml-2 flex items-center gap-1 rounded-3 border py-10px px-18px text-white  ${
              isValidForm
                ? "border-blue-500 bg-blue-500 hover:bg-blue-600"
                : "border-blue-300 bg-blue-300"
            }`}
            onClick={handleSubmit}
          >
            {capitalizedFirst(t("global.submit"))}
            {isSubmitting && (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1rem" }}
              ></i>
            )}
          </button>
        </div>
      ) : (
        <button
          className="rounded-3 border border-gray-300 py-10px px-18px text-gray-700 hover:bg-gray-100"
          onClick={() => handleClose()}
        >
          {capitalizedFirst(t("global.close"))}
        </button>
      )}
    </div>
  )

  return (
    <Dialog
      draggable={false}
      header={capitalizedFirst(t("modal.ratingTile"))}
      visible={isShow}
      footer={FooterContent}
      onHide={() => handleClose()}
    >
      <div className="flex w-full md:w-[640px] flex-col px-4 pb-4">
        <div className="flex flex-col">
          {!ratingProductData?.length && (
            <div className="h-[300px] w-full">
              <EmptyPage message="No data found." />
            </div>
          )}
          {ratingProductData?.map((r, index) => (
            <ItemRatingView
              isRated={isRated}
              notesReview={notesReview}
              index={index}
              key={index}
              {...r}
              updateRatingItem={updateRatingItem}
            />
          ))}
        </div>
      </div>
    </Dialog>
  )
}
