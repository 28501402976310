import { CartType } from "src/components/order-cart-view/type"

export interface CartDetail {
  id: string
  count: number
  entity_id: string
  stock_id: string
}

export interface SystemVoucherType {
  id: string
  value: number
  code: string
}
export interface MyCartType {
  cartData: CartType[]
  totalCart: number
  buyNowId: string
  systemVoucher: undefined | SystemVoucherType
}

export const initialState: MyCartType = {
  cartData: [],
  totalCart: 0,
  buyNowId: "",
  systemVoucher: undefined,
}
