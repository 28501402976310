import React, { useEffect, useRef, useState } from "react"
import SkeletonLoading from "../skeleton-loading"
import CustomCard from "../card-custom/card-custom/custom-card"
import EmptyPage from "src/pages/empty-page"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { CustomCardProps } from "../card-custom/card-custom/custom-card-props"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { MostProps } from "src/pages/most-view-page/type"
import useOnScreen from "src/hooks/use-on-screen"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function BestReviewedView({
  bloom_filter,
  handleReload,
  sponsorDataCount,
  isLoading,
  updateLoading,
}: MostProps) {
  const { t } = useTranslation()
  const loadingRef = useRef(null)
  const isOnScreen = useOnScreen(loadingRef)
  const [productList, setProductList] = useState<CustomCardProps[]>([])
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [loadMoreYourMarket, setLoadMoreYourMarket] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [pageYourMarket, setPageYourMarket] = useState<number>(0)

  const handleGetProductList = async () => {
    updateLoading(true)
    const localRegion = localStorage.getItem("localRegion") || ""
    await new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchGet(pageYourMarket, 20, "point>=4", "-sum_review|int", {
        headers: {
          region: localRegion,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setTotalProducts(res?.data?.data?.totalElement)
        setProductList(res?.data?.data?.data)
        updateLoading(false)
        setIsFirstLoad(false)
      })
      .catch(() => {
        updateLoading(false)
      })
  }

  const handleGetMoreProducts = async () => {
    if (productList?.length < totalProducts) {
      const curPage = pageYourMarket + 1
      setPageYourMarket(curPage)
      setLoadMoreYourMarket(true)
      const localRegion = localStorage.getItem("localRegion") || ""
      await new StockProductControllerApi(configHeader(SERVICE.STOCK))
        .productsSearchGet(curPage, 20, "point>=4", "-sum_review|int", {
          headers: {
            region: localRegion,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          setProductList([...productList, ...res?.data?.data?.data])
          updateLoading(false)
          setLoadMoreYourMarket(false)
        })
        .catch(() => {
          updateLoading(false)
        })
    }
  }

  useEffect(() => {
    handleGetProductList()
  }, [])

  useEffect(() => {
    if (isOnScreen && !isFirstLoad) {
      handleGetMoreProducts()
    }
  }, [isOnScreen])

  const detectStructView = (index: number) => {
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = productList.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach(i => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === "ONGOGING" || i?.flash_deal_status === "UPCOMING" ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }
  
  return (
    <div className="flex h-full w-full flex-col gap-3">
      <div className="flex h-full w-full flex-col">
        {isLoading ? (
          <div className="h-fit w-full">
            <SkeletonLoading limit={5} />
          </div>
        ) : (
          <>
            {
              !productList.length && !sponsorDataCount ? (
                <div className="h-[677px] w-full">
                  <EmptyPage
                    message={capitalizedFirst(
                      t("global.data-not-found-please-try-again-later")
                    )}
                  />
                </div>
              ) : null
            }
            {productList?.length ? (
              <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                {productList?.map((pro, index) => (
                  <CustomCard
                    key={`${pro.id}-${index}`}
                    id={pro.id} 
                    typeCard={CardCustomType.product}
                    imgUrl={pro.avatar?.id}
                    avatar={pro.avatar}
                    name={pro.name}
                    point={pro.point}
                    sum_review={pro.sum_review}
                    price={pro.price}
                    price_after_tax={pro.price_after_tax}
                    original_price={pro.original_price}
                    original_price_after_tax={pro.original_price_after_tax}
                    quantity={pro.quantity}
                    productAttr={pro.product_attributes}
                    productId={pro.id}
                    shop_id={pro?.shop_id}
                    shop_name={pro.shop?.name}
                    sub_label={pro.sub_label}
                    shop={pro.shop}
                    handleReload={handleReload}
                    voucherOfProducts={[pro?.voucher as string]}
                    bloom_filter={bloom_filter as string}
                    sold={pro?.sold}
                    is_flash_deal={pro?.is_flash_deal}
                    flash_deal_status={pro?.flash_deal_status}
                    flash_deals={pro?.flash_deals}
                    flash_deals_time={pro?.flash_deals_time}
                    vouchers={pro?.vouchers}
                    structView={detectStructView(index)}
                  />
                ))}
              </div>
            ) : null}
          </>
        )}
      </div>
      {pageYourMarket < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!loadMoreYourMarket && (
            <div className="flex items-center justify-center">
              {productList?.length < totalProducts ? (
                <div
                  onClick={handleGetMoreProducts}
                  className="cursor-pointer py-1 text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("shoppingPage.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      {loadMoreYourMarket && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
