/* eslint-disable indent */
import React, { useMemo, useState } from "react"
import ShopOrder from "../shop-order"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { ORDER_FAIL_CODE, ORDER_STATE } from "src/constants/common"
import { useNavigate } from "react-router-dom"
import { useToast } from "src/hooks/use-toast"
import DialogCustom from "src/components/dialog-custom/dialog-custom"
import { InputText } from "primereact/inputtext"
import { OrderProps, ReasonsType } from "./props"
import "./index.scss"
import { RatingProductModal } from "src/components/modal"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { OrderControllerApi } from "@soctrip/axios-shop-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import RatingShipperModal from "src/components/modal/rating-shipper-modal"
import { ItemRatingViewProps } from "src/components/modal/rating-product-modal/props"
import { repayOrder } from "src/services/order-service"
import { Dialog } from "primereact/dialog"
import { convertCurrency } from "src/utils/convert-currency"
import CancelOrderModal from "./cancel-order-modal"

export default function Orders({ data, refreshPage }: OrderProps) {
  const navigate = useNavigate()
  const showToast = useToast()
  const { t } = useTranslation()
  const [visibleRefund, setVisibleRefund] = useState<boolean>(false)
  const [reasonText, setReasonText] = useState<string>("")
  const [isShowRatingModal, setIsShowRatingModal] = useState<boolean>(false)
  const [isShowMessageModal, setIsShowMessageModal] = useState<boolean>(false)
  const [limitProduct, setLimitProduct] = useState<number>(1)
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowRatingShipModal, setIsShowRatingShipModal] = useState<boolean>(false)
  const [ratingProductData, setRatingProductData] = useState<ItemRatingViewProps[]>([])
  const [selectReasons, setSelectReason] = useState<ReasonsType>()
  // handle order details
  const handleOpenOrderDetails = (id: string) => {
    navigate(`/shopping/order-details/${id}`)
  }

  // handle cancel order
  const handleButtonAction = async (id: string, code: string, message?: string) => {
    await new OrderControllerApi(configHeader(SERVICE.SHOP))
      .ordersIdStatusPut(id, code, message)
      .then(() => {
        if (code === ORDER_STATE.CANCELLED_BY_YOU) {
          setIsShowMessageModal(true)
        } else {
          refreshPage()
        }
      })
      .catch(() => showToast({ detail: capitalizedFirst(t("order-fail-message.cancel-order-fail")), severity: "error" }))
  }

  const handleRating = () => {
    setIsShowRatingModal(true)
  }

  const goToRequestRefundPage = () => {
    navigate(`/shopping/refund-request/${data?.id}`)
  }
  const goToRefundDetailPage = () => {
    navigate(`/shopping/refund-detail/${data?.id}`)
  }

  const handleRefund = () => {
    setVisibleRefund(false)
    handleButtonAction(data?.id, ORDER_STATE.REFUND, reasonText)
  }
  const getIdProductRating = useMemo(() => {
    const result: string[] = []
    if (data) {
      data?.shops?.forEach((s) => {
        s?.products?.forEach((p) => {
          result.push(p?.product_id as string)
        })
      })
    }
    return result
  }, [data])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getVariationRating = (data: any) => {
    const result: { type: string; value: string }[] = []
    if (data?.variation1 && data?.prices1) {
      result.push({
        value: data.variation1,
        type: data.prices1,
      })
    }
    if (data?.variation2 && data?.prices2) {
      result.push({
        value: data.variation2,
        type: data.prices2,
      })
    }
    return result
  }

  const getProductRating = useMemo(() => {
    const result: {
      id: string
      productId: string
      productImg: string
      productName: string
      variation: { value: string; type: string }[]
    }[] = []

    if (data) {
      data?.shops?.forEach((s) => {
        s?.products.forEach((p) => {
          result.push({
            id: p?.id,
            productId: (p?.product_id as string) || "",
            productImg: (p?.avatar?.id as string) || "",
            productName: p?.name || "",
            variation: getVariationRating(p),
          })
        })
      })
    }
    return result
  }, [data])

  const handleConfirmRating = () => {
    setIsShowRatingModal(false)
    setIsShowRatingShipModal(true)
  }

  const handleConfirmRatingShiper = () => {
    setIsShowRatingShipModal(false)
    setTimeout(() => {
      refreshPage()
    }, 1000)
  }

  const ReDataRatingProduct = (value: ItemRatingViewProps[]) => {
    setRatingProductData(value)
  }

  const handleRepay = async () => {
    const requestData = {
      success_callback: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`,
      fail_callback: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`,
    }
    await repayOrder(data?.id || "", requestData)
      .then((res) => {
        if (res?.data?.data) {
          const paymentUrl = `${process.env.REACT_APP_PAYMENT_DOMAIN_URL}?paymentToken=${res?.data?.data?.token}`
          window.open(paymentUrl, "_self")
        } else {
          let message = capitalizedFirst(t("order-fail-message.repay-fail"))
          if (res?.data?.error?.code && ORDER_FAIL_CODE.includes(res.data.error.code)) {
            const messageError = `order-fail-message.${res.data.error.code}`
            message = capitalizedFirst(t(messageError))
          }
          showToast({
            detail: message,
            severity: "error",
          })
        }
      })
      .catch((data) => {
        let message = capitalizedFirst(t("order-fail-message.repay-fail"))
        if (
          data?.response?.data?.error?.code &&
          ORDER_FAIL_CODE.includes(data.response.data.error.code)
        ) {
          const messageError = `order-fail-message.${data.response.data.error.code}`
          message = capitalizedFirst(t(messageError))
        }
        showToast({
          detail: message,
          severity: "error",
        })
      })
  }

  const handleConfirmCancel = () => {
    handleButtonAction(data?.id, ORDER_STATE.CANCELLED_BY_YOU, selectReasons?.name)
    setIsShowConfirmModal(false)
  }

  const handleCancelOrder = () => {
    setIsShowConfirmModal(true)
  }

  const handleCloseMessageModal = () => {
    setIsShowMessageModal(false)
    refreshPage()
  }

  const isTicket = useMemo(() => {
    const products = data?.shops?.flatMap(shop => shop?.products)
    return products.some(product => product?.is_ticket)
  }, [data?.shops])

  const footerContent = (
    <div className="text-gray-900">
      <button
        className="text-sm rounded-3 border border-gray-300 px-4 py-2 font-semibold text-gray-900"
        onClick={handleCloseMessageModal}
        autoFocus
      >
        {capitalizedFirst(t("global.close"))}
      </button>
    </div>
  )
  const getShopCode = useMemo(() => {
    if (data.shops.length) return data.shops[0].code || ""
    return ""
  }, [data])
  return (
    <div className="mb-3 flex h-fit w-full flex-col rounded-3 bg-white">
      <div className="flex h-fit w-full flex-col">
        {/* content */}
        <div className="relative flex w-full flex-col">
          {/* status */}
          <div
            className="absolute right-[0px] top-2 flex w-[120px] items-center rounded-6 px-3 py-[4px] text-center text-14 font-medium line-clamp-1 md:right-4  md:w-fit"
            style={{
              backgroundColor: data?.status?.status_property?.color,
              color: data?.status?.status_property?.font_color,
            }}
          >
            {capitalizedFirst(t(`${data?.status?.name}`))}
          </div>
          {/* order items shop*/}
          {data?.shops?.map((shop, index) => (
            <div key={index} className="h-full w-full">
              <ShopOrder
                shopId={shop?.shop_id}
                shopName={shop?.name}
                avatar={shop?.avatar?.id}
                products={shop?.products}
                limitProduct={limitProduct}
              />
            </div>
          ))}
        </div>
        {/* footer */}
        <div className="flex w-full flex-col gap-1 px-3 pb-3">
          {/* total order */}
          <div className="flex w-full flex-row items-center justify-between">
            <div
              onClick={() => setLimitProduct(data?.total_item as number)}
              className={`flex cursor-pointer items-center text-14 font-medium   ${
                data?.total_item && data?.total_item > 1
                  ? "hover:text-blue-700 hover:underline"
                  : "text-gray-500"
              }`}
            >
              {data?.total_item}{" "}
              {translateCapitalize(
                (data?.total_item as number) > 1
                  ? "purchase-history.items"
                  : "purchase-history.item"
              )}
            </div>
            <div className="flex items-center justify-end gap-1">
              <p className="flex items-center text-16 font-medium text-gray-500">
                {translateCapitalize("purchase-history.order-total")}:
              </p>
              <p className="text-18 font-semibold text-orange-dark-500">
                {convertCurrency(data?.payment?.payment_total)}
              </p>
            </div>
          </div>
          {/* info */}
          <div className="flex flex-col items-center justify-between md:flex-row">
            <div className="flex flex-col items-center justify-center md:items-start">
              <div className="flex items-center gap-1 text-14 font-medium text-blue-700">
                <p className="flex items-start text-14 font-semibold">{data?.status?.note}</p>
              </div>
              {data?.status?.message && (
                <div className="pt-1 text-12 font-normal text-gray-500">
                  {data?.status?.message}
                </div>
              )}
            </div>
            {/* action */}
            <div className="mt-1 flex h-fit items-center gap-1">
              <button
                onClick={() => handleOpenOrderDetails(data?.id)}
                type="button"
                className="truncate rounded-3 border border-gray-300 px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
              >
                {translateCapitalize("purchase-history.button.order-details")}
              </button>

              {data?.status?.status_property?.can_cancel && (
                <button
                  onClick={handleCancelOrder}
                  type="button"
                  className="truncate rounded-3 border border-gray-300 px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
                >
                  {translateCapitalize("purchase-history.button.cancel-order")}
                </button>
              )}

              {data?.status?.status_property?.can_repay ||
              data?.status?.code === ORDER_STATE.FAILED ||
              data?.status?.code === ORDER_STATE.UNPAID ? (
                <button
                  onClick={handleRepay}
                  type="button"
                  className="truncate rounded-3 border border-blue-700 bg-blue-700 px-3 py-6px text-14 font-semibold text-white hover:bg-blue-600 md:px-5 md:py-10px"
                >
                  {translateCapitalize("purchase-history.button.repay")}
                </button>
              ) : (
                ""
              )}
              {data?.status?.status_property?.can_refund && (
                <>
                  <button
                    onClick={goToRequestRefundPage}
                    type="button"
                    className="truncate rounded-3 border border-gray-300 px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
                  >
                    {translateCapitalize("purchase-history.button.refund-order")}
                  </button>
                </>
              )}

              {data?.status?.code === ORDER_STATE.REFUND ? (
                <button
                  onClick={goToRefundDetailPage}
                  type="button"
                  className="truncate rounded-3 border border-gray-300 px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
                >
                  {translateCapitalize("purchase-history.button.refund-details")}
                </button>
              ) : null}

              {data?.status?.status_property?.can_receive && (
                <button
                  onClick={() => handleButtonAction(data?.id, ORDER_STATE.COMPLETED)}
                  type="button"
                  className="truncate rounded-3 border border-blue-700 bg-blue-700 px-2 py-6px text-14 font-semibold text-white hover:bg-blue-600 md:px-3 md:py-10px"
                >
                  {translateCapitalize("purchase-history.button.received-order")}
                </button>
              )}
              {/* rating */}
              {data?.status?.code === ORDER_STATE.COMPLETED && !isTicket ? (
                <button
                  onClick={handleRating}
                  type="button"
                  className="truncate rounded-3 border border-gray-300 px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
                >
                  {(data?.total_rating as number) > 0
                    ? translateCapitalize("purchase-history.button.view-rating")
                    : translateCapitalize("purchase-history.button.rating")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <DialogCustom
        header="Refund orders"
        labelButton="Submit"
        position="center"
        visibleShowDialog={visibleRefund}
        setVisibleShowDialog={setVisibleRefund}
        showIconClose={true}
        classLabel="w-[50vw]"
      >
        <div className="flex h-full w-full flex-col gap-4 px-4 pb-3">
          <div className="flex w-full flex-col">
            <label className="mb-2" htmlFor="comment">
              Refund reason
            </label>
            <InputText
              id="comment"
              placeholder="Enter reason"
              value={reasonText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReasonText(e.target.value)}
            />
          </div>

          <div className="flex w-full items-center justify-end">
            <button
              onClick={handleRefund}
              className="w-[100px] rounded-3 bg-blue-500 px-4 py-2 text-16 font-semibold text-white"
            >
              Submit
            </button>
          </div>
        </div>
      </DialogCustom>

      {isShowRatingModal ? (
        <RatingProductModal
          shopCode={getShopCode}
          orderId={data?.id}
          isShow={isShowRatingModal}
          isRated={(data?.total_rating as number) > 0 || false}
          products={getProductRating}
          ratingIds={getIdProductRating}
          handleClose={() => setIsShowRatingModal(false)}
          handleConfirm={handleConfirmRating}
          ratingProductList={ReDataRatingProduct}
        />
      ) : null}

      {isShowRatingShipModal ? (
        <RatingShipperModal
          shopCode={getShopCode}
          orderId={data?.id}
          ratingProductData={ratingProductData}
          isShowModal={isShowRatingShipModal}
          handleCloseShip={() => setIsShowRatingShipModal(false)}
          handleConfirm={handleConfirmRatingShiper}
        />
      ) : null}
      {isShowConfirmModal ? (
        <CancelOrderModal
          isShowConfirmModal={isShowConfirmModal}
          handleConfirmCancel={handleConfirmCancel}
          setSelectReason={(value) => setSelectReason(value)}
          setIsShowConfirmModal={(value) => setIsShowConfirmModal(value)}
          selectReasons={selectReasons}
        />
      ) : null}

      <Dialog
        header={translateCapitalize("purchase-history.cancel-successfully")}
        visible={isShowMessageModal}
        onHide={handleCloseMessageModal}
        footer={footerContent}
      >
        <div className="max-h-36 flex max-w-sm flex-col px-4 pb-3">
          <div className="flex w-full flex-col text-14 font-normal text-gray-700">
            <span>{translateCapitalize("purchase-history.cancel-success-message-1")}</span>
            <span>{translateCapitalize("purchase-history.cancel-success-message-2")}</span>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
