import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { SystemVoucherType, initialState } from "./type"
import { CartType } from "src/components/order-cart-view/type"
export const shoppingCartSlice = createSlice({
  name: "myCart",
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<CartType[]>) => {
      state.cartData = action.payload
    },
    updateTotalCart: (state, action: PayloadAction<number>) => {
      state.totalCart = action.payload
    },
    resetListDataCartLocal: (state) => {
      state.cartData = []
      state.totalCart = 0
      state.buyNowId = ""
    },
    setBuyNowId: (state, action: PayloadAction<string>) => {
      state.buyNowId = action.payload
    },
    updateSystemVoucher: (
      state,
      action: PayloadAction<SystemVoucherType | undefined>
    ) => {
      state.systemVoucher = action.payload
    },
  },
})

export const {
  updateCart,
  updateTotalCart,
  resetListDataCartLocal,
  setBuyNowId,
  updateSystemVoucher,
} = shoppingCartSlice.actions

export default shoppingCartSlice.reducer
