import React, { useRef, useState, useEffect } from "react"
import "./product-detail-gallery.scss"
import ImgCustom from "src/components/img-custom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import useWindowDimensions from "src/utils/screen-size"
import { TYPELINK } from "src/constants/common"
import { AttachmentType, ProductDetailGalleryProps } from "./props"
import imageNotFound from "src/assets/images/image-not-found.png"
import { getThumbnailVideo } from "src/utils/common"
import EmptyPage from "src/pages/empty-page"

export const ImageThumbnail = ({ image }: { image: AttachmentType }) => {
  const [thumbnailData, setThumbnailData] = useState("")

  useEffect(() => {
    const fetchThumbnail = async () => {
      const parts = image?.id.split("/")
      const fileNameWithExtension = parts[parts.length - 1]
      const fileNameWithoutExtension = fileNameWithExtension?.split(".")[0]

      const thumbnailURL = getThumbnailVideo(fileNameWithoutExtension)
      setThumbnailData(thumbnailURL)
    }

    fetchThumbnail()
  }, [image])

  return (
    <img
      src={thumbnailData}
      alt="thumbnailData"
      className="h-[60px] w-[60px] object-cover"
    />
  )
}

export default function ProductDetailGallery(props: ProductDetailGalleryProps) {
  const { width } = useWindowDimensions()
  const { Images, imgSelected } = props
  const swiperRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)

  const handlePrevImage = () => {
    swiperRef.current?.slidePrev()
    if (activeIndex > 0) setActiveIndex((index) => index - 1)
  }

  const handleNextImage = () => {
    swiperRef.current?.slideNext()
    if (activeIndex < Images.length - 1) setActiveIndex((index) => index + 1)
  }

  const typeDataImageSmallType = (image: AttachmentType) => {
    if (image.type === TYPELINK.LINK) {
      const isLinkYoutube = image?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)
      if (isLinkYoutube) {
        return (
          <img
            src={`https://img.youtube.com/vi/${
              image?.id?.split("/").slice(-1)[0]
            }/maxresdefault.jpg`}
            alt="img-video"
            className="h-[60px] w-[60px] object-cover"
          />
        )
      } else {
        return <ImageThumbnail image={image} />
      }
    } else
      return (
        <ImgCustom
          url={image?.id}
          alt="img"
          className="!h-[60px] !w-[60px] rounded-1 border border-gray-200 object-cover"
        />
      )
  }
  const typeDataImage = () => {
    if (Images[activeIndex]?.type === TYPELINK.LINK) {
      if (Images[activeIndex]?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)) {
        return (
          <iframe
            src={`${Images[activeIndex]?.id}?autoplay=${1}`}
            allow="autoplay"
            allowFullScreen
            className="h-full w-full object-contain "
          ></iframe>
        )
      } else
        return (
          <video
            src={Images[activeIndex]?.id}
            autoPlay
            muted
            className="h-full w-full object-cover"
          ></video>
        )
    } else
      return (
        <ImgCustom
          className="seo-image max-h-[384px] w-full transform rounded-2 object-contain transition duration-[1500ms]
          hover:scale-110"
          url={Images[activeIndex]?.id}
          alt="images"
          type="WEB"
        />
      )
  }

  useEffect(() => {
    if(!imgSelected) return
    const index = Images.findIndex((image) => image.id === imgSelected)
    if(index >= 0) {
      setActiveIndex(index)
    }
  }, [imgSelected])

  if (!Images.length)
    return (
      <div className="flex h-full w-full flex-col items-center gap-2 rounded-3 border bg-white p-2 ">
        <img
          src={imageNotFound}
          alt="imageNotFound"
          className="h-full w-full object-contain"
        />
      </div>
    )
  return (
    <>
      {Images?.length ? (
        <div className="h-full w-full items-center gap-2 rounded-4 border border-gray-200 shadow-sm">
          <div className="mb-1 h-[384px] flex-1 overflow-hidden rounded-3 py-4px px-4px">
            {typeDataImage()}
          </div>
          <div id="container-swiper-detail" className="relative w-full">
            <Swiper
              onBeforeInit={(swiper) => (swiperRef.current = swiper)}
              direction="horizontal"
              modules={[Navigation]}
              slidesPerView={"auto"}
              className="box-border h-full w-full"
              spaceBetween={8}
            >
              {Images.map((image: AttachmentType, index: number) => (
                <SwiperSlide key={image.id}>
                  <div
                    onClick={() => setActiveIndex(index)}
                    className={`${
                      activeIndex === index
                        ? "border border-red-400"
                        : "border border-transparent brightness-[70%] transition-all"
                    } cursor-pointer overflow-hidden rounded-2 hover:border-red-400 hover:brightness-100`}
                  >
                    {typeDataImageSmallType(image)}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {Images.length > 1 && (
              <>
                <button
                  onClick={handlePrevImage}
                  className={`${
                    activeIndex === 0 && "cursor-default text-gray-300"
                  } absolute -left-4px top-2 z-10 flex h-[28px] w-[28px] rotate-0 items-center justify-center rounded-full border bg-white shadow lg:-rotate-90`}
                >
                  {width > 1024 ? (
                    <i className="pi pi-angle-up"></i>
                  ) : (
                    <i className="pi pi-angle-left"></i>
                  )}
                </button>
                <button
                  onClick={handleNextImage}
                  className={`${
                    activeIndex === Images.length - 1 &&
                    "cursor-default text-gray-300"
                  } absolute -right-4px top-2 z-10 flex h-[28px] w-[28px] rotate-0 items-center justify-center rounded-full border bg-white shadow lg:-rotate-90`}
                >
                  {width > 1024 ? (
                    <i className="pi pi-angle-down"></i>
                  ) : (
                    <i className="pi pi-angle-right"></i>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="h-auto w-full">
          <EmptyPage message="Not found data. Please try again later." />
        </div>
      )}
    </>
  )
}
