import http from "src/config/interceptors"
import {UpdateCartProductType, StockValueType, CreateCartProductType} from "./props"

export const getShoppingCartByUser = () => {
  const urlFile = "cart/shopping-cart/my-cart"
  return http.get(urlFile)
}

export const updateCartProduct = (data: UpdateCartProductType) => {
  const urlFile = `/cart/shopping-cart/${data.id}`
  const dataRes = {
    product_id: data.productId,
    stock_id: data.stockId,
    shop_id: data.shopId,
    quantity: data.quantity,
  }
  return http.put(urlFile, dataRes)
}

export const deleteCartProduct = (productId: string) => {
  const urlFile = `/cart/shopping-cart/${productId}`
  return http.delete(urlFile)
}

export const getStockValue = (data: StockValueType) => {
  const urlFile = "/stock/stocks/variations"
  return http.get(urlFile, {
    params: {
      variationFirstId: data.variationFirstId,
      variationSecondId: data.variationSecondId,
    },
  })
}

export const createProductToCart = (data: CreateCartProductType) => {
  const urlFile = "/cart/shopping-cart"
  const resBody = {
    product_id: data.productId,
    stock_id: data.stockId,
    shop_id: data.shopId,
    quantity: data.quantity,
  }
  return http.post(urlFile, resBody)
}

export const getMyCart = (cartType: string, searchValue = "") => {
  const paramsQuery = searchValue ? `?objectType=${cartType}&keyword=${searchValue}'` : `?objectType=${cartType}`
  const urlFile = `/cart/carts/my-cart${paramsQuery}`
  return http.get(urlFile)
}