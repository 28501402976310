/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import ButtonCustom from "../button-custom/button-custom/button-custom"
import box from "../../assets/images/box-icon.svg"
import followersImage from "../../assets/images/user-icon.svg"
import star from "../../assets/images/star-icon.svg"
import { useTranslation } from "react-i18next"
import { ButtonSeverity } from "src/constants/constants/button-severity"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { ProfileProps } from "./props"
import avatarDefault from "src/assets/images/avatar-default.webp"
import ImgCustom from "../img-custom"
import {
  bookmarkProduct,
  deleteBookmark,
  getBloomFilter,
} from "src/services/bookmark-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useToast } from "src/hooks/use-toast"
import { handleCheckBloomFilter } from "src/utils/common"
import { BOOKMARK_CATEGORY } from "src/constants/common"
import useWindowDimensions from "src/utils/screen-size"
import ShareToFeed from "../share-to-feed-dialog"

export default function MerchantProfile({
  id,
  avatar,
  name,
  rating,
  productNumber,
  description,
  followers,
  background,
  badge,
}: ProfileProps) {
  const { t } = useTranslation()
  const showToast = useToast()
  const { width } = useWindowDimensions()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  const authStore = useSelector((state: RootState) => state.auth)
  const [newFollower, setNewFollower] = useState<number>(0)
  const [bloomData, setBloomData] = useState<string>("")
  const [isShow, setIsShow] = useState<boolean>(false)
  const handleBookmark = async () => {
    if (authStore?.value && id) {
      if (bloomData) {
        if (!handleCheckBloomFilter(bloomData as string, id as string)) {
          bookmarkProduct(id, BOOKMARK_CATEGORY.SHOP)
            .then(() => {
              handleGetBloomData()
              setNewFollower(newFollower + 1)
              showToast({
                detail: handleTranslate("product-details.follow-success"),
                severity: "success",
              })
            })
            .catch((error) => {
              throw error
            })
        } else {
          deleteBookmark(id)
            .then(() => {
              handleGetBloomData()
              setNewFollower(newFollower - 1)
              showToast({
                detail: handleTranslate("product-details.unfollow-success"),
                severity: "success",
              })
            })
            .catch((error) => {
              error
            })
        }
      } else {
        bookmarkProduct(id, BOOKMARK_CATEGORY.SHOP)
          .then(() => {
            handleGetBloomData()
            setNewFollower(newFollower + 1)
            showToast({
              detail: handleTranslate("product-details.follow-success"),
              severity: "success",
            })
          })
          .catch((error) => {
            throw error
          })
      }
    } else {
      showToast({ detail: "Please login before follow", severity: "warn" })
    }
  }

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])

  useEffect(() => {
    setNewFollower(followers as number)
  }, [followers])

  return (
    <div className="flex w-full items-center justify-between rounded-4 border-[1px] border-gray-200 bg-white">
      <div className="h-[105px] w-[130px] px-1 py-1">
        {avatar ? (
          <ImgCustom
            url={avatar}
            alt="header"
            className="h-[100px] w-[105px] rounded-full object-cover object-center"
          />
        ) : ( 
          <img src={avatarDefault} alt="header"></img>
        )}
      </div>
      <div className=" flex w-full flex-col gap-2 py-1">
        <div className="gap-12 flex justify-between">
          <div className="flex flex-col md:flex-row cursor-pointer justify-start gap-4px text-18 font-semibold">
            <div className="max-w-[250px] md:max-w-[300px] truncate">{name}</div>
            {badge?.name ? (
              <div className=" flex w-fit items-center gap-4px rounded-3 bg-blue-50 px-[4px] py-[2px] max-w-[120px] ">
                <div className="h-[16px] w-[16px]">
                  <ImgCustom url={badge?.avatar?.id as string} alt="image" />
                </div>
                <div className="truncate text-[12px] leading-[16px] text-blue-600">
                  {badge.name}
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex items-center justify-between gap-2px px-1 md:gap-2">
            <ButtonCustom
              classCustom="md:border rounded-3 hidden px-1 py-[1px] text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
              severity={ButtonSeverity.custom}
            >
              <div className="flex items-center justify-between gap-1">
                <i className="pi pi-comment text-14 font-semibold"></i>
                {width >= 768 ? (
                  <p className="text-14 font-semibold">
                    {capitalizedFirst(handleTranslate("global.chat"))}
                  </p>
                ) : null}
              </div>
            </ButtonCustom>
            {bloomData &&
            id &&
            handleCheckBloomFilter(bloomData as string, id as string) ? (
              <ButtonCustom
                classCustom="rounded-2 border py-[1px] px-[6px] border-blue-700 text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                severity={ButtonSeverity.custom}
              >
                <div
                  onClick={handleBookmark}
                  className={`flex items-center justify-between gap-2  ${
                    bloomData &&
                    id &&
                    handleCheckBloomFilter(bloomData as string, id as string)
                      ? "text-blue-700"
                      : "text-gray-700"
                  }`}
                >
                  <p className="text-14 font-semibold ">
                    {capitalizedFirst(handleTranslate("global.followed"))}
                  </p>
                </div>
              </ButtonCustom>
            ) : (
              <ButtonCustom
                classCustom="rounded-2 md:border py-[1px] px-[6px] border-gray-300 text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                severity={ButtonSeverity.custom}
              >
                <div
                  onClick={handleBookmark}
                  className={`flex items-center justify-between gap-2  ${
                    bloomData &&
                    id &&
                    handleCheckBloomFilter(bloomData as string, id as string)
                      ? "text-blue-700"
                      : "text-gray-700"
                  }`}
                >
                  <i
                    className="pi pi-plus "
                    style={{ fontSize: "0.75rem", fontWeight: 600 }}
                  ></i>
                  {width >= 768 ? (
                    <p className="text-14 font-semibold ">
                      {capitalizedFirst(handleTranslate("global.follow"))}
                    </p>
                  ) : null}
                </div>
              </ButtonCustom>
            )}
            <ShareToFeed
              isShow={isShow}
              handleClose={() => setIsShow(false)}
              id={id}
              restaurantName={name}
              descriptionRestaurant={description}
              linkType="SHOP"
              objectName="SHOP_SHARE"
              avatar={avatar}
              background={background}
            />
            <div
              className="flex h-max w-max cursor-pointer items-center justify-between rounded-2 border border-gray-400 bg-white p-[3px] hover:shadow-lg"
              onClick={() => setIsShow(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.52366 2.08388C9.14224 2.07424 8.7768 2.24511 8.53572 2.54713C8.31898 2.81866 8.28932 3.08219 8.27185 3.27977C8.25437 3.47735 8.25736 3.66139 8.25736 3.89414V6.92333C6.59702 7.36767 5.05943 8.21487 3.90327 9.53526C2.46446 11.1784 1.66782 13.3058 1.66675 15.5098V16.0239C1.66679 16.1969 1.71906 16.3657 1.81645 16.5072C1.91383 16.6487 2.05158 16.7562 2.21094 16.8149C2.37031 16.8736 2.54352 16.8808 2.707 16.8354C2.87048 16.7899 3.01627 16.6942 3.12451 16.5611C3.91318 15.5912 4.89788 14.8082 6.01112 14.263C6.71632 13.9176 7.4912 13.7964 8.25736 13.6585V16.1061C8.25736 16.3389 8.25437 16.5229 8.27185 16.7205C8.28932 16.9181 8.31917 17.1801 8.53573 17.4515C8.53573 17.4521 8.53573 17.4526 8.53573 17.4532C8.77673 17.7549 9.14175 17.9263 9.52366 17.9164C9.86668 17.9078 10.0813 17.7588 10.2397 17.6437C10.398 17.5287 10.5316 17.4075 10.7047 17.2561L17.6831 11.1501C17.8842 10.9741 18.1123 10.8485 18.2607 10.4273C18.3576 10.1516 18.3576 9.84873 18.2607 9.5731C18.1123 9.15202 17.8843 9.02639 17.6831 8.85031L10.7047 2.74434C10.5316 2.59286 10.398 2.47172 10.2397 2.35666C10.0814 2.2416 9.86665 2.09256 9.52366 2.08388ZM9.905 4.2588L16.4651 10.0001L9.905 15.7414V12.5824C9.90503 12.4671 9.88184 12.353 9.83685 12.2472C9.79187 12.1414 9.72607 12.0462 9.64352 11.9675C9.56098 11.8888 9.46346 11.8282 9.35703 11.7896C9.25059 11.751 9.13752 11.7351 9.02482 11.743C7.73306 11.8325 6.46849 12.1719 5.29832 12.745C4.7028 13.0367 4.14983 13.4039 3.62332 13.8046C3.89978 12.6533 4.34558 11.5541 5.131 10.6572C6.20346 9.43232 7.64615 8.61598 9.22279 8.33439C9.41373 8.30036 9.5868 8.1987 9.71158 8.04727C9.83636 7.89585 9.90485 7.70436 9.90502 7.50647L9.905 4.2588Z"
                  fill="#344054"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start sm:flex-row">
          <div className="flex items-center justify-start border-r border-gray-300 pl-4 pr-2 text-14 font-medium text-gray-600 sm:pl-0">
            <img className="mr-1 h-[20px] w-[20px]" src={star} alt="star" />
            <p>{rating?.toFixed(1)}/5</p>
          </div>
          <div className="flex items-center justify-start gap-1 border-r border-gray-300 px-1 text-14 font-medium text-gray-600">
            <img
              className="h-[20px] w-[20px]"
              src={followersImage}
              alt="follower"
            />
            <p>{capitalizedFirst(handleTranslate("global.follower"))}:</p>
            <p>{newFollower}</p>
          </div>
          <div className="flex items-center justify-start gap-1 px-2 text-14 font-medium text-gray-600">
            <img className="h-[20px] w-[20px]" src={box} alt="box" />
            <p>{capitalizedFirst(handleTranslate("global.products"))}: </p>
            <p>{productNumber}</p>
          </div>
        </div>
        <div className="text-12 font-normal text-gray-700">
          {capitalizedFirst(handleTranslate(description))}
        </div>
      </div>
    </div>
  )
}
