import React, { useMemo, useEffect, useState } from "react"
import "./main-layout.scss"
import type { MainLayoutProps } from "./main-layout-props"
import { Outlet, useLocation } from "react-router-dom"
// import soctripLogo from "../../assets/images/logo.svg"
export type { MainLayoutProps }
import { ROUTERS, ROUTERS_NOT_LAYOUT } from "src/constants/router"
import HeaderView from "../header-view"
import ShoppingHomePageHeader from "../shopping-homepage-view/shopping-homepage-header"
import "./main-layout.scss"
import HeaderFlashDeals from "../header-flash-deals"
import MostHeaderView from "../most-header-view"
import { FlashDealSettingControllerApi } from "@soctrip/axios-advertising-service"
import { updateFlashTimes } from "src/features/system"
import { useDispatch } from "react-redux"
import { SERVICE } from "src/constants/common"
import { configHeader } from "src/config/interceptors"
import scrollToTopIcon from "src/assets/images/scroll-to-top-icon.svg"
// import { t } from "i18next"
// import { isDevMode } from "src/utils/app-logo"
import FooterView from "../footer-view"
import { validateAndFetchCurrency } from "src/utils/convert-currency"
import { getMyRegion } from "src/services/user-service"

export default function MainLayout() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [isShowScroll, setIsShowScroll] = useState<boolean>(false)
  const [isRender, setIsRender] = useState<boolean>(false)

  useEffect(() => {
    handleScrollToTop()
  }, [location?.pathname])

  // validate currency when change page
  useEffect(() => {
    validateAndFetchCurrency()
  },[location.pathname])

  const isNotLayout = useMemo(() => {
    return (
      ROUTERS_NOT_LAYOUT.includes(location.pathname) ||
      location.pathname.includes(ROUTERS.LIVE_STREAM_ROOM_PATH) ||
      location.pathname.includes(ROUTERS.APPROVAL_REQUEST?.split("/")?.[1])
    )
  }, [location])

  useEffect(() => {
    new FlashDealSettingControllerApi(configHeader(SERVICE.ADVERTISING))
      .getFlashDealsSettingPage()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: any[] = []
          if (res.data?.data?.data[0]?.time_slot_units?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data[0].time_slot_units?.forEach((i: any) => {
              const dateData = new Date(i?.start_at)
              const currentDate = new Date()
              currentDate.setHours(0)
              currentDate.setMinutes(0)
              currentDate.setSeconds(0)
              currentDate.setMilliseconds(0)
              const timeStampStart =
                dateData.getHours() * 1000 * 60 * 60 +
                dateData.getMinutes() * 1000 * 60 +
                dateData.getSeconds() * 1000
              const timeStampEnd = timeStampStart + i?.duration * 60 * 1000

              result.push({
                timeStampStart: currentDate.getTime() + timeStampStart,
                timeStampEnd: currentDate.getTime() + timeStampEnd,
                notify_before: i?.notify_before,
                notify_end: i?.notify_end,
                duration: i?.duration,
              })
            })
          }
          dispatch(updateFlashTimes(result))
        }
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    detectRegionLocal()
  }, [])

  const detectRegionLocal = () => {
    if(localStorage.getItem("localRegion")) {
      setIsRender(true)
    } else {
      setIsRender
      getMyRegion().then((res) => {
        console.log(res)
        if(res?.data?.data?.length) {
          const region = res.data.data[0].country_code || ""
          localStorage.setItem("localRegion", region)
        }
      }).catch(err => console.log(err)).finally(() => setIsRender(true))
    }

  }
  const handleScrollToTop = () => {
    document.getElementById("content-body")?.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const detectScrollButton = () => {
    const positionView = document.getElementById("content-body")?.scrollTop || 0
    const isScroll = positionView > 100
    setIsShowScroll(isScroll)
  }

  useEffect(() => {
    const contentValue = document.getElementById("content-body") as HTMLDivElement
    if (contentValue) contentValue.addEventListener("scroll", detectScrollButton)
    return () => {
      if (contentValue) contentValue.removeEventListener("scroll", detectScrollButton)
    }
  }, [isRender])

  if(!isRender) return null
  if (isNotLayout) return <Outlet />
  
  return (
    <div
      className="no-scrollbar mx-auto flex h-full w-screen flex-col overflow-auto"
      id="content-body"
    >
      <HeaderView />
      <main className="mt-[64px] flex w-full flex-col justify-center gap-1 bg-gray-50">
        {(location.pathname === ROUTERS.SHOPPING || location.pathname === `${ROUTERS.SHOPPING}/`) && <ShoppingHomePageHeader />}
        {location.pathname === `${ROUTERS.SHOPPING}/${ROUTERS.FLASH_SALE}` && <HeaderFlashDeals />}
        {location.pathname === `${ROUTERS.SHOPPING}/${ROUTERS.MOST_PAGE}` && <MostHeaderView />}

        <div className="mx-auto w-full max-w-[1200px] main">
          <Outlet />
        </div>
      </main>
      {/* Footer */}
      <FooterView/>
      <div className="fixed bottom-40px right-1 z-50 flex items-center gap-1">
        {/* {!isDevMode() && (
          <a
            href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
            target="_blank"
            rel="noopener noreferrer"
            className="group flex cursor-pointer items-center gap-2 rounded-[100px] border border-gray-100 bg-white px-[4px] py-[4px] shadow-lg hover:bg-gray-25"
          >
            <span className="hidden text-12 font-semibold group-hover:ml-2 group-hover:block">
              Give feedback
            </span>
            <img className="h-40px w-40px" src={soctripLogo} alt="Logo Soctrip" />
          </a>
        )} */}
        {isShowScroll && (
          <div
            onClick={handleScrollToTop}
            className="flex h-40px w-40px cursor-pointer items-center justify-center md:bottom-[40px] md:right-[40px]"
          >
            <img className="h-full w-full" src={scrollToTopIcon} alt="scrollToTopIcon" />
          </div>
        )}
      </div>
    </div>
  )
}
