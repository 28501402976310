import React, { useEffect, useMemo } from "react"
import { VoucherType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import discountShippingIcon from "src/assets/images/discount-shipping-icon.svg"
import discountVoucherIcon from "src/assets/images/discount-voucher-icon.svg"
import livestreamVoucherIcon from "src/assets/images/livestream-voucher-icon.svg"
import discountSoctripIcon from "src/assets/images/soctrip-i.svg"
import warningIcon from "src/assets/images/warning-icon.svg"
import { Checkbox } from "primereact/checkbox"
import {
  VOUCHER_TYPE,
  VOUCHER_EXPIRED_TIME,
  VOUCHER_EXPIRED_SOON_7_DAY,
  VOUCHER_EXPIRED_SOON_1_DAY,
} from "../../../constants/common"
import "./index.scss"
import { convertTimeCommon } from "src/utils/time-utils"
import { ProgressBar } from "primereact/progressbar"
import { mathRoundNumber } from "src/utils/common"
import ImgCustom from "src/components/img-custom"
import { convertCurrency } from "src/utils/convert-currency"

const ItemView = (props: VoucherType) => {
  const { t } = useTranslation()
  const {
    name,
    itemType,
    index,
    minimum_order_price,
    end_date,
    // max_discount_price,
    voucherSelected,
    updateSelected,
    updateSelectedShip,
    id,
    shopBilling,
    platform,
    is_limit_usage, // li mit count use,
    used_count,
    shipVoucherSelected,
    isReadOnly,
    voucherDiscount,
    liveVoucherSelected,
    updateSelectedLive,
    liveBilling,
    isLiveVoucherSelected,
    isInvalid,
    max_order_price,
    voucher_discount_range,
    isSystemVoucher,
    avatar_id,
    discount_type,
    discount_amount,
    discount_percent,
    discount_price,
    max_usage_count,
  } = props

  const getExpiredDay = useMemo(() => {
    if(!end_date) return capitalizedFirst(t("shoppingCartPage.voucher-no-expired"))
    const voucherTime = new Date(end_date).getTime()
    if (voucherTime < VOUCHER_EXPIRED_TIME)
      return convertTimeCommon(end_date as string)
    return capitalizedFirst(t("shoppingCartPage.voucher-no-expired"))
  }, [end_date])
  
  const getMinSpendPrice = useMemo(() => {
    if (voucher_discount_range && voucher_discount_range.length) {
      return voucher_discount_range.reduce((min, range) => {
        const minOrderPrice = range.min_order_price as number
        return minOrderPrice < min ? minOrderPrice : min
      }, Infinity)
    }
    return 0
  }, [voucher_discount_range])  
  
  const updateSelectedItem = () => {
    if (!isVoucherApply) return
    if (updateSelected) {
      if (voucherSelected?.id !== id) updateSelected(id)
      else updateSelected(undefined)
      return
    }

    if (updateSelectedLive) {
      if (liveVoucherSelected?.id !== id) updateSelectedLive(id)
      else updateSelectedLive(undefined)
      return
    }

    if (updateSelectedShip) {
      if (shipVoucherSelected?.id !== id) updateSelectedShip(id)
      else updateSelectedShip(undefined)
    }
  }

  const isVoucherApply = useMemo(() => {
    // system
    if(isSystemVoucher) {
      return true
    }
    // ship and voucher
    if (isInvalid) {
      return false
    }
    
    if (shopBilling) {
      const usagePerUser = max_usage_count
        ? max_usage_count
        : 0

      if (
        is_limit_usage &&
        used_count &&
        (used_count * 100) / usagePerUser >= 100
      )
        return false
      if (
        itemType === VOUCHER_TYPE.VOUCHER ||
        itemType === VOUCHER_TYPE.LIVE_STREAM
      ) {
        return shopBilling >= minimum_order_price && platform !== "MOBILE"
      }
      return voucherDiscount
        ? shopBilling - mathRoundNumber(voucherDiscount) >=
            minimum_order_price && platform !== "MOBILE"
        : shopBilling >= minimum_order_price && platform !== "MOBILE"
    }
    return false
  }, [voucherDiscount, shopBilling, liveBilling, isLiveVoucherSelected])
  const usePercent = useMemo(() => {
    let result = 0
    if (is_limit_usage && used_count && max_usage_count) {
      result = mathRoundNumber((used_count * 100) / max_usage_count)
    }
    return result > 100 ? 100 : result
  }, [])

  useEffect(() => {
    if (
      !isVoucherApply &&
      shipVoucherSelected &&
      updateSelectedShip &&
      shipVoucherSelected?.id === id
    ) {
      updateSelectedShip(undefined)
    }
  }, [isVoucherApply, shipVoucherSelected])

  const isExpiringSoon = useMemo(() => {
    if(!end_date) return false
    const voucherTime = new Date(end_date).getTime()
    const currentTime = new Date().getTime()
    if (
      voucherTime < VOUCHER_EXPIRED_TIME &&
      voucherTime - currentTime <= VOUCHER_EXPIRED_SOON_7_DAY
    )
      return true
    return false
  }, [])
  const getIsExpiringData = useMemo(() => {
    let result = 0
    if (!end_date) return false
    const voucherTime = new Date(end_date).getTime()
    const currentTime = new Date().getTime()
    if (
      voucherTime < VOUCHER_EXPIRED_TIME &&
      voucherTime - currentTime <= VOUCHER_EXPIRED_SOON_7_DAY
    ) {
      const expiredDate = (voucherTime - currentTime) / VOUCHER_EXPIRED_SOON_1_DAY
      if(expiredDate >= 1)  {
        const roundedDate = Math.round(expiredDate)
        result = expiredDate > roundedDate ? roundedDate + 1 : roundedDate
      }
      else result = 1
    }
    return result
  }, [])

  const getStyleView = useMemo(() => {
    const commonStyle = "border flex min-w-[374px] cursor-pointer rounded-5"
    const borderView =
      itemType === VOUCHER_TYPE.SHIPPING
        ? "border-blue-500"
        : itemType === VOUCHER_TYPE.DISCOUNT
          ? "border-blue-500"
          : itemType === VOUCHER_TYPE.LIVE_STREAM
            ? "border-red-500"
            : " border-orange-dark-300"
    const bgActiveView = itemType === VOUCHER_TYPE.SHIPPING
      ? "bg-emerald-25"
      : itemType === VOUCHER_TYPE.DISCOUNT
        ? "bg-blue-25"
        : itemType === VOUCHER_TYPE.LIVE_STREAM
          ? "bg-red-50"
          : "bg-orange-dark-25"
    const backgroundView = !isVoucherApply ? "bg-gray-50 opacity-50" : bgActiveView
    return {
      wrapperView:
        itemType === VOUCHER_TYPE.SHIPPING
          ? "bg-blue-25"
          : itemType === VOUCHER_TYPE.DISCOUNT
            ? "bg-blue-25"
            : "bg-orange-dark-25",
      contentView: `${commonStyle} ${borderView} ${backgroundView}`,
      lineView: borderView,
    }
  }, [itemType, isVoucherApply])

  const getIcon = useMemo(()=> {
    let result = discountVoucherIcon
    if(itemType === VOUCHER_TYPE.LIVE_STREAM) result = livestreamVoucherIcon
    else if (itemType === VOUCHER_TYPE.DISCOUNT)result = discountSoctripIcon
    else if (itemType === VOUCHER_TYPE.SHIPPING) result = discountShippingIcon
  
    return result
  } , [])

  const getReward = useMemo(() => {
    if(discount_type === "PERCENTAGE") {
      return `${mathRoundNumber(discount_percent || 0)}%`
    } else {
      const value = isSystemVoucher ? discount_price : discount_amount
      return convertCurrency(value || 0)
    }
  }, [])
  return (
    <div
      className={`rounded-5 ${
        index ? "mt-3" : ""
      }`}
    >
      <div className={getStyleView?.contentView} onClick={updateSelectedItem}>
        <div className="relative flex w-[88px] items-center justify-center">
          {avatar_id ? (
            <>
              <div className="h-[48px] w-[48px]">
                <ImgCustom
                  className="h-full w-full"
                  alt="voucherIcon"
                  url={avatar_id as string}
                />
              </div>
            </>
          ) : (
            <img
              className="h-[48px] w-[48px]"
              src={
                getIcon
              }
              alt="discountIcon"
            />
          )}
          <div
            className={`absolute top-[-7px] right-[-6px] h-[12px] w-[12px] rotate-[135deg] rounded-[50%] border ${getStyleView?.lineView} border-b-[transparent] border-l-[transparent] bg-white`}
          />
          <div
            className={`absolute bottom-[-7px] right-[-6px] h-[12px] w-[12px] rotate-[-45deg] rounded-[50%] border ${getStyleView?.lineView} border-b-[transparent] border-l-[transparent] bg-white`}
          />
        </div>
        <div
          className={`my-auto h-[65px] w-[1px] border-l-[1px] border-dashed ${getStyleView?.lineView}`}
        ></div>
        <div className="flex flex-1 p-3">
          <div className="flex flex-1 flex-col">
            <span className="text-16 font-semibold leading-24 text-gray-700">
              {name}
            </span>
            <span className="mt-2px text-[12px] font-semibold leading-[16px] text-gray-700">
              {capitalizedFirst(t("live-stream.reward", {value: getReward}))}
            </span>
            <div className="mt-[2px] flex items-center justify-between text-12 font-medium leading-18 text-gray-700">
              <span>
                {capitalizedFirst(
                  t("checkoutPage.minSpend", {
                    minSpend:
                      convertCurrency(voucher_discount_range ? getMinSpendPrice : minimum_order_price),
                  })
                )}
              </span>
            </div>
            {is_limit_usage ? (
              <>
                <div className="mt-[2px]" id="voucherProgress">
                  <ProgressBar
                    color={"#DC6803"}
                    value={usePercent}
                    className="max-h-[8px]"
                  />
                </div>
              </>
            ) : null}
            <div className="flex items-center justify-between text-[10px] leading-[16px] text-gray-500">
              {is_limit_usage ? (
                <span>
                  {capitalizedFirst(
                    t("shoppingCartPage.voucher-use", {
                      count: usePercent,
                    })
                  )}
                </span>
              ) : null}

              <span className={isExpiringSoon ? "text-red-600" : ""}>
                {isExpiringSoon
                  ? (
                    getIsExpiringData === 1
                      ? capitalizedFirst(t("checkoutPage.expires-in-one-day", {value: getIsExpiringData}))
                      : capitalizedFirst(t("checkoutPage.expiredSoon", {value: getIsExpiringData}))
                  )
                  : capitalizedFirst(
                    t("checkoutPage.expiredDate", {
                      expiredDate: getExpiredDay,
                    })
                  )}
              </span>
            </div>
          </div>
          {!isReadOnly ? (
            <div className="ml-1 flex w-[16px] items-center">
              <Checkbox
                disabled={!isVoucherApply}
                checked={
                  voucherSelected?.id === id ||
                  shipVoucherSelected?.id === id ||
                  liveVoucherSelected?.id === id
                }
                readOnly
              />
            </div>
          ) : null}
        </div>
      </div>
      {!isVoucherApply &&
      !isInvalid &&
      shopBilling &&
      minimum_order_price - shopBilling > 0 ? (
          <div className="flex items-center px-[10px] py-[4px] ">
            <img
              className="h-[12px] w-[12px]"
              alt="warningIcon"
              src={warningIcon}
            />
            <span className="ml-[4px] bg-white text-[10px] leading-[16px] text-orange-dark-600">
              {capitalizedFirst(
                t("shoppingCartPage.warning-voucher", {
                  value: convertCurrency(minimum_order_price - shopBilling),
                })
              )}
            </span>
          </div>
        ) : null}
      {isInvalid &&
      shopBilling &&
      (minimum_order_price - shopBilling > 0 ||
        (max_order_price && shopBilling - max_order_price > 0)) ? (
          <div className="flex items-center px-[10px] py-[4px] ">
            <img
              className="h-[12px] w-[12px]"
              alt="warningIcon"
              src={warningIcon}
            />
            {max_order_price && shopBilling - max_order_price > 0 ? (
              <span className="ml-[4px] bg-white text-[10px] leading-[16px] text-orange-dark-600">
                {capitalizedFirst(
                  t("shoppingCartPage.reduce-voucher", {
                    value: convertCurrency(shopBilling - max_order_price),
                  })
                )}
              </span>
            ) : (
              <span className="ml-[4px] bg-white text-[10px] leading-[16px] text-orange-dark-600">
                {capitalizedFirst(
                  t("shoppingCartPage.warning-voucher", {
                    value: convertCurrency(minimum_order_price - shopBilling),
                  })
                )}
              </span>
            )}
          </div>
        ) : null}
    </div>
  )
}

export default ItemView
