import { InputText } from "primereact/inputtext"
import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ImgCustom from "src/components/img-custom"
import {
  GetCategoryFilterOption,
  GetProductList,
  handleGetMerchantData,
} from "src/services/merchant-service"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { CategoriesProperties, TreeNodePros } from "./props"
import { ShopProperties } from "../merchant-page/props"
import {
  Tree,
  TreeCheckboxSelectionKeys,
  TreeExpandedKeysType,
  TreeMultipleSelectionKeys,
} from "primereact/tree"
import "./index.scss"
import EmptyPage from "../empty-page"
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator"
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem"
import { Menu } from "primereact/menu"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
// import TreeNode from "primereact/treenode"
import SkeletonLoading from "src/components/skeleton-loading"
import { getBloomFilter } from "src/services/bookmark-service"
import MerchantProfileCategory from "src/components/merchant-profile-category"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import CustomCard from "src/components/card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { CustomCardProps } from "src/components/card-custom/card-custom/custom-card-props"
import useWindowDimensions from "src/utils/screen-size"
import { convertViToEn } from "src/utils/common"

export default function MerchantCategory() {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const menuRight = useRef<Menu>(null)
  const { width } = useWindowDimensions()
  const authStore = useSelector((state: RootState) => state.auth)
  const [categoryNameFilter, setCategoryNameFilter] = useState<string>("")
  const [sort, setSort] = useState<string>("name")
  const [loading, setLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<number>(12)
  const [first, setFirst] = useState<number>(0)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [background, setBackground] = useState<string>("")
  const [avatar, setAvatar] = useState<string>("")
  const [shopData, setShopData] = useState<ShopProperties>()
  const [categoryLists, setCategoryLists] = useState<CategoriesProperties[]>()
  const [nodeValue, setNodeValue] = useState<TreeNodePros[]>()
  const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({})
  const [isExpand, setIsExpand] = useState<boolean>(false)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [isShowCategory, setIsShowCategory] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [selectedCategory, setSelectedCategory] = useState<
    string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null
  >("")

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [parentId, setParentId] = useState<any>("")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [parentIdLevel2, setParentIdLevel2] = useState<any>("")
  const [selectCategoryName, setSelectCategoryName] = useState<
    string | undefined
  >("")
  const [productLists, setProductLists] = useState<CustomCardProps[]>([])
  const [emptyMessage, setEmptyMessage] = useState<string>(
    "Network Error. Please refresh page try again."
  )

  const itemsSearchContent: MenuItem[] = [
    {
      data: "name",
      label: "A->Z",
      icon: "pi pi-sort-alpha-down",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "-name",
      label: "Z -> A",
      icon: "pi pi-sort-alpha-down-alt",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "-price|double",
      label: translateCapitalize("global.high-to-low"),
      icon: "pi pi-sort-amount-down",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "price|double",
      label: translateCapitalize("global.low-to-high"),
      icon: "pi pi-sort-amount-up",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
  ]

  const SearchInput = () => {
    const [searchValue, setSearchValue] = useState<string>("")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeydown = (event: any) => {
      if (event.key === "Enter") {
        if (searchValue) {
          navigate(
            `/merchant-page/${shopData?.id}/search?keyword=` + searchValue,
            {
              state: {
                searchValue: searchValue,
                isSearchInShop: true,
                shopData: shopData,
              },
            }
          )
        } else {
          navigate(`/shopping/merchant-page/${shopData?.id}/search` + searchValue, {
            state: {
              searchValue: searchValue,
              isSearchInShop: true,
              shopData: shopData,
            },
          })
        }
      }
    }
    return (
      <div>
        <span className="p-input-icon-left w-full">
          <i className="pi pi-search" />
          <InputText
            className="min-w-[300px] max-w-[400px] rounded-3"
            onKeyDown={handleKeydown}
            placeholder={translateCapitalize("global.search")}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </span>
      </div>
    )
  }

  const handleGetCategoryList = async (id: string) => {
    await GetCategoryFilterOption(
      encodeURIComponent(`shop_id==${id},is_used==true`)
    )
      .then((response) => {
        setCategoryLists(response?.data?.data?.data)
      })
      .catch((error) => error)
  }

  const handleGetShopData = async (id: string) => {
    await handleGetMerchantData(id)
      .then((response) => {
        setShopData(response?.data?.data)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    scrollToTop()
    setIsExpand(true)
    if (location.state?.shopId) {
      localStorage.setItem("shopId", location.state?.shopId)
      handleGetShopData(location.state?.shopId)
      handleGetCategoryList(location.state?.shopId)
    } else {
      const id = localStorage.getItem("shopId")
      if (id) {
        handleGetShopData(id)
        handleGetCategoryList(id)
      }
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nest = (items: any, id = null, link = "parent_id") =>
    items
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((item: any) => item[link] === id)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((item: any) => ({ ...item, children: nest(items, item?.id) }))

  useEffect(() => {
    const newCategoryList = categoryLists?.map((category) => ({
      ...category,
      label: category?.name,
      key: category?.id,
    }))
    if (newCategoryList?.length) {
      setNodeValue(nest(newCategoryList))
    }
  }, [categoryLists])

  const handleSelectCategory = (
    event: string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null
  ) => {
    setIsExpand(false)
    setSelectedCategory(event)
    const data = categoryLists?.find((item) => item?.id === event)

    setSelectCategoryName(data?.reference?.replaceAll(">", " > "))
    navigate(
      "?category=" + data?.name?.toLocaleLowerCase().replaceAll(" ", "-"),
      {
        state: { shopId: shopData?.id, categoryId: data?.id },
      }
    )
  }

  useEffect(() => {
    if (selectedCategory && shopData?.id) {
      setLoading(true)
      const timeout = setTimeout(() => {
        getProductList()
      }, 300)
      return () => clearTimeout(timeout)
    } else {
      setEmptyMessage("No data found. Please try with other filters.")
    }
  }, [selectedCategory, sort, currentPage, shopData])

  // handle get product list
  const getProductList = async () => {
    setLoading(true)
    await GetProductList(
      shopData?.id,
      currentPage,
      rows,
      encodeURIComponent(`catalog_ids==${selectedCategory}`),
      encodeURIComponent(sort)
    )
      .then((response) => {
        if (response?.data?.data) {
          setTotalProducts(response?.data?.data?.totalElement)
          setProductLists(response?.data?.data?.data)
          setLoading(false)
          setIsEmpty(false)
        }
        if (response?.data?.data?.totalElement === 0) {
          setIsEmpty(true)
          setEmptyMessage("No data found. Please try with other filters.")
        }
      })
      .catch(() => {
        setEmptyMessage("Network Error. Please refresh page try again.")
        setLoading(false)
        setIsEmpty(true)
      })
  }

  const handleReloadData = async () => {
    handleGetBloomData()
  }

  // Paginator
  const scrollToTop = () => {
    document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
  }
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    scrollToTop()
    setFirst(event.first)
    setRows(event.rows)
    setCurrentPage(event.page)
  }

  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  // edit path
  useEffect(() => {
    const param = new URL(window.location.href).searchParams.get("category")
    if (param) {
      setCategoryNameFilter(param)
    }
  }, [params, location])

  useEffect(() => {
    if (categoryNameFilter && categoryLists?.length !== 0) {
      const categoryName = categoryNameFilter
        .replaceAll("-", "")
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLocaleUpperCase()
      const dataMatched = categoryLists?.find(
        (category) =>
          category.name
            .replaceAll(" ", "")
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleUpperCase() === categoryName
      )
      if (dataMatched) {
        setSelectCategoryName(dataMatched?.reference?.replaceAll(">", " > "))
        setSelectedCategory(dataMatched.id)
      }
    }
  }, [categoryNameFilter, categoryLists])

  useEffect(() => {
    if (shopData) {
      setBackground(shopData?.background?.id)
      setAvatar(shopData?.avatar?.id)
      localStorage.setItem("shopId", shopData?.id)
    }
  }, [shopData])

  useEffect(() => {
    if (location.state?.shopId) {
      localStorage.setItem("shopId", location.state?.shopId)
      handleGetShopData(location.state?.shopId)
      handleGetCategoryList(location.state?.shopId)
    } else {
      const id = localStorage.getItem("shopId")
      if (id) {
        handleGetShopData(id)
        handleGetCategoryList(id)
      }
    }
  }, [params])

  const expandNode = (
    node: TreeNodePros,
    _expandedKeys: TreeExpandedKeysType
  ) => {
    //
    if (node.children && node.children.length) {
      if (node?.parent_id) {
        _expandedKeys[node.parent_id as string] = true
      }
      _expandedKeys[node.key as string] = true

      for (const child of node.children) {
        if (child?.id === parentIdLevel2) {
          expandNode(child, _expandedKeys)
        }
      }
    }
  }

  // handle expand
  const expandAll = () => {
    if (isExpand) {
      const _expandedKeys = {}
      const data = categoryLists?.find((cate) => cate.id === selectedCategory)
      if (data?.parent_id) {
        const parentData = categoryLists?.find(
          (cate) => cate?.id === data?.parent_id
        )
        if (parentData?.parent_id !== null) {
          setParentIdLevel2(parentData?.id)
        } else {
          setParentId(parentData?.id)
        }

        if (parentData?.parent_id) {
          const parent = categoryLists?.find(
            (cate) => cate?.id === parentData?.parent_id
          )
          setParentId(parent?.id)
        }
      }

      nodeValue?.forEach((node) => {
        if (node?.key === parentId) {
          expandNode(node, _expandedKeys)
        }
      })
      setExpandedKeys(_expandedKeys)
    }
  }

  useEffect(() => {
    expandAll()
  }, [isExpand, selectedCategory, parentId])

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])

  useEffect(() => {
    if (width >= 768) {
      setIsShowCategory(true)
    }
  }, [width])

  return (
    <div id="top" className="mx-auto max-w-[1200px] px-3">
      <div className="flex w-full flex-col py-4">
        {/* header */}
        <div className="relative">
          {/* background image */}
          <div className="flex h-[150px] w-full items-center rounded-t-3 bg-blue-light-100 object-cover object-center">
            {background && (
              <ImgCustom
                url={background}
                alt="header"
                className="h-[150px] w-full rounded-3 object-cover object-center"
              />
            )}
          </div>
          <div className="absolute left-1 top-[30%] z-[1] rounded-4 sm:left-4 sm:top-[45%] md:top-[45%] lg:top-[50%] xl:top-[50%]">
            {/* shop info */}
            <MerchantProfileCategory
              id={shopData?.id as string}
              avatar={avatar}
              description={shopData?.slogan}
              followers={shopData?.followers}
              rating={shopData?.rating}
              name={shopData?.name}
              productNumber={shopData?.total_products}
            />
          </div>
          {/* Search input */}
          <div className="absolute right-1 bottom-1 w-[300px]">
            <SearchInput />
          </div>
        </div>
        {/* content */}
        <div className="mt-6 flex w-full flex-col gap-1">
          {/* contents */}
          <div className="flex flex-col gap-3 md:grid md:grid-cols-4">
            <div className="flex h-fit flex-col rounded-3 bg-white md:col-span-1">
              <div className="flex w-full items-center justify-between">
                <div className="w-full truncate border-b px-4 py-[17px] text-14 font-semibold text-gray-700 lg:py-[16px] lg:text-16">
                  {capitalizedFirst(
                    handleTranslate("merchant-page.product-category")
                  )}
                </div>
                <div className="px-1 md:hidden">
                  {isShowCategory ? (
                    <i
                      onClick={() => setIsShowCategory(!isShowCategory)}
                      className="pi pi-angle-down"
                      style={{ fontSize: "1rem" }}
                    ></i>
                  ) : (
                    <i
                      onClick={() => setIsShowCategory(!isShowCategory)}
                      className="pi pi-angle-up"
                      style={{ fontSize: "1rem" }}
                    ></i>
                  )}
                </div>
              </div>
              {isShowCategory ? (
                <div className="no-scrollbar mt-1 max-h-[700px] min-h-[300px] w-full overflow-y-auto md:max-h-[unset]">
                  <Tree
                    value={nodeValue}
                    selectionMode="single"
                    selectionKeys={selectedCategory}
                    onSelectionChange={(e) => handleSelectCategory(e?.value)}
                    expandedKeys={expandedKeys}
                    onToggle={(e) => setExpandedKeys(e.value)}
                  />
                </div>
              ) : null}
            </div>
            <div className="h-fit rounded-3 md:col-span-3 ">
              <div className="flex w-full flex-col rounded-3 bg-white">
                <div className="flex w-full items-center gap-2 truncate border-b px-4 py-[9px] text-16 font-semibold text-gray-700">
                  <p className="w-full truncate">{selectCategoryName}</p>
                  <p
                    onClick={() => {
                      const shopCode = shopData?.id?.split("-")?.length ? shopData?.id?.split("-")[0]: ""
                      navigate(`/shopping/merchant/${convertViToEn(shopData?.name as string)}/${shopData?.id}/${shopCode}`)
                    }}
                    className="flex w-fit cursor-pointer items-center justify-end text-14 text-blue-500 hover:text-blue-700 hover:underline"
                  >
                    {capitalizedFirst(
                      handleTranslate("merchant-page.back-to-shop")
                    )}
                  </p>
                  <div
                    onClick={(event) => menuRight?.current?.toggle(event)}
                    className="relative flex cursor-pointer items-center gap-4px rounded-3 bg-gray-50 px-3 hover:bg-white"
                  >
                    <p className="truncate py-1 text-14 font-semibold text-gray-600">
                      {translateCapitalize("global.sort")}{" "}
                    </p>
                    <div className="fill-gray-600 stroke-gray-600">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <Menu
                      className="mt-1 px-4px"
                      model={itemsSearchContent}
                      popup
                      ref={menuRight}
                      id="popup_menu_right"
                    />
                  </div>
                </div>
                <div className="mt-1 h-full w-full px-3">
                  {loading ? (
                    <div className="w-full">
                      <SkeletonLoading limit={4} />
                    </div>
                  ) : (
                    <div className="mt-1 w-full pb-3">
                      {isEmpty ? (
                        <div className="flex h-[675px] w-full items-center justify-center">
                          <EmptyPage message={emptyMessage} />
                        </div>
                      ) : (
                        <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                          {productLists?.map(
                            (pro: CustomCardProps, index: number) => (
                              <div key={index}>
                                <CustomCard
                                  key={pro.id}
                                  id={pro.id}
                                  typeCard={CardCustomType.product}
                                  imgUrl={pro.avatar?.id}
                                  avatar={pro.avatar}
                                  name={pro.name}
                                  point={pro.point}
                                  price={pro.price}
                                  price_after_tax={pro.price_after_tax}
                                  original_price={pro.original_price}
                                  original_price_after_tax={pro.original_price_after_tax}
                                  sum_review={pro.sum_review}
                                  quantity={pro.quantity}
                                  voucherOfProducts={[pro.voucher as string]}
                                  productId={pro.id}
                                  shop_name={pro.shop?.name}
                                  sub_label={pro.sub_label}
                                  shop={pro.shop}
                                  is_bookmark={pro.is_bookmark}
                                  handleReload={handleReloadData}
                                  bloom_filter={bloomData}
                                  sold={pro?.sold}
                                  is_flash_deal={pro.is_flash_deal}
                                  flash_deal_status={pro?.flash_deal_status}
                                  flash_deals={pro?.flash_deals}
                                  flash_deals_time={pro?.flash_deals_time}
                                  shop_id={pro?.shop_id}
                                />
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {totalProducts > rows ? (
                <div className="mt-4 flex justify-center border-t border-gray-200 py-1">
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalProducts}
                    onPageChange={onPageChange}
                    pageLinkSize={3}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
