import { AddressDirectoryControllerApi } from "@soctrip/shipment-service"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { configHeader } from "src/config/interceptors"
import { SERVICE, TYPE_PAGE } from "src/constants/common"
import { getCurrencies } from "src/services/billing-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { AddressType, CurrencyType, RestaurantType, ShopInformationFormType } from "../types"

const WARD_DATA_STATE = {
  INIT: "INIT",
  VALID: "VALID",
  IN_VALID: "IN_VALID",

}
const ShopInformationForm = (props: ShopInformationFormType) => {
  const { dataForm, typePage, restaurantType } = props
  const [countryData, setCountryData] = useState<AddressType[]>([])
  const [cityData, setCityData] = useState<AddressType[]>([])
  const [districtData, setDistrictData] = useState<AddressType[]>([])
  const [wardData, setWardData] = useState<AddressType[]>([])
  const [currencyData, setCurrencyData] = useState<CurrencyType[]>([])
  const [isWardValid, setIsWardValid] = useState<string>(WARD_DATA_STATE.INIT)
  const { t } = useTranslation()

  const isForeign = (dataForm.values?.shop_country?.code_name ?? "") != "vn"

  const isInValidField = (name: string) => {
    return !!(dataForm?.touched[`${name}`] && dataForm?.errors[`${name}`])
  }

  const itemTemplate = (option: RestaurantType) => {
    return (
      <div className="align-items-center flex">
        <div className="text-14 leading-20 text-gray-700">{option.name}</div>
      </div>
    )
  }
  const headerTemplate = () => <div className="hidden" />

  const getCountryList = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressCountriesGet()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.country_id,
              name: i?.country_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setCountryData(result)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }

  const getCityList = (countryId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressProvincesGet(countryId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.province_id,
              name: i?.province_name,
              postal_code: i?.postal_code || "",
              code_name: i?.code_name,
            })
          })
          setCityData(result)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }
  const getDistrictList = (cityId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressDistrictsGet(cityId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.district_id,
              name: i?.district_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setDistrictData(result)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }

  const getWardList = (districtId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressWardsGet(districtId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.ward_id,
              name: i?.ward_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })          
          if(!result.length) setIsWardValid(WARD_DATA_STATE.IN_VALID)
          setWardData(result)
        } else {
          setIsWardValid(WARD_DATA_STATE.IN_VALID)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }
  //trigger country change
  useEffect(() => {
    if (dataForm?.values?.shop_country) {
      setCityData([])
      setDistrictData([])
      // setWardData([])
      dataForm.setFieldValue("shop_city", null)
      dataForm.setFieldValue("shop_district", null)
      dataForm.setFieldValue("shop_ward", null)

      getCityList(dataForm?.values?.shop_country?.id)
    }
  }, [dataForm?.values?.shop_country])

  // trigger city change
  useEffect(() => {
    if (dataForm?.values?.shop_city) {
      setDistrictData([])
      // setWardData([])
      dataForm.setFieldValue("shop_district", null)
      dataForm.setFieldValue("shop_ward", null)

      getDistrictList(dataForm?.values?.shop_city?.id)
    }
  }, [dataForm?.values?.shop_city])

  // trigger district change
  useEffect(() => {
    if (dataForm?.values?.shop_district) {
      setWardData([])
      dataForm.setFieldValue("shop_ward", null)
      setIsWardValid(WARD_DATA_STATE.INIT)
      getWardList(dataForm?.values?.shop_district?.id)
    }
  }, [dataForm?.values?.shop_district])

  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const res = await getCurrencies()
        setCurrencyData(res?.data?.data ?? [])
      } catch (error) {
        console.log(error)
      }
    }
    fetchCurrencies()
  }, [])

  useEffect(() => {
    function initView() {
      getCountryList()
    }
    initView()
  }, [])

  const selectedCurrencyTemplate = (option: CurrencyType) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>
            {option.code} - {option.name}
          </div>
        </div>
      )
    }
    return <span>{t("request-open-page.select-a-currency") ?? ""}</span>
  }

  const countryOptionTemplate = (option: CurrencyType) => {
    return (
      <div className="align-items-center flex">
        <div>
          {option.code} - {option.name}
        </div>
      </div>
    )
  }

  return (
    <div className="rounded mt-3 w-full rounded-3 border-gray-200 bg-white p-3">
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {t("request-open-page.shop-information")}
            </span>
          </div>
        </div>
        <div className="ml-4 flex w-full flex-col lg:w-[760px]">
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.merchant-name")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <InputText
              className={`mt-[6px] w-full ${isInValidField("shop_name") ? "p-invalid" : ""} `}
              placeholder={t("request-open-page.enter-merchant-name") || ""}
              name="shop_name"
              value={dataForm?.values?.shop_name}
              onChange={(e) => {
                if (!dataForm?.touched?.shop_name)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ shop_name: true },
                  })
                dataForm.setFieldValue("shop_name", e.target.value)
              }}
            />
          </div>
          {dataForm.errors.shop_name && dataForm.touched.shop_name && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm.errors.shop_name}
            </span>
          )}
          <fieldset className="border-gray-200 rounded-2 border p-3 pt-1 mt-1">
            <legend className="px-2 text-14 font-medium text-gray-700">{t("request-open-page.address-line")}</legend>
            <div id="shopInformation" className="grid grid-cols-1 gap-3 md:grid-cols-2">
              {/* COUNTRY */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {t("request-open-page.country")}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  value={dataForm?.values?.shop_country}
                  name="shop_country"
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_country)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_country: true },
                      })

                    dataForm.setFieldValue("shop_country", e.value)
                    dataForm.setFieldValue("shop_city", null)
                    dataForm.setFieldValue("shop_district", null)
                    if(e.value?.code_name === "us" && dataForm?.values?.sub_shop_address) dataForm.setFieldValue("sub_shop_address", "")
                  }}
                  options={countryData}
                  optionLabel="name"
                  placeholder={t("request-open-page.select-a-country") || ""}
                  className={`mt-[6px] w-full ${isInValidField("shop_country") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_country && dataForm.touched.shop_country && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_country}
                  </span>
                )}
              </div>
              {/* CITY */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {isForeign ? t("request-open-page.state") : t("request-open-page.city/province")}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  value={dataForm?.values?.shop_city}
                  name="shop_city"
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_city)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_city: true },
                      })
                    dataForm.setFieldValue("shop_city", e.value)
                  }}
                  options={cityData}
                  optionLabel="name"
                  placeholder={
                    (isForeign
                      ? t("request-open-page.select-a-state")
                      : t("request-open-page.select-a-city/province")) || ""
                  }
                  className={`mt-[6px] w-full ${isInValidField("shop_city") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_city && dataForm.touched.shop_city && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_city}
                  </span>
                )}
              </div>

              {/* DISTRICT */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {isForeign ? t("request-open-page.city") : t("request-open-page.district")}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  name="shop_district"
                  value={dataForm?.values?.shop_district}
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_district)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_district: true },
                      })
                    dataForm.setFieldValue("shop_district", e.value)
                  }}
                  options={districtData}
                  optionLabel="name"
                  placeholder={
                    (isForeign
                      ? t("request-open-page.select-a-city")
                      : t("request-open-page.select-a-district")) || ""
                  }
                  className={`mt-[6px] w-full ${isInValidField("shop_district") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_district && dataForm.touched.shop_district && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_district}
                  </span>
                )}
              </div>

              {/* WARD */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {t("request-open-page.ward")}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  value={dataForm?.values?.shop_ward}
                  name="shop_ward"
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_ward)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_ward: true },
                      })
                    dataForm.setFieldValue("shop_ward", e.value)
                  }}
                  options={wardData}
                  optionLabel="name"
                  placeholder={t("request-open-page.select-a-ward") || ""}
                  className={`mt-[6px] w-full ${isInValidField("shop_ward") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_ward && dataForm.touched.shop_ward && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">                  
                    {isWardValid === WARD_DATA_STATE.IN_VALID ? t("request-open-page.invalid-address") : dataForm.errors.shop_ward}
                  </span>
                )}
              </div>
            </div>

            {/* ADDRESS LINE 1*/}
            <div className="mt-[16px] flex flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {
                  t(dataForm?.values?.shop_country?.code_name === "us" ? "request-open-page.address-line-1" : "request-open-page.address")
                }
                <span className="text-red-600">{" *"}</span>
              </span>
              <InputText
                className={`mt-[6px] w-full ${isInValidField("shop_address") ? "p-invalid" : ""} `}
                placeholder={t(dataForm?.values?.shop_country?.code_name === "us" ? "request-open-page.enter-address-line-1" : "request-open-page.enter-address-line") ||"" }
                value={dataForm?.values?.shop_address}
                name="shop_address"
                onChange={(e) => {
                  if (!dataForm?.touched?.shop_address)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ shop_address: true },
                    })
                  dataForm.setFieldValue("shop_address", e.target.value)
                }}
              />
              <span className="mt-[6px] text-[12px] font-medium leading-[16px] text-[#e24c4c]">
                {capitalizedFirst(t("request-open-page.note-address-1"))}
              </span>
              {dataForm.errors.shop_address && dataForm.touched.shop_address && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.shop_address}
                </span>
              )}
            </div>

            {/* ADDRESS LINE 2 only support for region is USA*/}
            {
              dataForm?.values?.shop_country?.code_name === "us" ? (
                <>
                  <div className="mt-[16px] flex flex-col">
                    <span className="text-14 font-medium leading-20 text-gray-700">
                      {t("request-open-page.address-line-2")}
                    </span>
                    <InputText
                      className={`mt-[6px] w-full ${
                        isInValidField("sub_shop_address") ? "p-invalid" : ""
                      } `}
                      placeholder={t("request-open-page.enter-address-line-2") || ""}
                      value={dataForm?.values?.sub_shop_address}
                      name="sub_shop_address"
                      onChange={(e) => {
                        if (!dataForm?.touched?.sub_shop_address)
                          dataForm.setTouched({
                            ...dataForm.touched,
                            ...{ sub_shop_address: true },
                          })
                        dataForm.setFieldValue("sub_shop_address", e.target.value)
                      }}
                    />
                    <span className="mt-[6px] text-[12px] font-normal leading-[16px]">
                      {capitalizedFirst(t("request-open-page.note-address-2"))}
                    </span>
                    {dataForm.errors.sub_shop_address && dataForm.touched.sub_shop_address && (
                      <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                        {dataForm.errors.sub_shop_address}
                      </span>
                    )}
                  </div>
                </>
              ) : null
            }
          </fieldset>

          {/* CURRENCY */}
          <div className="mt-1 flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.currency")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              value={dataForm?.values?.currency}
              name="currency"
              onChange={(e) => {
                if (!dataForm?.touched?.currency)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ currency: true },
                  })
                dataForm.setFieldValue("currency", e.value)
              }}
              options={currencyData}
              optionLabel="name"
              optionValue="code"
              valueTemplate={selectedCurrencyTemplate}
              itemTemplate={countryOptionTemplate}
              placeholder={t("request-open-page.select-a-currency") ?? ""}
              className={`mt-[6px] w-full ${isInValidField("currency") ? "p-invalid" : ""} `}
            />
            {dataForm.errors.currency && dataForm.touched.currency && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.currency}
              </span>
            )}
          </div>

          {typePage === TYPE_PAGE.FOOD ? (
            <div className="mt-3 flex flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {"Category type "}
                <span className="text-red-600">{" *"}</span>
              </span>
              <MultiSelect
                name="shop_category"
                value={dataForm?.values?.shop_category}
                options={restaurantType}
                onChange={(e) => {
                  if (!dataForm?.touched?.shop_category)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ shop_category: true },
                    })
                  dataForm.setFieldValue("shop_category", e.value)
                }}
                optionLabel="name"
                placeholder="Select category"
                panelHeaderTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                className={`mt-[6px] w-full ${isInValidField("shop_category") ? "p-invalid" : ""}`}
                display="chip"
              />
              {dataForm.errors.shop_category && dataForm.touched.shop_category && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.shop_category}
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ShopInformationForm
