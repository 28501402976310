/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useLayoutEffect, useState } from "react"
import { CategoryListProperties, DataProperties } from "./props"
import { IMAGE_TYPE, LAYOUT_TYPE } from "src/constants/common"
import { Galleria } from "primereact/galleria"
import { categoriesItemProps } from "../shopping-homepage-view/shopping-homepage-content/props"
import ImgCustom from "../img-custom"
import { getListProductByIdLists } from "src/services/merchant-service"
import {
  ImageSlideshowProperties,
  SlideShowDataProperties,
} from "src/pages/merchant-page/props"
import "./index.scss"
import { useNavigate } from "react-router-dom"
import SkeletonLoading from "../skeleton-loading"
import CarouselCustom from "../carousel"
import { getBloomFilter } from "src/services/bookmark-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import CustomCard from "../card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { CustomCardProps } from "../card-custom/card-custom/custom-card-props"
import { ShopCatalogControllerApi } from "@soctrip/axios-catalog-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { Tooltip } from "primereact/tooltip"

export default function CategoryView(data: DataProperties) {
  const authStore = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const [slideshowData, setSlideshowData] = useState<SlideShowDataProperties[]>(
    []
  )
  const [dataContent, setDataContent] = useState<any[]>([])

  const [categoryListData, setCategoryListData] =
    useState<CategoryListProperties[]>()
  const [productListData, setProductListData] = useState<CustomCardProps[]>()
  const [productIds, setProductIds] = useState<string[]>([])
  const [categoryIds, setCategoryIds] = useState<string[]>([])
  const [limit, setLimit] = useState<number>(20)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")

  const handleClickCate = (item: categoriesItemProps) => {
    navigate(
      "/shopping/merchant-page/categories?category=" +
        item.name?.toLocaleLowerCase().replaceAll(" ", "-"),
      {
        state: { shopId: data.shopId, categoryId: item.id },
      }
    )
  }

  useEffect(() => {
    if (data?.code === LAYOUT_TYPE.SLIDESHOW) {
      const newSlideshowData: { itemImageSrc: string; alt: string }[] = []
      data?.dataContent?.forEach((element: ImageSlideshowProperties) => {
        newSlideshowData.push({
          itemImageSrc: `${process.env.REACT_APP_API_URL}/storage/files/web/${element?.id}`,
          alt: "slideshow",
        })
      })
      setSlideshowData(newSlideshowData)
    }
  }, [data?.dataContent])

  useLayoutEffect(() => {
    setDataContent(data?.dataContent)
  }, [data?.dataContent])

  useEffect(() => {
    if (data?.code === LAYOUT_TYPE.CATEGORY_LIST) {
      if (data?.fullData?.is_category_list) {
        setCategoryIds([])
        data?.fullData?.category_ids?.map((id) => {
          setCategoryIds((previous: string[]) => [...previous, id])
        })
      }
    }
    // product list
    if (data?.code === LAYOUT_TYPE.PRODUCT_LIST) {
      if (data?.fullData?.is_product_list) {
        setProductIds([])
        data?.fullData?.product_ids?.map((id) => {
          setProductIds((previous: string[]) => [...previous, `ids=${id}`])
        })
      }
    }
  }, [data?.fullData])

  if (!data) {
    return <div className="h-full w-full">Loading</div>
  }

  // get product ids list
  useEffect(() => {
    getProductList()
  }, [productIds])

  // handle get product list
  const getProductList = async () => {
    if (productIds.length !== 0) {
      setLoading(true)
      await getListProductByIdLists(productIds.join("&"))
        .then((response) => {
          setProductListData(response?.data?.data)
          setTotalProducts(response?.data?.data?.length)
          setLoading(false)
        })
        .catch((error) => error)
    }
  }

  // handle reload
  const handleReloadData = async () => {
    handleGetBloomData()
  }

  const handleGetCategoryList = async (ids: string[]) => {
    await new ShopCatalogControllerApi(configHeader(SERVICE.CATALOG))
      .shopCatalogsFiltersGet(ids)
      .then((response: any) => {
        console.log(response?.data)
        setCategoryListData(response?.data?.data)
      })
      .catch((error) => error)
  }

  // get category ids list
  useEffect(() => {
    if (categoryIds.length !== 0) {
      handleGetCategoryList(categoryIds)
    }
  }, [categoryIds])

  const handleViewMore = () => {
    setLimit(limit + 12)
  }

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])

  return (
    <div className="h-full w-full">
      <div className="flex items-center">
        {data?.code === LAYOUT_TYPE.IMAGE_WITH_TEXT && data?.dataContent && (
          <div className=" mb-3 flex max-h-[400px] min-h-[200px] w-full flex-col items-center justify-center pb-1">
            <ImgCustom
              url={data?.dataContent[0]?.id}
              alt={`${data?.dataContent[0]?.content} image`}
              className="h-[200px] w-full rounded-4 object-cover object-center md:h-[350px]"
              type={IMAGE_TYPE.WEB}
            />
            {data?.dataContent[0]?.content && (
              <div className="flex w-full items-center justify-center bg-opacity-25 pt-1 text-16 font-medium italic text-gray-600">
                {data?.dataContent[0]?.content}
              </div>
            )}
          </div>
        )}
        {data?.code === LAYOUT_TYPE.BANNER && data?.dataContent && (
          <div className="mb-4 flex min-h-[200px] w-full items-center justify-center rounded-4 bg-blue-light-50">
            <ImgCustom
              url={data?.dataContent[0]?.id}
              alt="banner image"
              className="h-[200px] w-full rounded-4 object-cover object-center md:h-[350px]"
              type={IMAGE_TYPE.WEB}
            />
          </div>
        )}
        {/* single slideshow */}
        {data?.code === LAYOUT_TYPE.SLIDESHOW && (
          <div className="mb-4 max-h-[400px] min-h-[200px] w-full rounded-4 bg-blue-light-50">
            <Galleria
              value={slideshowData}
              showIndicators
              showIndicatorsOnItem
              showThumbnails={false}
              autoPlay
              circular
              transitionInterval={2500}
              item={(item) => {
                return (
                  <img
                    className="h-[200px] w-full rounded-4 object-cover object-center md:h-[350px]"
                    src={item?.itemImageSrc}
                    alt={item?.alt}
                  />
                )
              }}
            />
          </div>
        )}

        {/* multiple slideshow */}
        {data?.code === LAYOUT_TYPE.MULTIPLE_SLIDESHOW && (
          <div className="mb-4 flex max-h-[400px] min-h-[350px] w-full items-center rounded-4 bg-blue-light-25 py-1 px-1 shadow-sm">
            <div className="h-full w-full">
              <CarouselCustom images={dataContent} />
            </div>
          </div>
        )}

        {data?.code === LAYOUT_TYPE.TITLE && data?.dataContent && (
          <div
            className="mb-2 h-full w-full text-center text-20"
            style={{
              fontSize: data?.dataContent.fontSize,
              fontWeight: data?.dataContent.fontWeight,
            }}
            dangerouslySetInnerHTML={{ __html: data?.dataContent[0]?.content }}
          ></div>
        )}

        {data?.code === LAYOUT_TYPE.PARAGRAPH && data?.dataContent && (
          <div
            className="mb-3 h-full w-full text-14"
            dangerouslySetInnerHTML={{ __html: data?.dataContent[0]?.content }}
          ></div>
        )}
        {data?.code === LAYOUT_TYPE.CATEGORY_LIST && (
          <div className="my-3 flex h-full w-full flex-col gap-1 rounded-3 bg-white p-3">
            <div className="flex h-full w-full items-center">
              <div className="h-full w-full overflow-hidden">
                <div
                  id="merchant-page-categories"
                  className="relative flex h-full w-full gap-1"
                >
                  <Swiper
                    navigation={true}
                    spaceBetween={25}
                    modules={[Navigation]}
                    slidesPerView={"auto"}
                  >
                    {categoryListData?.map((cate, index) => (
                      <SwiperSlide key={index}>
                        <div
                          onClick={() => handleClickCate(cate)}
                          className="flex max-h-[190px] w-[100px] cursor-pointer flex-col items-center gap-4px rounded-2 text-gray-700"
                        >
                          <div className="group h-[70px] w-[70px] sm:h-[80px] sm:w-[80px] md:h-[90px] md:w-[90px] lg:h-[100px] lg:w-[100px]">
                            <ImgCustom
                              url={cate?.image?.id as string}
                              alt="image"
                              className="h-[60px] w-[60px] rounded-full border-[4px] border-white border-opacity-70 object-cover object-center shadow-lg shadow-blue-light-200 transition group-hover:shadow-blue-light-300 sm:h-[70px] sm:w-[70px] md:h-[80px] md:w-[80px] lg:h-[90px] lg:w-[90px]"
                            />
                          </div>
                          <Tooltip target=".target-name" />
                          <div className="flex w-full flex-col items-center">
                            <div
                              id="product_title"
                              className="target-name w-full max-w-[110px] bg-transparent text-center text-14 font-semibold leading-6 text-gray-600"
                              data-pr-tooltip={cate?.name}
                              data-pr-position="top"
                              data-pr-at="right-2 top"
                            >
                              {cate?.name}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        )}

        {data?.code === LAYOUT_TYPE.PRODUCT_LIST &&
          data?.fullData?.is_product_list && (
          <div className="mb-4 mt-3 flex h-full w-full flex-col gap-2">
            {/* <div className="flex w-full items-center">
              {data?.fullData?.shop_decoration_collection?.name !== "" && (
                <div className="w-full text-18 font-semibold text-gray-700">
                  {data?.fullData?.shop_decoration_collection?.name}
                </div>
              )}
            </div> */}
            {loading ? (
              <div className="h-full w-full">
                <SkeletonLoading limit={5} />
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
                {productListData
                  ?.slice(0, limit)
                  ?.map((pro: CustomCardProps, index: number) => (
                    <div key={index}>
                      <CustomCard
                        key={pro.id}
                        id={pro.id}
                        typeCard={CardCustomType.product}
                        imgUrl={pro.avatar?.id}
                        avatar={pro.avatar}
                        name={pro.name}
                        point={pro.point}
                        price={pro.price}
                        price_after_tax={pro.price_after_tax}
                        original_price={pro.original_price}
                        original_price_after_tax={pro.original_price_after_tax}
                        sum_review={pro.sum_review}
                        quantity={pro.quantity}
                        voucherOfProducts={[pro.voucher as string]}
                        productId={pro.id}
                        shop_name={pro.shop?.name}
                        sub_label={pro.sub_label}
                        shop={pro.shop}
                        is_bookmark={pro.is_bookmark}
                        handleReload={handleReloadData}
                        bloom_filter={bloomData}
                        sold={pro?.sold}
                        is_flash_deal={pro?.is_flash_deal}
                        flash_deal_status={pro?.flash_deal_status}
                        flash_deals={pro?.flash_deals}
                        flash_deals_time={pro?.flash_deals_time}
                        shop_id={pro?.shop_id}
                      />
                    </div>
                  ))}
              </div>
            )}

            {totalProducts > limit && (
              <div>
                <p
                  onClick={handleViewMore}
                  className="cursor-pointer text-14 font-medium text-blue-700"
                >
                    View more
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
