import React, { useMemo } from "react"
import { BreadCrumbType, productDetailType } from "./props"
import CustomBreadcrumb from "../product-detail-custom/breadcrumb-custom"

export default function ProductDetailCatalog(props: productDetailType) {
  const {catalogs, shopDetail} = props
  const getCatalog = useMemo(() => {
    const result: BreadCrumbType[] = []
    if (catalogs?.length) {
      const catalogLabel = catalogs[0]?.reference_global?.split(">")
      const catalogId = catalogs[0]?.reference_global_id?.split(">")
      if (catalogLabel?.length) {
        catalogLabel.forEach((i, index) => {
          result.push({
            label: i,
            url: catalogId[index] || "",
          })
        })
      }
    }
    return result
  }, [catalogs])
  return (
    <div className="pt-1 pb-3">
      {getCatalog.length ? (
        <div className="flex items-center">
          {getCatalog.map((i, index) => (
            <CustomBreadcrumb
              key={index}
              {...i}
              index={index}
              shopId={shopDetail?.shopId}
              isLast={index === getCatalog?.length - 1}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}
