import React, { useEffect, useState } from "react"
import completeOrderIcon from "../../../../assets/images/shopping-complete-order-icon.svg"
import failOrderIcon from "../../../../assets/images/warning-icon.svg"
import { useNavigate } from "react-router-dom"
import { ROUTERS } from "src/constants/router"
import ProductItemView from "./product-item-view"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import Loading from "src/components/loading"
import { OrderControllerApi, OrderPayment } from "@soctrip/axios-order-service"
import { configHeader } from "src/config/interceptors"
import { ORDER_FAIL_CODE, SERVICE } from "src/constants/common"
import { ProductType, BillingDetailType, STATE_VIEW, Payment } from "./type"
import EmptyPage from "src/pages/empty-page"
import { convertCurrency } from "src/utils/convert-currency"
import { repayOrder } from "src/services/order-service"
import { useToast } from "src/hooks/use-toast"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"
import { mathRoundNumber } from "src/utils/common"
import { detectPurchase, detectPurchaseByParam } from "src/services/tracking-service"

const PaymentViewComplete = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const showToast = useToast()
  const [searchParams] = useSearchParams()
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [billingDetail, setBillingDetail] = useState<BillingDetailType | undefined>(undefined)
  const [orderData, setOrderData] = useState<ProductType[]>([])
  const [orderIds, setOrderIds] = useState<string[]>([])
  const [failMessage, setFailMessage] = useState<string>("")
  const [paymentInfo, setPaymentInfo] = useState<Payment | OrderPayment>()
  const [transactionId, setTransactionId] = useState<string>("")
  const handleRepay = async () => {
    const requestData = {
      success_callback: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`,
      fail_callback: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`,
    }
    await repayOrder(orderIds[0] || "", requestData)
      .then((res) => {
        if (res?.data?.data) {
          const paymentUrl = `${process.env.REACT_APP_PAYMENT_DOMAIN_URL}?paymentToken=${res?.data?.data?.token}`
          window.open(paymentUrl, "_self")
        } else {
          let message = capitalizedFirst(t("order-fail-message.repay-fail"))
          if (res?.data?.error?.code && ORDER_FAIL_CODE.includes(res.data.error.code)) {
            const messageError = `order-fail-message.${res.data.error.code}`
            message = capitalizedFirst(t(messageError))
          }
          showToast({
            detail: message,
            severity: "error",
          })
        }
      })
      .catch((data) => {
        let message = capitalizedFirst(t("order-fail-message.repay-fail"))
        if (
          data?.response?.data?.error?.code &&
          ORDER_FAIL_CODE.includes(data.response.data.error.code)
        ) {
          const messageError = `order-fail-message.${data.response.data.error.code}`
          message = capitalizedFirst(t(messageError))
        }
        showToast({
          detail: message,
          severity: "error",
        })
      })
  }

  const handleClickMyOrder = () => {
    if(stateView === STATE_VIEW.COMPLETE) navigate("/shopping/"+ROUTERS.PURCHASE_HISTORY)
    else handleRepay()
  }

  const handleClickContinue = () => {
    navigate(ROUTERS.SHOPPING)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOrderData = (data: any) => {
    const result = {
      ids: [] as string[],
      billing: {
        subtotal: 0,
        total: 0,
        delivery: 0,
        discount: 0,
        deliveryDiscount: 0,
        tax: 0,
      },
    }
    if (data?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data?.forEach((i: any) => {
        result.billing.subtotal = result.billing.subtotal + i?.sub_total
        result.billing.total = result.billing.total + i?.total
        result.billing.delivery = result.billing.delivery + i?.shipping_fee
        result.billing.discount = result.billing.discount + i?.discount
        result.billing.deliveryDiscount = result.billing.deliveryDiscount + i?.shipping_discount
        result.billing.tax = result.billing.tax + i?.tax
        result.ids.push((i?.id as string) || "")
      })
    }
    return result
  }
  const detectCookie = () => {
    let result = ""
    const data = document.cookie.split(";")
    data.forEach(i => {
      if(i.includes("afincd") && i.split("=").length === 2) result = i.split("=")[1] || ""
    })
    return result
  }
  const initDataView = () => {
    const paymentToken = searchParams.get("token")
    const failMessage = searchParams.get("message")
    const isOffline = searchParams.get("isOffline")
    if (paymentToken) {
      new OrderControllerApi(configHeader(SERVICE.ORDER))
        .ordersPaymentTokenStatusGet(paymentToken)
        .then((res) => {
          if (res?.data?.data?.status) {
            setTransactionId(res.data.data?.transaction_id || "")
            const isPaymentComplete =
              res?.data?.data?.status === "succeeded" ||
              res?.data?.data?.status == "waiting_for_account"
            const stateResultView = isPaymentComplete ? STATE_VIEW.COMPLETE : STATE_VIEW.FAIL
            const { ids, billing } = getOrderData(res.data.data?.orders)
            if (failMessage) {
              const messageError = failMessage.replaceAll("_", " ")
              setFailMessage(messageError)
            }
            setBillingDetail(billing)
            setOrderIds(ids)
            const stateResult = isOffline === "true" ? STATE_VIEW.COMPLETE : stateResultView
            // tracking
            const afincd = detectCookie()
            if(afincd) {
              detectPurchase(paymentToken).catch(err => console.log(err))
              detectPurchaseByParam(paymentToken, afincd).catch(err => console.log(err))
            }
            setStateView(stateResult)
          } else {
            setStateView(STATE_VIEW.NOT_FOUND)
          }
        })
        .catch(() => setStateView(STATE_VIEW.NOT_FOUND))
    }
  }
  useEffect(() => {
    initDataView()
  }, [])

  useEffect(() => {
    if (orderIds.length) {
      new OrderControllerApi(configHeader(SERVICE.ORDER))
        .ordersPaymentInfoGet(orderIds)
        .then((res) => {
          if (res?.data?.data?.products?.length) {
            setPaymentInfo(res.data.data.payment)
            const productsResult = [] as ProductType[]
            res.data.data.products.forEach((i) => {
              productsResult.push({
                id: i?.id || "",
                sku: i?.sku || "",
                productPrice: i.current_price || 0,
                productImg: i?.avatar?.id || "",
                productName: i?.name || "",
                quantity: i?.quantity || 0,
                variation:
                  (i?.variation2 ? `${i?.variation1}/${i?.variation2}` : i?.variation1) || "",
              })
            })
            setOrderData(productsResult)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [orderIds])

  useEffect(() => {
    if(orderData.length && billingDetail && (stateView === STATE_VIEW.COMPLETE || stateView === STATE_VIEW.FAIL)) {
      // GA POST ACTION
      let couponData =  ""
      if(paymentInfo?.vouchers?.length) {
        paymentInfo.vouchers.forEach(i => {
          couponData = couponData + i.name + ", "
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const itemData = [] as any[]
      if(orderData.length) {
        orderData.forEach(i => {
          itemData.push({
            item_id: i.id,
            item_name: i.productName,
            price: mathRoundNumber(i.productPrice),
            quantity: i.quantity,
          })
        })
      }
      gaPostAction(TAG_TYPE.PURCHASE, {
        transaction_id: orderIds[0],
        affiliation: "",
        value: mathRoundNumber(billingDetail.total),
        currency: "USD",
        tax: mathRoundNumber(billingDetail.tax),
        shipping: mathRoundNumber(billingDetail.delivery),
        coupon: couponData,
        items: itemData,
      })
    }

  }, [orderData, billingDetail, stateView, transactionId])

  if (stateView === STATE_VIEW.NOT_FOUND)
    return (
      <div className="h-[600px]">
        <EmptyPage message={capitalizedFirst(t("shoppingPage.payment-not-found"))} />
      </div>
    )

  if (stateView === STATE_VIEW.INIT)
    return (
      <div className="h-[600px]">
        <Loading />
      </div>
    )
  const getStyleVoucher = (voucherType: string) => {
    let result = "bg-orange-dark-50 text-orange-700"
    if(voucherType === "FREE_SHIP") result = "bg-emerald-50 text-emerald-600"    
    return result
  }

  return (
    <div className="flex flex-col rounded-3 border border-gray-200 bg-white p-3 py-3 text-gray-700">
      <div className="flex flex-col items-center text-gray-700">
        <img
          src={stateView === STATE_VIEW.COMPLETE ? completeOrderIcon : failOrderIcon}
          className="h-[90px] w-[90px]"
          alt="paymentCompleteIcon"
        />
        <span className="leading-32 mt-3 text-24 font-bold">
          {stateView === STATE_VIEW.COMPLETE
            ? capitalizedFirst(t("checkoutPage.thank-for-your-shopping"))
            : capitalizedFirst(t("shoppingPage.payment-fail"))}
        </span>
        {stateView === STATE_VIEW.COMPLETE ? (
          <span className="mt-6px text-16 font-normal leading-24">
            {capitalizedFirst(t("checkoutPage.the-order-confirmation-has-been-sent-to-your-email"))}
          </span>
        ) : null}
        {stateView === STATE_VIEW.FAIL && failMessage ? (
          <span className="mt-6px text-16 font-normal leading-24">
            {capitalizedFirst(failMessage)}
          </span>
        ) : null}
      </div>
      <div />
      <div className="mt-3 flex flex-col">
        <span className="text-16 font-semibold leading-24 text-gray-700">
          {capitalizedFirst(t("checkoutPage.track-orders"))}
        </span>
        <div className="mt-3 flex flex-col">
          {orderData.map((item, index: number) => (
            <ProductItemView key={index} {...item} />
          ))}
        </div>

        {/* Subtotal */}
        <div className="mt-3 flex items-center justify-between border-t-[1px] border-gray-200 pt-3 text-14 font-semibold leading-20">
          <span>{capitalizedFirst(t("purchase-history.order-total"))}</span>
          <span>{convertCurrency(billingDetail?.subtotal || 0)}</span>
        </div>

        {/* Tax */}
        <div className="mt-3 flex items-center justify-between text-14 font-semibold leading-20">
          <span className="text-14 font-semibold leading-20">
            {capitalizedFirst(t("shoppingCartPage.tax"))}
          </span>
          <span className="font-normal">{convertCurrency(billingDetail?.tax || 0)}</span>
        </div>

        {/* voucher */}
        <div className="mt-3 flex items-center justify-between text-14 font-semibold leading-20">
          <div className="text-14 font-semibold leading-20">
            {capitalizedFirst(t("checkoutPage.vouchers"))}
          </div>
          <div className="flex items-center justify-end gap-1">
            {paymentInfo?.vouchers?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (voucher: any, index: number) => (
                <div
                  key={index}
                  className={`rounded-5  px-10px py-4px text-14 font-medium ${getStyleVoucher(voucher?.type)}`}
                >
                  {voucher?.name}
                </div>
              )
            )}
          </div>
        </div>

        {/* Discounts */}
        <div className="mt-3 flex items-center justify-between text-14 font-semibold leading-20">
          <span>{capitalizedFirst(t("shoppingCartPage.discounts"))}</span>
          <span className="font-normal">{`-${convertCurrency(billingDetail?.discount || 0)}`}</span>
        </div>

        {/* Delivery */}
        <div className="mt-3 flex items-center justify-between text-14 font-semibold leading-20">
          <span className="text-14 font-semibold leading-20">
            {capitalizedFirst(t("shoppingCartPage.delivery"))}
          </span>
          <span className="font-normal">{convertCurrency(billingDetail?.delivery || 0)}</span>
        </div>

        {/* Delivery discount*/}
        <div className="mt-3 flex items-center justify-between border-gray-200 text-14 font-semibold leading-20">
          <span className="text-14 font-semibold leading-20">
            {capitalizedFirst(t("shoppingCartPage.delivery-discount"))}
          </span>
          <span className="font-normal">
            {`-${convertCurrency(billingDetail?.deliveryDiscount || 0)}`}
          </span>
        </div>

        {/* Payment method */}
        <div className="mt-3 flex items-center justify-between text-14 font-semibold leading-20 border-b-[1px] pb-3">
          <span className="text-14 font-semibold leading-20">
            {capitalizedFirst(t("purchase-history.payment-method"))}
          </span>
          <span className="font-normal">{paymentInfo?.payment_method?.name}</span>
        </div>

        {/* Total */}
        <div className="flex items-center justify-between py-3 font-bold">
          <span className="text-16 leading-24">
            {capitalizedFirst(t("purchase-history.total-payment"))}
          </span>
          <span className="text-20 leading-30 text-orange-dark-500">
            {convertCurrency((billingDetail?.total || 0) > 0 ? billingDetail?.total : 0)}
          </span>
        </div>
      </div>
      <div className="ml-3 flex justify-end">
        <button
          onClick={handleClickMyOrder}
          className="rounded-3 border border-gray-200 bg-gray-200 px-20px py-2 text-16 font-semibold leading-24 text-gray-700 hover:bg-gray-100 123"
        >
          {capitalizedFirst(t(stateView === STATE_VIEW.COMPLETE  ? "purchase-history.my-order" : "purchase-history.button.repay"))}
        </button>
        <button
          onClick={handleClickContinue}
          className="ml-3 flex items-center rounded-3 bg-blue-600 px-20px py-2 text-16 font-semibold leading-24 text-white hover:bg-blue-500"
        >
          <span>{capitalizedFirst(t("shoppingPage.cart_continue_btn"))}</span>
          <i className="pi pi-arrow-right ml-1" />
        </button>
      </div>
    </div>
  )
}

export default PaymentViewComplete
