import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { signIn } from "src/features/auth"
import { RECEIVE_TOKEN_URL, SERVICE, STATE_RENDER_APP } from "src/constants/common"
import { ShareTokenTypes, isIFrame } from "./types"
import {
  validateAndFetchCurrency,
} from "src/utils/convert-currency"
import { ProgressSpinner } from "primereact/progressspinner"
import { useNavigate } from "react-router-dom"
import {SoctripPromotionControllerApi} from "@soctrip/axios-promotion-service"
import { configHeader } from "src/config/interceptors"
import { updateShipVoucherMin, updateSystemVoucher } from "src/features/system"
import { SystemVoucherType } from "src/features/system/props"
import { VoucherType } from "../modal/select-voucher-modal/types"

function ShareToken(props: ShareTokenTypes) {
  const { setStateRenderView, stateRenderView } = props
  const [isRender, setIsRender] = useState<boolean>(true)
  const [isHaveLogin, setIsHaveLogin] = useState<boolean>(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getSystemVoucherPublic = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new SoctripPromotionControllerApi(configHeader(SERVICE.PROMOTION)).findAllValidVouchers(0,100).then((res:any) => {
      if(res?.data?.data?.data?.length) {
        let valueCompare = 0
        let result = undefined as SystemVoucherType | undefined
        const shipVouchers = [] as VoucherType[]
        let shipVoucherMin = undefined as undefined | VoucherType
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.data.data.data.forEach((i:any) => {

          const currentTime = new Date().getTime()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const discountRange = (i?.voucher_discount_range ?? [])?.map((range: any) => range.discount_percent || 0)
          const currentValue = Math.max(...discountRange)
          if( currentTime >= new Date(i?.start_date).getTime() && currentTime <= new Date(i?.end_date).getTime()) {
            if(i?.soctrip_voucher_type?.code === "FREE_SHIP" && i?.voucher_discount_range?.length && i?.voucher_discount_range[0]?.discount_type === "PERCENTAGE" ) {
              shipVouchers.push(i)
            }
            if(currentValue > valueCompare) {
              valueCompare = currentValue
              result = {discount_percent: currentValue}
            }
          }
        })
        // get free ship
        shipVouchers.forEach(i => {
          if(!shipVoucherMin) {
            shipVoucherMin = i
          }
          if( shipVoucherMin?.voucher_discount_range?.length && i.voucher_discount_range?.length &&  (shipVoucherMin?.voucher_discount_range[0]?.min_order_price as number || 0 >  i.voucher_discount_range[0].min_order_price || 0)) {
            shipVoucherMin = i
          }
        })
        dispatch(updateSystemVoucher(result))
        if(shipVoucherMin) {
          dispatch(updateShipVoucherMin(shipVoucherMin))
        } 
      }
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    getSystemVoucherPublic()
    window.addEventListener("message", handleReceiveMessage)
    return () => window.removeEventListener("message", handleReceiveMessage)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e: any) => {
    if (e?.data?.type === "authorized") { //authorized
      console.log("authorized")
      localStorage.removeItem("iframeError")
      const accessTokenData = e.data?.token?.accessToken
      const profileData = JSON.parse(e.data?.profile)
      let data = undefined
      if (accessTokenData && profileData?.id) {
        data = {
          user: profileData,
          access_token: accessTokenData,
        }

        localStorage.setItem("accessToken", accessTokenData)
        localStorage.setItem("userId", profileData?.id)
      }
      dispatch(signIn(data))
      setIsHaveLogin(true)
    } else if(e?.data?.type === "unauthorized") {
      localStorage.removeItem("iframeError")
      // have token
      if(!localStorage.getItem("isLoading")?.length) { // pass
        console.log("unauthorized login")
        localStorage.clear()
        navigate(0)
      } else { // pass
        console.log("unauthorized not login")
        getCurrencyData()
      }
    } else if(e?.data?.type === "success") { // case logout
      console.log("logout")
      navigate(0)
    }
  }
  const handleOnLoad = () => {
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      console.log("Get token")
      frame.contentWindow.postMessage({ type: "connect" }, "*")
      localStorage.setItem("iframeError", "true")
      detectIframeError()
    }
  }
  const getCurrencyData = async () => {
    await validateAndFetchCurrency(true)
    setStateRenderView(STATE_RENDER_APP.APP)
  }

  const getUserInfoData = async () => {
    localStorage.setItem("isFetchingCurrency", "false")
    const userId = localStorage.getItem("userId")
    // not have token
    if (!userId) {
      dispatch(signIn(undefined))
      setStateRenderView(STATE_RENDER_APP.APP)
      return
    }
    
    setStateRenderView(STATE_RENDER_APP.APP)
  }
  useEffect(() => {
    if (isHaveLogin) getUserInfoData()
  }, [isHaveLogin])

  const detectIframeError = () => {
    setTimeout(() => {
      if(localStorage.getItem("iframeError")?.length) getUserInfoData()
    }, 5000)
  }

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      localStorage.setItem("isLoading", "true")
    }
    localStorage.removeItem("iframeError")
    setIsRender(true)
  }, [])

  // clear loading when render app
  useEffect(() => {
    if(stateRenderView === STATE_RENDER_APP.APP) localStorage.removeItem("isLoading")
  }, [stateRenderView] )

  useEffect(() => {
    if(isRender) {
      detectIframeError()
    }
  },[isRender])

  if (!isRender) return null
  return (
    <div className={`${stateRenderView === STATE_RENDER_APP.APP ? "fixed z-[10] top-[0px] left-[0px]" : ""}`}>
      {localStorage.getItem("isLoading")?.length && stateRenderView !== STATE_RENDER_APP.APP? (
        <div className="h-screen w-screen">
          <div className="flex h-full w-full items-center justify-center gap-2 rounded-3 bg-white">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        </div>
      ) : null}
      <iframe
        id="socialLogin"
        style={{
          width: 0,
          height: 0,
          position: "absolute",
          left: 0,
          top: 0,
          display: "none",
          visibility: "hidden",
        }}
        width={0}
        height={0}
        title="socialLogin"
        src={RECEIVE_TOKEN_URL}
        onLoad={handleOnLoad}
      />
    </div>
  )
}

export default ShareToken
