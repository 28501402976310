import React, { useState } from "react"
import { ORDER_STEP_VIEW, ORDER_TYPE_MODE } from "../../../constants/common"
import AddressView from "./address-view"
import BillingDetail from "./billing-detail"
import { OrderBillingTypes } from "./types"
import { AddressType } from "../type"
import messageCartIcon from "src/assets/images/Icon_Cart_Message.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"

const OrderBilling = (props: OrderBillingTypes) => {
  const {
    stepView,
    updateStepView,
    paymentSelected,
    updateOrderData,
    setOrderBilling,
    typeView,
    showCardForm,
    isPaymentCod,
    paymentName,
    closeCardForm,
    billingAddressData,
    updateAddressShip,
    isNotInvoice,
    invoiceData,
    tab,
    paymentMethodType,
    isCallClick,
    updateIsCallClick,
  } = props
  const [addressSelected, setAddressSelected] = useState<AddressType | undefined>(undefined)

  return (
    <div className="sticky top-[90px] flex h-fit w-full flex-col gap-3 lg:w-[384px]">
      <div className="flex h-fit w-full flex-col gap-1">
        {stepView !== ORDER_STEP_VIEW.CART && tab === ORDER_TYPE_MODE.ONLINE ? (
          <AddressView
            stepView={stepView}
            updateAddressSelected={(newAddress: AddressType) => {
              setAddressSelected(newAddress)
              updateAddressShip(newAddress)
            }}
          />
        ) : null}
        <BillingDetail
          updateOrderData={updateOrderData}
          setOrderBilling={setOrderBilling}
          address={addressSelected}
          stepView={stepView}
          payment={paymentSelected}
          updateStepView={(newStep: string) => updateStepView(newStep)}
          typeView={typeView}
          showCardForm={showCardForm}
          isPaymentCod={isPaymentCod}
          paymentName={paymentName}
          closeCardForm={closeCardForm}
          billingAddressData={billingAddressData}
          isNotInvoice={isNotInvoice}
          invoiceData={invoiceData}
          tab={tab}
          paymentMethodType={paymentMethodType}
          isCallClick={isCallClick}
          updateIsCallClick={updateIsCallClick}
        />
        {/* Message info warning */}
      </div>
      <div className="flex w-full gap-2 rounded-3 border border-blue-300 p-3">
        <div className="flex w-[20px] items-start justify-start">
          <img src={messageCartIcon} alt="icon message" className="h-[20px] w-[20px]" />
        </div>
        <div className="w-full text-14 font-normal leading-5 text-blue-700">
          {capitalizedFirst(t("shoppingCartPage.this-is-an-estimated-total-only"))}
        </div>
      </div>
    </div>
  )
}

export default OrderBilling
