import React, { useEffect, useState } from "react"

import { useNavigate, useParams } from "react-router-dom"
import { HeaderView, RefundDetailView, RequestRefundView } from "src/components/refund"
import { getImageUrl } from "src/services/file-service"
import { OrderControllerApi } from "@soctrip/axios-order-service"
import Loading from "src/components/loading"
import { SERVICE, TYPELINK } from "src/constants/common"
import { configHeader } from "src/config/interceptors"
import { convertViToEn, handlePromiseAll } from "src/utils/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { convertCurrency } from "src/utils/convert-currency"
import i18n from "src/locales"
export default function RefundDetailPage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [refundDetail, setRefundDetail] = useState<any>()
  const [isRender, setIsRender] = useState<boolean>(false)
  const param = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const getRefundDetail = () => {
    return new OrderControllerApi(configHeader(SERVICE.ORDER))
      .ordersIdRefundGet(param?.id || "", i18n.language)
      .then((res) => {
        if (res?.data?.data) {
          console.log(res.data.data)
          setRefundDetail(res.data.data)
        }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis: any[] = []
    apis.push(getRefundDetail())
    handlePromiseAll(apis).then(() => {
      setIsRender(true)
    })
  }, [i18n.language])
  const goToShop = () => {
    const shopCode = refundDetail?.shop?.shop_id.split("-")?.length
      ? refundDetail?.shop?.shop_id.split("-")[0]
      : ""
    navigate(
      `/shopping/merchant/${convertViToEn(refundDetail?.shop.name as string)}/${
        refundDetail?.shop?.shop_id
      }/${shopCode}`
    )
  }
  if (!refundDetail || !isRender)
    return (
      <div className="h-[500px] w-full p-4">
        <Loading />
      </div>
    )
  return (
    <div id="requestRefundPage" className="relative pb-4">
      <HeaderView title={"back-refund-detail"} />
      <div className="flex flex-col-reverse gap-4 md:flex-row ">
        <div className="flex w-full flex-col gap-3 md:w-[66%]">
          <div className="rounded-3 border border-gray-200 bg-white px-4 py-20px shadow-sm">
            <div className="flex flex-row items-center justify-start gap-2 pb-20px ">
              <img
                src={getImageUrl(refundDetail?.shop?.avatar?.id, refundDetail?.shop?.avatar?.type)}
                alt="avatar"
                className="flex !h-[30px] !w-[30px] items-center justify-start rounded-full object-cover"
              ></img>

              <span className="truncate text-16 font-semibold leading-6 text-gray-900">
                {refundDetail?.shop?.name}
              </span>

              <span
                onClick={goToShop}
                className="cursor-pointer text-14 font-medium leading-6 text-blue-500 hover:underline"
              >
                {capitalizedFirst(t("refund.go-to-shop"))}
              </span>
            </div>
            <div className=" h-[1px] bg-gray-100"></div>
            {refundDetail &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              refundDetail.shop?.products?.map((el: any, i: number) => (
                <div key={i}>
                  <div className="flex flex-row gap-3 pt-20px">
                    {el?.avatar?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE) ? (
                      <img
                        src={`https://img.youtube.com/vi/${
                          el?.avatar?.id?.split("/").slice(-1)[0]
                        }/maxresdefault.jpg`}
                        alt="img-video"
                        className="h-[64px] w-[64px] rounded-2 border border-gray-300 object-cover"
                      />
                    ) : (
                      <img
                        src={getImageUrl(el?.avatar?.id, el?.avatar?.type)}
                        alt="img"
                        className="h-[64px] w-[64px] rounded-2 border border-gray-300 object-cover"
                      />
                    )}
                    <div className="flex flex-1 flex-col gap-4px">
                      <div className="flex flex-row items-center justify-between">
                        <span className="text-16 font-medium text-gray-700">{el.name}</span>
                        <span className="text-16 font-semibold leading-6 text-gray-900">
                          {convertCurrency(el.current_price || 0)}
                        </span>
                      </div>
                      <div className="flex flex-row items-center justify-start gap-1">
                        {el?.variation1 ? (
                          <div className="flex flex-row items-center gap-1">
                            <div className="flex flex-row gap-4px">
                              <span className="text-14  font-normal leading-5 text-gray-500">
                                {capitalizedFirst(t("refund.variation"))}
                              </span>
                              <span className="text-14 font-medium leading-5 text-gray-900">
                                {el?.variation1}
                              </span>
                            </div>
                            <i className="pi pi-circle-fill text-[5px] text-gray-300"></i>
                          </div>
                        ) : null}

                        <div className="flex flex-row gap-4px">
                          <span className="text-14  font-normal leading-5 text-gray-500">
                            {capitalizedFirst(t("refund.quantity"))}
                          </span>
                          <span className="text-14 font-medium leading-5 text-gray-900">
                            {el.quantity}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {i === refundDetail?.shop?.products?.length - 1 ? null : (
                    <div className="mt-3 h-[1px] bg-gray-100"></div>
                  )}
                </div>
              ))}
          </div>
          <RefundDetailView refundDetail={refundDetail} />
        </div>
        <div className="top-[72px] h-fit w-full md:sticky md:w-[32%]">
          <RequestRefundView
            refundAmount={refundDetail?.refund_amount}
            description={refundDetail?.description}
            refundDetail={{
              requestId: refundDetail?.code,
              closeAt: "",
              reason: refundDetail?.reason,
              description: refundDetail?.description,
              img: refundDetail?.images || [],
            }}
          />
          <button
            type="button"
            className="mt-1 hidden w-[100%] truncate rounded-3 border border-gray-300 bg-white px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
          >
            {capitalizedFirst(t("refund.button.cancel_request"))}
          </button>
        </div>
      </div>
    </div>
  )
}
