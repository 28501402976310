import React, { useEffect, useState } from "react"
import EmptyPage from "../empty-page"
import Loading from "src/components/loading"
import { SERVICE } from "src/constants/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { LivestreamCampaignControllerApi } from "@soctrip/axios-livestream-service"
import {
  LiveStreamViewType,
  STATE_VIEW,
  PAGE_SIZE_LIVE_STREAM,
} from "src/components/live-streams/types"
import { LiveStreamView } from "src/components/live-streams"
import { configHeader } from "src/config/interceptors"

export default function LiveStreamPage() {
  const [liveStreams, setLiveStreams] = useState<LiveStreamViewType[]>([])
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false)
  const [totalData, setTotalData] = useState<number>(0)

  const { t } = useTranslation()

  const handleLoadMore = () => {
    const reqData = {
      type: "SHOP",
      pageNum: pageIndex,
      pageSize: PAGE_SIZE_LIVE_STREAM,
    }
    setIsLoadMore(true)
    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLivesSearchGet(
        reqData.type,
        reqData.pageNum,
        reqData.pageSize
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          const result: LiveStreamViewType[] = []
          if (res.data.data?.data?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data?.forEach((i: any) => {
              result.push({
                hostImg: i?.shop?.avatar_id || "",
                hostName: i?.shop?.name || "",
                liveName: i?.live_detail?.title || "",
                bgImg: i?.live_detail?.thumbnail_image || "",
                id: i?.id || "",
                views: i?.live_detail?.view_count || 0,
                actionCount: i?.live_detail?.like_count || 0,
                isLive: i?.live_detail?.status === "ONGOING" || false,
                liveId: i?.live_detail?.id || "",
              })
            })
          }
          if (result.length) setLiveStreams([...liveStreams, ...result])
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadMore(false))
  }

  const getDataLiveStreams = () => {
    const reqData = {
      type: "SHOP",
      pageNum: pageIndex,
      pageSize: PAGE_SIZE_LIVE_STREAM,
    }

    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLivesSearchGet(
        reqData.type,
        reqData.pageNum,
        reqData.pageSize
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          const result: LiveStreamViewType[] = []
          setTotalData(res.data.data?.totalElement || 0)
          if (res.data.data?.data.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data?.forEach((i: any) => {
              result.push({
                hostImg: i?.shop?.avatar_id || "",
                hostName: i?.shop?.name || "",
                liveName: i?.live_detail?.title || "",
                bgImg: i?.live_detail?.thumbnail_image || "",
                id: i?.id || "",
                views: i?.live_detail?.view_count || 0,
                actionCount: i?.live_detail?.like_count || 0,
                isLive: i?.live_detail?.status === "ONGOING" || false,
                liveId: i?.live_detail?.id || "",
              })
            })
          }
          if (result.length) setLiveStreams(result)
          setStateView(STATE_VIEW.RENDER)
        } else {
          setStateView(STATE_VIEW.NOT_FOUND)
        }
      })
      .catch(() => setStateView(STATE_VIEW.NOT_FOUND))
  }

  useEffect(() => {
    getDataLiveStreams()
  }, [])
  
  useEffect(() => {
    if (pageIndex) handleLoadMore()
  }, [pageIndex])

  if (stateView === STATE_VIEW.INIT || stateView === STATE_VIEW.LOADING)
    return (
      <div className="h-[600px]">
        <Loading />
      </div>
    )

  if (stateView === STATE_VIEW.NOT_FOUND || !liveStreams.length)
    return (
      <div className="h-[600px]">
        <EmptyPage message="Data not found"/>
      </div>
    )

  return (
    <div className="my-[40px] flex flex-col">
      <span className="text-[20px] font-semibold leading-[30px] text-gray-900">
        {capitalizedFirst(t("live-stream.live-stream"))}
      </span>
      <div className="mt-3 gap-3 rounded-3 bg-white p-3">
        <div className="grid grid-cols-4 gap-x-3 gap-y-4">
          {liveStreams.map((i) => (
            <LiveStreamView key={i.id} {...i} />
          ))}
        </div>
      </div>

      {isLoadMore ? (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      ) : null}

      {!isLoadMore && (pageIndex + 1) * PAGE_SIZE_LIVE_STREAM < totalData ? (
        <div
          onClick={() => setPageIndex(pageIndex + 1)}
          className="mt-1 flex w-full cursor-pointer items-center justify-center text-16 font-semibold text-blue-700 hover:underline"
        >
          <span>{capitalizedFirst(t("shoppingPage.view-more"))}</span>
        </div>
      ) : null}
    </div>
  )
}
