import { FlashDealSettingControllerApi } from "@soctrip/axios-advertising-service"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import flashDealIconInBackground from "src/assets/images/flash-deals-icon-in-background.png"
import { configHeader } from "src/config/interceptors"
import { LOGO, SERVICE } from "src/constants/common"
import { useClickOutside } from "src/hooks/use-click-outside"
import { getClientBanners } from "src/services/banners-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { handleCheckTime } from "src/utils/common"
import { getKeywords } from "src/utils/get-keyword-suggestion"
import { useDebounce } from "use-debounce"
import ImgCustom from "../img-custom"
import FlashSaleCountDown from "../shopping-homepage-view/shopping-flash-sale/flash-sale-countdown"
import { BannerType } from "../shopping-homepage-view/shopping-homepage-header/type"

export interface TimeListType {
  startTime: number
  endTime: number
}
export default function HeaderFlashDeals() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const clickRef = useRef<HTMLDivElement>(null)
  const [isSuggestionModal, setIsSuggestionModal] = useState(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
  const [searchDebounce] = useDebounce(searchValue, 300)
  const [banner, setBanner] = useState<string>("")
  const [timeLists, setTimeLists] = useState<TimeListType[]>([])
  const [isDisplay, setIsDisplay] = useState<boolean>(false)

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
  }

  const handleToSearchPage = (keyword?: string) => {
    setSearchValue(keyword || "")
    const uri = searchValue
      ? `/shopping/flash-sale?keyword=${keyword || searchValue}`
      : "/shopping/flash-sale"
    navigate(uri, { state: { searchValue } })
    setIsSuggestionModal(false)
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      const index =
        selectedSuggestionIndex == suggestions.length - 1 ? 0 : selectedSuggestionIndex + 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "ArrowUp") {
      const index =
        selectedSuggestionIndex == 0 ? suggestions.length - 1 : selectedSuggestionIndex - 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "Enter") {
      if (selectedSuggestionIndex !== -1) {
        handleToSearchPage(suggestions[selectedSuggestionIndex])
        setIsSuggestionModal(false)
      } else {
        handleToSearchPage()
      }
    }
  }

  const onClickOutside = () => {
    setIsSuggestionModal(false)
  }

  const highlightMatchedText = (text: string, keyword: string) => {
    const startIndex = text.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase())
    if (startIndex === -1) {
      return text
    }

    const endIndex = startIndex + keyword.length
    const prefix = text.slice(0, startIndex)
    const match = text.slice(startIndex, endIndex)
    const suffix = text.slice(endIndex)

    return (
      <>
        {prefix}
        <strong className="font-semibold text-black">{match}</strong>
        {suffix}
      </>
    )
  }

  useClickOutside(clickRef, onClickOutside)

  const fetchSuggestions = async (keyword: string) => {
    setIsSearching(true)
    try {
      const res = await new StockProductControllerApi(
        configHeader(SERVICE.STOCK)
      ).productsPreflightSearchGet(0, 12, keyword)
      const data = res?.data?.data?.data

      if (data) {
        const keywords = getKeywords(data, keyword)
        setSuggestions(keywords)
      }
      setIsSearching(false)
    } catch (error) {
      setIsSearching(false)
    }
  }

  useEffect(() => {
    const keyword = searchDebounce.trim()
    if (keyword) {
      setSelectedSuggestionIndex(-1)
      fetchSuggestions(keyword)
    }
  }, [searchDebounce])

  const handleGetTime = (value: number) => {
    const h = new Date(value).getHours() as number
    const m = new Date(value).getMinutes() as number
    const hoursString = h > 0 ? `${h}` : `0${h}`
    const minutesString = m > 0 ? `${m}` : `0${m}`
    return `${hoursString} : ${minutesString}`
  }

  const handleGetStatus = (startTime: number, endTime: number) => {
    const timeStartLeft = startTime - Date.now()
    const timeEndLeft = endTime - Date.now()
    if (timeStartLeft > 0) {
      return {
        status: `${capitalizedFirst(t("flash-deals.up-coming"))}`,
        code: "UP",
      }
    } else if (timeStartLeft < 0 && timeEndLeft - timeStartLeft > 0 && timeEndLeft > 0) {
      return {
        status: `${capitalizedFirst(t("flash-deals.ends-in"))}`,
        code: "GO",
      }
    } else {
      return {
        status: `${capitalizedFirst(t("flash-deals.end"))}`,
        code: "END",
      }
    }
  }

  const handleGetBanner = async () => {
    await getClientBanners("SHOP_PROMOTION_SLIDE_BANNER")
      .then((response) => {
        const data: BannerType[] = response?.data?.data
        data?.map((item) => {
          if (handleCheckTime(item?.start_time, item?.end_time)) {
            if (item?.medias?.length && item?.is_default && item?.published) {
              setBanner(item?.medias?.[0]?.id)
            }
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (timeLists.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newList: TimeListType[] = []
      timeLists.forEach((time) => {
        if (time?.endTime - Date.now() >= 0) newList.push(time)
      })
      const timeout = setTimeout(() => {
        setTimeLists(newList)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [timeLists])

  useEffect(() => {
    handleGetBanner()
    new FlashDealSettingControllerApi(configHeader(SERVICE.ADVERTISING))
      .getFlashDealsSettingPage()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: TimeListType[] = []
          if (res.data?.data?.data[0]?.time_slot_units?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data[0].time_slot_units?.forEach((i: any) => {
              const dateData = new Date(i?.start_at)
              const currentDate = new Date()
              currentDate.setHours(0)
              currentDate.setMinutes(0)
              currentDate.setSeconds(0)
              currentDate.setMilliseconds(0)
              const timeStampStart =
                dateData.getHours() * 1000 * 60 * 60 +
                dateData.getMinutes() * 1000 * 60 +
                dateData.getSeconds() * 1000

              const timeStampEnd = timeStampStart + i?.duration * 60 * 1000

              let resultStartTime = currentDate.getTime() + timeStampStart
              let resultEndTime = currentDate.getTime() + timeStampEnd

              if (timeStampEnd > 24 * 60 * 60 * 1000) {
                const startDate = new Date(resultStartTime)
                startDate.setDate(startDate.getDate() - 1)
                resultStartTime = startDate.getTime()

                const endDate = new Date(resultEndTime)
                endDate.setDate(endDate.getDate() - 1)
                resultEndTime = endDate.getTime()
              }
              result.push({
                startTime: resultStartTime,
                endTime: resultEndTime,
              })
            })
          }
          setTimeLists(result)
        }
      })
    if (location?.search) {
      setSearchValue(decodeURI(location?.search?.split("=")[1]))
    }
  }, [])

  const getTimeDataSort = useMemo(() => {
    const result: TimeListType[] = []
    timeLists?.forEach((i: TimeListType) => result.push(i))
    result.sort((a: TimeListType, b: TimeListType) => a.startTime - b.startTime)
    return result
  }, [timeLists])
  const updateDisplay = () => {
    if (!isDisplay) setIsDisplay(true)
  }
  return (
    <div className="relative z-10 h-full w-full">
      <div className="h-[320px] w-full">
        <ImgCustom
          url={banner}
          alt="banner"
          isBanner={true}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="absolute top-[55%] left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col gap-5">
        <div className="item-center flex w-full justify-center gap-2">
          <div className="font-[AlfaSlabOne-Regular-400] text-[30px] text-white">{LOGO.NAME}</div>
          <img src={flashDealIconInBackground} alt="" />
          <div className="font-[AlfaSlabOne-Regular-400] text-[30px] text-white">Flash deals</div>
        </div>
        {/* Search Products */}
        <div className="flex w-full flex-col items-center justify-center gap-6">
          <div ref={clickRef} className="relative w-[70%] max-w-[680px]">
            <span className="p-input-icon-left w-full">
              {isSearching ? (
                <i className="pi pi-spin pi-spinner"></i>
              ) : (
                <i className="pi pi-search" />
              )}
              <InputText
                className="flex h-[44px] w-full items-center rounded-3"
                placeholder={capitalizedFirst(t("global.search-for-products"))}
                value={searchValue}
                onChange={(event) => {
                  handleSearch(event.target.value)
                  setIsSuggestionModal(true)
                }}
                onKeyDown={handleKeydown}
                onClick={() => setIsSuggestionModal(true)}
              />
            </span>
            <button
              type="button"
              onClick={() => handleToSearchPage(searchValue)}
              className="absolute right-4px top-1/2 -translate-y-1/2 transform rounded-3 bg-blue-500 py-6px px-2 text-14 font-medium text-white"
            >
              {capitalizedFirst(t("global.search"))}
            </button>
            {searchValue.trim() && isSuggestionModal && (
              <div className="absolute top-[52px] left-0 right-0 rounded-3 bg-white shadow">
                {suggestions.length > 0 && isSuggestionModal && (
                  <ul className="py-3">
                    {suggestions.map((suggestion, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleToSearchPage(suggestion)}
                          className={`max-w-full cursor-pointer truncate px-3 py-[4px] text-14 text-gray-700 hover:bg-gray-100 ${
                            selectedSuggestionIndex === index ? "bg-gray-100" : ""
                          }`}
                        >
                          {highlightMatchedText(suggestion, searchValue)}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
          {/* Tabs time flash deals */}
          <div className="flex h-[76px] w-full max-w-[1200px] items-center justify-between px-5">
            {getTimeDataSort?.slice(0, 4)?.map((time, index) => (
              <div
                key={index}
                className={`flex cursor-pointer flex-col items-center justify-center gap-10px ${
                  !isDisplay ? "opacity-0" : ""
                }`}
              >
                <div className="text-14 font-medium leading-5 text-white">
                  {handleGetStatus(time?.startTime, time?.endTime)?.status}
                </div>
                {handleGetStatus(time?.startTime, time?.endTime)?.code === "GO" ? (
                  <div className="text flex items-center gap-1">
                    <FlashSaleCountDown
                      spaceClass="text-white"
                      tartGetDate={time?.endTime}
                      customClass="bg-white text-blue-600 h-[36px] w-[36px] text-20 font-bold leading-30"
                      triggerRender={updateDisplay}
                    />
                  </div>
                ) : (
                  <div className="flex items-center gap-1 text-[30px] font-bold leading-[38px] text-white">
                    {handleGetTime(time?.startTime)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
