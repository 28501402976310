import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ImgCustom from "src/components/img-custom"
import { RequestRefundViewType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useToast } from "src/hooks/use-toast"
import { convertCurrency } from "src/utils/convert-currency"

export default function RequestRefundView(props: RequestRefundViewType) {
  const { isRequest, isSubmit, refundAmount, refundDetail, description,  handleRefund } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onBack = () => {
    navigate("/shopping/orders")
  }

  const onSubmitRequest = () => {
    if (handleRefund) handleRefund()
  }
  const showToast = useToast()
  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then(() => {
      showToast({ detail: "Copied", severity: "success" })
    })
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col rounded-3 border border-gray-200 bg-white shadow-sm">
        <span className="py-20px px-4 text-18 font-semibold leading-7 text-gray-900">
          {capitalizedFirst(t("refund.request-refund"))}
        </span>
        <div className="flex flex-col gap-3 px-4 pb-20px">
          <div className="flex flex-col gap-4px">
            <div className="flex flex-row items-center gap-4px">
              <span className="text-14 font-medium leading-5 text-gray-700">
                {capitalizedFirst(t("refund.refund-amount"))}
              </span>
              {isRequest ? <span className="text-red-500">*</span> : null}
            </div>
            <span className="text-[30px] font-semibold leading-[38px] text-orange-dark-600">
              {convertCurrency(refundAmount)}
            </span>
          </div>
          {!isRequest && refundDetail ? (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-4px">
                <span className="text-14 font-medium leading-5 text-gray-500">
                  {capitalizedFirst(t("refund.reason"))}
                </span>
                <span className="text-14 font-medium leading-5 text-gray-700 line-clamp-1">
                  {refundDetail.reason}
                </span>
              </div>
              {/* Description */}
              {refundDetail?.description ? (
                <div className="flex flex-col gap-4px">
                  <span className="text-14 font-medium leading-5 text-gray-500">
                    {capitalizedFirst(t("refund.description"))}
                  </span>
                  <span className="text-14 font-medium leading-5 text-gray-700 line-clamp-4">
                    {refundDetail.description}
                  </span>
                </div>
              ) : null}
              {refundDetail?.img?.length ? (
                <div className="grid grid-cols-5 gap-1">
                  {refundDetail?.img.map((i: string) => (
                    <div key={i}>
                      <ImgCustom
                        url={i}
                        alt="refundImage"
                        className="!h-[64px] !w-[64px] rounded-3 object-cover"
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              <div className="flex w-full flex-row gap-3">
                <div className="flex flex-1  flex-col gap-4px">
                  <span className="text-14 font-medium leading-5 text-gray-500">
                    {capitalizedFirst(t("refund.request-id"))}
                  </span>
                  <div className="flex flex-row gap-4px">
                    <span className="text-14 font-medium leading-5 text-gray-700 line-clamp-1">
                      {refundDetail.requestId}
                    </span>
                    <i
                      onClick={() => handleCopy(refundDetail.requestId)}
                      className="sctr-icon-copy-03 cursor-pointer text-blue-600"
                    ></i>
                  </div>
                </div>
                {/* 
                <div className="flex shrink-0 flex-col gap-4px">
                  <span className="text-14 font-medium leading-5 text-gray-500 text-nowrap">
                    {capitalizedFirst(t("refund.close-at"))}
                  </span>
                  <span className="text-14 font-medium leading-5 text-gray-700 line-clamp-2">
                    {refundDetail.closeAt}
                  </span>
                </div> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {isRequest ? (
        <div className="flex flex-col gap-2">
          <button
            disabled={!isSubmit}
            className={`h-[44px] w-full  rounded-3 border ${
              isSubmit && description.length<=2000
                ? "cursor-pointer border-blue-500 bg-blue-600 hover:bg-blue-700"
                : "border-blue-200 bg-blue-200"
            }`}
            onClick={()=>{
              if(isSubmit && description.length<=2000){
                onSubmitRequest()
              }
            }}
          >
            <span className="text-14 font-semibold leading-5 text-white">
              {capitalizedFirst(t("global.submit"))}
            </span>
          </button>
          <button
            className="border-gray-00 h-[44px] w-full cursor-pointer rounded-3 border border-gray-300 bg-white hover:bg-gray-100"
            onClick={onBack}
          >
            <span className="text-14 font-semibold leading-5 text-gray-900">
              {capitalizedFirst(t("global.cancel"))}
            </span>
          </button>
        </div>
      ) : null}
    </div>
  )
}
