import React, { useEffect, useMemo, useState } from "react"

import { RequestRefundView, RequestInfoView, HeaderView } from "src/components/refund"

import { useNavigate, useParams } from "react-router-dom"
import { getOrderDetails } from "src/services/purchase-history-service"
import { getImageUrl, uploadFile } from "src/services/file-service"
import Loading from "src/components/loading"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { convertViToEn, handlePromiseAll } from "src/utils/common"
import { StatusControllerApi, OrderControllerApi } from "@soctrip/axios-order-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE, TYPELINK } from "src/constants/common"
import { FileType, ReasonType } from "src/components/refund/types"
import { useToast } from "src/hooks/use-toast"
import { convertCurrency } from "src/utils/convert-currency"
import i18n from "src/locales"
import ImgCustom from "src/components/img-custom"

export default function RefundRequestPage() {
  const [selectedReason, setSelectedReason] = useState<ReasonType | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orderData, setOrderData] = useState<any>()
  const [reasons, setReasons] = useState<ReasonType[]>([])
  const [isRender, setIsRender] = useState<boolean>(false)
  const [isClickSubmit, setIsClickSubmit] = useState<boolean>(false)
  const [description, setDescription] = useState<string>("")
  const [files, setFiles] = useState<FileType[]>([])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const param = useParams()
  const toast = useToast()
  const getOrderDetail = () => {
    const id = param?.id
    if (id) {
      return getOrderDetails(id)
        .then((response) => {
          const data = response?.data?.data
          if (data) {
            setOrderData(data)
          }
        })
        .catch((error) => console.log(error))
    }
  }
  const getReasons = () => {
    new StatusControllerApi(configHeader(SERVICE.ORDER))
      .statusReasonsGet("REFUND", i18n.language)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        {
          if (res?.data?.data?.length) {
            setReasons(res.data.data)
          }
        }
      })
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis: any[] = []
    apis.push(getOrderDetail())
    apis.push(getReasons())
    handlePromiseAll(apis).then(() => {
      setIsRender(true)
    })
  }, [])
  const updateReason = (newReason: ReasonType) => {
    setSelectedReason(newReason)
  }

  const getRefundAmount = useMemo(() => {
    let result = 0
    if (orderData?.payment?.payment_total) {
      result = orderData.payment.payment_total
    }
    return result
  }, [orderData])

  const getImageData = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis: any[] = []
    const getFileData = function (file: File) {
      return uploadFile(file)
        .then((res) => {
          if (res?.data?.data) {
            result.push(res.data.data)
          }
        })
        .catch((err) => console.log(err))
    }
    files.forEach((i) => {
      apis.push(getFileData(i.file))
    })
    await handlePromiseAll(apis)
    return result
  }
  const handleRefund = async () => {
    setIsClickSubmit(true)
    const imageData = await getImageData()
    const requestData = {
      order_id: param?.id,
      reason: selectedReason?.name,
      description: description,
      images: imageData,
    }
    new OrderControllerApi(configHeader(SERVICE.ORDER))
      .ordersRefundPost(requestData)
      .then((res) => {
        if (res.data.data) {
          toast({
            detail: capitalizedFirst(t("refund.request-refund-success")),
            severity: "success",
          })
          navigate("/shopping/orders")
        } else {
          toast({
            detail: capitalizedFirst(t("refund.request-refund-fail")),
            severity: "error",
          })
        }
      })
      .catch(() =>
        toast({
          detail: capitalizedFirst(t("refund.request-refund-fail")),
          severity: "error",
        })
      )
      .finally(() => setIsClickSubmit(false))
  }
  const goToShop = () => {
    const shopCode = orderData?.shops[0]?.shop_id.split("-")?.length
      ? orderData?.shops[0]?.shop_id.split("-")[0]
      : ""
    navigate(
      `/shopping/merchant/${convertViToEn(orderData?.shops[0]?.name as string)}/${
        orderData?.shops[0]?.shop_id
      }/${shopCode}`
    )
  }
  if (!orderData || !isRender)
    return (
      <div className="h-[500px] w-full p-4">
        <Loading />
      </div>
    )
  return (
    <div id="requestRefundPage" className="relative pb-4">
      <HeaderView title={"back-refund-detail"} />
      <div className="flex flex-col-reverse gap-4 md:flex-row ">
        {orderData?.shops?.length ? (
          <div className="flex w-full flex-col gap-3 md:w-[66%]">
            <div className="rounded-3 border border-gray-200 bg-white px-4 py-20px shadow-sm">
              <div className="flex flex-row items-center justify-start gap-2 pb-20px ">
                <div className="h-[30px] w-[30px]">
                  <ImgCustom
                    url={orderData?.shops[0]?.avatar?.id}
                    alt="product image"
                    className="!h-[30px] !w-[30px] items-center justify-start rounded-full object-cover"
                  />
                </div>
                <span className="truncate text-16 font-semibold leading-6 text-gray-900">
                  {orderData?.shops[0]?.name || ""}
                </span>

                <span
                  onClick={goToShop}
                  className="cursor-pointer text-14 font-medium leading-6 text-blue-500 hover:underline"
                >
                  {capitalizedFirst(t("refund.go-to-shop"))}
                </span>
              </div>
              <div className=" h-[1px] bg-gray-100"></div>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                orderData.shops[0]?.products?.map((el: any, i: number) => (
                  <div key={i}>
                    <div className="flex flex-row gap-3 pt-20px">
                      {el?.avatar?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE) ? (
                        <img
                          src={`https://img.youtube.com/vi/${
                            el?.avatar?.id?.split("/").slice(-1)[0]
                          }/maxresdefault.jpg`}
                          alt="img-video"
                          className="h-[64px] w-[64px] rounded-2 border border-gray-300 object-cover"
                        />
                      ) : (
                        <img
                          src={getImageUrl(el?.avatar?.id, el?.avatar?.type)}
                          alt="img"
                          className="h-[64px] w-[64px] rounded-2 border border-gray-300 object-cover"
                        />
                      )}
                      <div className="flex flex-1 flex-col gap-4px">
                        <div className="flex flex-row items-center justify-between">
                          <span className="text-16 font-medium text-gray-700">{el.name}</span>
                          <span className="text-16 font-semibold leading-6 text-gray-900">
                            {convertCurrency(el.current_price)}
                          </span>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-1">
                          {el?.variation1 ? (
                            <div className="flex flex-row items-center gap-1">
                              <div className="flex flex-row gap-4px">
                                <span className="text-14  font-normal leading-5 text-gray-500">
                                  {capitalizedFirst(t("refund.variation"))}
                                </span>
                                <span className="text-14 font-medium leading-5 text-gray-900">
                                  {el?.variation1}
                                </span>
                              </div>
                              <i className="pi pi-circle-fill text-[5px] text-gray-300"></i>
                            </div>
                          ) : null}

                          <div className="flex flex-row gap-4px">
                            <span className="text-14  font-normal leading-5 text-gray-500">
                              {capitalizedFirst(t("refund.quantity"))}
                            </span>
                            <span className="text-14 font-medium leading-5 text-gray-900">
                              {el.quantity}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {i === orderData?.shops[0]?.products?.length - 1 ? null : (
                      <div className="mt-3 h-[1px] bg-gray-100"></div>
                    )}
                  </div>
                ))
              }
            </div>
            <RequestInfoView
              setSelectedReason={updateReason}
              selectedReason={selectedReason}
              reasons={reasons}
              description={description}
              updateDescription={(value: string) => setDescription(value)}
              files={files}
              updateFiles={(values: FileType[]) => setFiles(values)}
            />
          </div>
        ) : null}

        <div className="top-[72px] h-fit w-full md:sticky md:w-[32%]">
          <RequestRefundView
            isSubmit={!!selectedReason && !isClickSubmit}
            description={description}
            isRequest={true}
            refundAmount={getRefundAmount}
            handleRefund={handleRefund}
          />
        </div>
      </div>
    </div>
  )
}
