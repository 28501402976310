import React, { useEffect, useState } from "react"
import { Dialog } from "primereact/dialog"
import { ConfirmModalProps } from "./props"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { Dropdown } from "primereact/dropdown"
import { RootState } from "src/app/store"
import { useDispatch, useSelector } from "react-redux"
import enIcon from "src/assets/images/en-icon.png"
import viIcon from "src/assets/images/vi-icon.png"
import "./index.scss"
import { getRegions, getUserInfo, updateUserLanguage } from "src/services/user-service"
import { useNavigate } from "react-router-dom"
import i18n from "src/locales"
import { userType } from "src/features/auth/props"
import { signIn } from "src/features/auth"
import { updateCurrency } from "src/utils/convert-currency"
import { useToast } from "src/hooks/use-toast"
import { isIFrame } from "src/components/shareToken/types"
export interface LangDataType {
  value: string
  label: string
  icon: string
}
const currencyData = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "VND",
    label: "VND",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "AUD",
    label: "AUD",
  },
]


const lngData = [
  {
    value: "EN",
    label: "English",
    icon: enIcon,
  },
  {
    value: "VI",
    label: "Tiếng Việt",
    icon: viIcon,
  },
]

export interface RegionDataType {
  value: string
  label: string
}
export default function LanguageModal(props: ConfirmModalProps) {
  const { title, isShow, handleClose } = props
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const [currencyValue, setCurrencyValue] = useState<string>("USD")
  const [languageValue, setLanguageValue] = useState<string>("EN")
  const [region, setRegion] = useState<string>("US")
  const [isRender, setIsRender] = useState<boolean>(false)
  // const [regions, setRegions] = useState<RegionDataType[]>([])
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showToast = useToast()

  const getUser = () => {
    const userId = localStorage.getItem("userId")
    if(!userId) return
    return getUserInfo(userId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {        
        if (res?.data?.data) {
          // const langResult = res.data.data?.language
          //   ? res.data.data.language
          //   : "vn"
          const currentCurrency = res.data.data?.currency
            ? res.data.data?.currency
            : "USD"
          // i18n.changeLanguage(langResult.toLowerCase())
          const authData = {
            user: res?.data?.data as userType,
            access_token: localStorage.getItem("accessToken") || "",
          }
          
          updateCurrency(currentCurrency)
          if(currencyValue !== authStore?.value?.user?.currency || region !== localStorage.getItem("localRegion")) {
            showToast({
              detail: capitalizedFirst(t("policy-modal.update-success")),
              severity: "success",
            })
            dispatch(signIn(authData))
            if(region  !== localStorage.getItem("localRegion")) localStorage.setItem("localRegion", region)
            setTimeout(() => {
              navigate(0)
            }, 200)
            return
          }       
          showToast({
            detail: capitalizedFirst(t("policy-modal.update-success")),
            severity: "success",
          })
          dispatch(signIn(authData))   
          setIsSubmit(false)
          handleClose()
        }
      })
      .catch(() => {
        setIsSubmit(false)
        showToast({
          detail: capitalizedFirst(t("policy-modal.update-fail")),
          severity: "error",
        })
      })
  }
  const updateDataConnect = () => {
    const data = {
      currency: currencyValue,
      language: languageValue.toLowerCase(),
    }
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "changeLanguage",
        data: data,
      }
      console.log("Message changeLanguage", data)
      frame.contentWindow.postMessage(messageData, "*")      
    }
  }
  const handleApply = () => {
    setIsSubmit(true)
    updateUserLanguage(languageValue, currencyValue.toLowerCase()).then(() => {
      i18n.changeLanguage(languageValue.toLowerCase())
      updateDataConnect()      
      getUser()
    }).catch(() => {
      showToast({
        detail: capitalizedFirst(t("policy-modal.update-fail")),
        severity: "error",
      })
    }).finally(() => setIsSubmit(false) )
  }
  const footerContent = (
    <div
      className="mt-[24px] flex items-center justify-end gap-3 text-[16px] font-semibold"
      id="dialogFooter"
    >
      <button
        disabled={isSubmit}
        onClick={() => handleClose()}
        className={`rounded-3 border border-gray-300 px-[16px] py-[10px] text-gray-500 ${isSubmit ? "opacity-50" : ""}`}
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        disabled={isSubmit}
        onClick={handleApply}
        className={`rounded-3 gap-4px flex items-center border border-blue-600 bg-blue-600 px-[16px] py-[10px] text-white ${isSubmit ? "animate-pulse duration-75" : ""}`}
        autoFocus
      >
        {isSubmit ? <i className="pi pi-spin pi-spinner text-16 font-bold text-white"></i> : null}
        <span>{capitalizedFirst(t("shoppingPage.apply"))}</span>        
      </button>
    </div>
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRegions().then((res:any) => {
      if(res?.data?.data?.length) {
        const result = [] as RegionDataType[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.data.data.forEach((i:any) => {
          result.push({
            value: i?.country_code || "", 
            label: i?.country || "",
          })
        })
        // setRegions(result)
        setRegion(localStorage.getItem("localRegion") || "")
      }
    }).catch(err=>console.log(err)).finally(() => {
      if (authStore?.value?.user) {
        setCurrencyValue(authStore.value.user?.currency)
        setLanguageValue(i18n.language.toUpperCase())
      }
      setIsRender(true)
    })
  }, [])
  const itemTemplate = (option: LangDataType) => {
    if (option) {
      return (
        <div className="flex items-center gap-1">
          <img
            className="w-[20px] h-[20px] rounded-full"
            alt={option.value}
            src={option.icon}
          />
          <div>{option.label}</div>
        </div>
      )
    }

    return null
  }

  if(!isRender) return null
  return (
    <Dialog
      id="languageModal"
      header={title}
      visible={isShow}
      style={{ width: "480px" }}
      onHide={() => handleClose()}
      footer={footerContent}
      draggable={false}
      closable={false}
    >
      <div className="flex flex-col gap-[12px] px-[24px]">
        {/* <div className="flex flex-col">
          <span className="text-[14px] font-medium leading-[20px] text-gray-700">
            {capitalizedFirst(t("header.language-data.region"))}
          </span>
          <Dropdown
            filter
            optionLabel="label"
            value={region}
            options={regions}
            onChange={(e) => setRegion(e?.target?.value)}
            className="mt-1 flex h-[44px] items-center rounded-3 border border-gray-300 text-16 leading-24 text-gray-900"
          />
        </div> */}
        <div></div>
        <div className="flex flex-col">
          <span className="text-[14px] font-medium leading-[20px] text-gray-700">
            {capitalizedFirst(t("header.language"))}
          </span>
          <Dropdown
            value={languageValue}
            optionLabel="label"
            valueTemplate={itemTemplate}
            itemTemplate={itemTemplate}
            options={lngData}
            onChange={(e) => setLanguageValue(e?.target?.value)}
            className="mt-1 flex h-[44px] items-center rounded-3 border border-gray-300 text-16 leading-24 text-gray-900"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-[14px] font-medium leading-[20px] text-gray-700">
            {capitalizedFirst(t("header.language-data.currency"))}
          </span>
          <Dropdown
            value={currencyValue}
            optionLabel="label"
            options={currencyData}
            onChange={(e) => setCurrencyValue(e?.target?.value)}
            className="mt-1 flex h-[44px] items-center rounded-3 border border-gray-300 text-16 leading-24 text-gray-900"
          />
        </div>
      </div>
    </Dialog>
  )
}
