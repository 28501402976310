import React, { useState } from "react"
import storeIcon from "src/assets/images/store-icon.svg"
import ModalData from "../modal"
export interface DealVoucherType {
  id: string
  isShopOwner: boolean
}
const StoreModal = (props: DealVoucherType) => {
  const { id, isShopOwner } = props
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  return (
    <div className="flex h-[44px] w-[44px] items-center justify-center">
      <img
        onClick={() => setIsShowModal(true)}
        src={storeIcon}
        alt="storeIcon"
        className="h-[20px] w-[20px] cursor-pointer"
      />
      {isShowModal ? (
        <ModalData
          isShowModal={isShowModal}
          handleClose={() => setIsShowModal(false)}
          handleConfirm={() => {
            setIsShowModal(false)
          }}
          id={id}
          isShopOwner={isShopOwner}
        />
      ) : null}
    </div>
  )
}

export default StoreModal
