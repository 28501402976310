import React, { useRef, useMemo, useEffect } from "react"
import { Badge } from "primereact/badge"
import { Menu } from "primereact/menu"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { useNavigate, useLocation } from "react-router-dom"
import { ROUTERS } from "src/constants/router"
import { WebsocketInstance } from "src/utils/cart-socket-instance"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { configHeader } from "src/config/interceptors"
import { updateTotalCart } from "src/features/shopping-cart"
import "./index.scss"
import { SERVICE } from "src/constants/common"
import AvatarView from "./avatar-view"
import SettingView from "./setting-view"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import NotificationsView from "./notification-view"

const HeaderRightView = () => {
  const authStore = useSelector((state: RootState) => state.auth)
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const userMenuRef = useRef<Menu>(null)
  const navigate = useNavigate()

  const gotoCart = () => {
    if (location.pathname === ROUTERS.ORDER_CART) {
      navigate("/shopping/order-cart")
      navigate(0)
    } else {
      navigate("/shopping/order-cart")
    }
  }

  useEffect(() => {
    if (authStore.value && !WebsocketInstance.stompClient) {
      WebsocketInstance?._connect()
    } else if (!authStore.value && WebsocketInstance.stompClient) {
      WebsocketInstance?._disconnect()
    }
  }, [authStore.value, shoppingCartStore])

  const getInitCartTotal = () => {
    new CartControllerApi(configHeader(SERVICE.CART))
      .cartsTotalItemsGet()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        const result = res?.data?.data || 0
        dispatch(updateTotalCart(result))
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (authStore.value) {
      getInitCartTotal()
    }
  }, [])

  const countCart = useMemo(() => {
    if (authStore.value) {
      return shoppingCartStore.totalCart
    }
    const result = 0
    return result
  }, [shoppingCartStore, authStore.value])

  return (
    <div className="flex items-center justify-end gap-3">
      <div className="flex flex-1 gap-4px md:gap-3">
        <SettingView />
        {authStore?.value ? (
          <>
            <NotificationsView/>
            <div
              className="flex flex-1 items-center justify-center"
              onClick={gotoCart}
            >
              <i className="sctr-icon-shopping-cart-01 p-overlay-badge cursor-pointer text-[24px] text-gray-600 hover:text-blue-600">
                {countCart ? (
                  <Badge
                    style={{
                      minWidth: "20px",
                      width:"20px",
                      height:"20px",
                      fontSize: "8px",
                    }}
                    className="flex items-center justify-center p-0"
                    severity="danger"
                    value={countCart}
                  ></Badge>
                ) : null}
              </i>
            </div>
          </>
        ) : null}
      </div>

      <div className="flex items-center gap-2">
        <div className="text-xs flex min-w-fit cursor-pointer items-center gap-1 font-semibold text-gray-600">
          {!authStore?.value ? (
            <>
              <div className="flex items-center gap-1 text-[14px] font-semibold leading-[20px]">
                <button
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_HOME_DOMAIN_URL}/login?r=${window.location.href}`,
                      "_self"
                    )
                  }}
                  className="rounded-3 border border-blue-600 bg-blue-600 py-1 px-2 text-white hover:bg-blue-500"
                >
                  {capitalizedFirst(t("header-setting.sign-in"))}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_HOME_DOMAIN_URL}/sign-up`,
                      "_self"
                    )
                  }
                  className="hidden rounded-3 border border-gray-300 py-1 px-2 text-gray-900 hover:opacity-70 md:block"
                >
                  {capitalizedFirst(t("header-setting.sign-up"))}
                </button>
              </div>
            </>
          ) : (
            <div
              className="rounded-full border-[2px] border-orange-500"
              onClick={(e) => userMenuRef?.current?.toggle(e)}
            >
              <AvatarView />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default HeaderRightView
