/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import ButtonCustom from "../button-custom/button-custom/button-custom"
import box from "../../assets/images/box-icon.svg"
import followersImage from "../../assets/images/user-icon.svg"
import star from "../../assets/images/star-icon.svg"
import { useTranslation } from "react-i18next"
import { ButtonSeverity } from "src/constants/constants/button-severity"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

import ImgCustom from "../img-custom"
import {
  bookmarkProduct,
  deleteBookmark,
  getBloomFilter,
} from "src/services/bookmark-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useToast } from "src/hooks/use-toast"
import { handleCheckBloomFilter } from "src/utils/common"
import { ProfilePropsCategory } from "./props"
import { BOOKMARK_CATEGORY } from "src/constants/common"

export default function MerchantProfileCategory({
  id,
  avatar,
  name,
  rating,
  productNumber,
  description,
  followers,
}: ProfilePropsCategory) {
  const { t } = useTranslation()
  const showToast = useToast()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  const authStore = useSelector((state: RootState) => state.auth)
  const [newFollower, setNewFollower] = useState<number>(0)
  const [bloomData, setBloomData] = useState<string>("")
  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])

  useEffect(() => {
    setNewFollower(followers as number)
  }, [followers])

  const handleBookmark = async () => {
    if (authStore?.value && id) {
      if (bloomData) {
        if (!handleCheckBloomFilter(bloomData as string, id as string)) {
          bookmarkProduct(id, BOOKMARK_CATEGORY.SHOP)
            .then(() => {
              handleGetBloomData()
              setNewFollower(newFollower + 1)
            })
            .catch((error) => {
              throw error
            })
        } else {
          deleteBookmark(id)
            .then(() => {
              handleGetBloomData()
              setNewFollower(newFollower - 1)
            })
            .catch((error) => {
              error
            })
        }
      } else {
        bookmarkProduct(id, BOOKMARK_CATEGORY.SHOP)
          .then(() => {
            handleGetBloomData()
            setNewFollower(newFollower + 1)
          })
          .catch((error) => {
            throw error
          })
      }
    } else {
      showToast({ detail: t("product-details.login-before-bookmark"), severity: "warn" })
    }
  }

  return (
    <>
      {bloomData ? (
        <div className="flex w-fit items-center justify-between rounded-4 border-[1px] border-gray-200 bg-white">
          <div className="h-[105px] w-[130px] px-1 py-1">
            {avatar && (
              <ImgCustom
                url={avatar}
                alt="header"
                className="h-[100px] w-[105px] rounded-full object-cover object-center"
              />
            )}
          </div>
          <div className=" flex w-full flex-col gap-2 py-1">
            <div className="gap-12 flex justify-between">
              <div className="flex cursor-pointer justify-start gap-1 text-18 font-semibold">
                <div>{name}</div>
              </div>
              <div className="flex items-center justify-between gap-2 px-1">
                <ButtonCustom
                  classCustom="rounded-3 border-2 px-1 py-[1px] text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                  severity={ButtonSeverity.custom}
                >
                  <div className="flex items-center justify-between gap-1">
                    <i className="pi pi-comment text-14 font-semibold"></i>
                    <p className="text-14 font-semibold">
                      {capitalizedFirst(handleTranslate("global.chat"))}
                    </p>
                  </div>
                </ButtonCustom>
                {bloomData && id && handleCheckBloomFilter(bloomData, id) ? (
                  <ButtonCustom
                    classCustom="rounded-2 border py-[1px] px-[6px] border-blue-700 text-blue-700 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                    severity={ButtonSeverity.custom}
                  >
                    <div
                      onClick={handleBookmark}
                      className={"flex items-center justify-between gap-2"}
                    >
                      <p className="text-14 font-semibold ">
                        {capitalizedFirst(handleTranslate("global.followed"))}
                      </p>
                    </div>
                  </ButtonCustom>
                ) : (
                  <ButtonCustom
                    classCustom="rounded-2 border py-[1px] px-[6px] border-gray-300 text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                    severity={ButtonSeverity.custom}
                  >
                    <div
                      onClick={handleBookmark}
                      className={"flex items-center justify-between gap-2"}
                    >
                      <i
                        className="pi pi-plus "
                        style={{ fontSize: "0.75rem", fontWeight: 600 }}
                      ></i>
                      <p className="text-14 font-semibold ">
                        {capitalizedFirst(handleTranslate("global.follow"))}
                      </p>
                    </div>
                  </ButtonCustom>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-start sm:flex-row">
              <div className="flex items-center justify-start border-r border-gray-300 pl-4 pr-2 text-14 font-medium text-gray-600 sm:pl-0">
                <img className="mr-1 h-[20px] w-[20px]" src={star} alt="star" />
                <p>{rating}/5</p>
              </div>
              <div className="flex items-center justify-start gap-1 border-r border-gray-300 px-1 text-14 font-medium text-gray-600">
                <img
                  className="h-[20px] w-[20px]"
                  src={followersImage}
                  alt="follower"
                />
                <p>{capitalizedFirst(handleTranslate("global.follower"))}:</p>
                <p>{newFollower}</p>
              </div>
              <div className="flex items-center justify-start gap-1 px-2 text-14 font-medium text-gray-600">
                <img className="h-[20px] w-[20px]" src={box} alt="box" />
                <p>{capitalizedFirst(handleTranslate("global.products"))}: </p>
                <p>{productNumber}</p>
              </div>
            </div>
            <div className="text-12 font-normal text-gray-700">
              {capitalizedFirst(handleTranslate(description))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
