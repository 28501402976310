import React, { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import RantingsSummaryCustom from "src/components/ratings-summary/ratings-summary-custom"
import ProductDetailCustom from "src/components/shopping-homepage-view/product-detail-custom"
import ProductDetailInformation from "src/components/shopping-homepage-view/product-detail-information"
import ProductDetailRelated from "src/components/shopping-homepage-view/product-detail-related"
import { getProductDetail } from "../../../services/shopping-homepage-service/product-detail-service"
import { productDetailType, AttributesType, ReviewsType } from "./props"
import CommentView from "src/components/shopping-homepage-view/comment-view"
import ProductDetailGallery from "src/components/shopping-homepage-view/product-detail-gallery"
import SkeletonDetails from "src/components/skeleton-details"
import { NoteControllerApi } from "@soctrip/axios-rating-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import ProductDetailRelatedShop from "src/components/shopping-homepage-view/product-detail-related-shop"
import { useTranslation } from "react-i18next"
import ShopOnProductDetail from "src/components/shopping-homepage-view/product-detail-custom/shop-on-product-detail"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import ProductDetailAddOnDeal from "src/components/shopping-homepage-view/product-detail-add-on-deals"
import EmptyPage from "src/pages/empty-page"
import ProductDetailCatalog from "src/components/shopping-homepage-view/product-detail-catalog"
import { useSelector } from "react-redux"
import { ShopControllerApi } from "@soctrip/axios-shop-service"
import { RootState } from "src/app/store"
import { getBloomFilter } from "src/services/bookmark-service"
import i18n from "src/locales"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"
import { mathRoundNumber } from "src/utils/common"
import { Helmet } from "react-helmet-async"
import { detectProduct, detectProductByParam } from "src/services/tracking-service"
export interface NoteType {
  code: string
  content: string
  created_at: string
  created_by: string
  id: string
  type: string
  updated_at: string
  updated_by: string
}
export default function ProductDetail() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [proDetail, setProDetail] = useState<productDetailType>()
  const [starFilterSelected, setStarFilterSelected] = useState<number>(0)
  const [starFilter, setStarFilter] = useState<string>("")
  const elRef = useRef<HTMLDivElement>(null)
  const commentViewRef = useRef<HTMLDivElement>(null)
  const [notesFilter, setNotesFilter] = useState<NoteType[]>([])
  const [noteSelected, setNoteSelected] = useState<NoteType | undefined>(
    undefined
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isShopOwner, setIsShopOwner] = useState<boolean>(false)
  const [imgSelected, setImgSelected] = useState<string | undefined>("")
  const [bloomData, setBloomData] = useState<string>("")

  const navigate = useNavigate()
  const authStore = useSelector((state: RootState) => state.auth)
  const [isRender, setIsRender] = useState<boolean>(false)

  useEffect(() => {
    handleGetBloomData()
  }, [])

  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  useEffect(() => {
    getNotes()
  }, [i18n.language])

  useEffect(() => {
    if (authStore.value && proDetail?.shopDetail?.shopId) {
      new ShopControllerApi(configHeader(SERVICE.SHOP))
        .shopsMyShopGet()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data?.id) {
            const result = res.data.data.id === proDetail?.shopDetail?.shopId
            setIsShopOwner(result)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          if (!isRender) {
            setIsRender(true)
          }
        })
    } else {
      if (!isRender && !authStore.value) {
        setIsRender(true)
      }
    }
  }, [proDetail, authStore.value])

  useEffect(() => {
    if (starFilterSelected) {
      setStarFilter(`${starFilterSelected}`)
    } else {
      setStarFilter("")
    }
  }, [starFilterSelected])

  const detectCookie = () => {
    let result = ""
    const data = document.cookie.split(";")
    data.forEach(i => {
      if(i.includes("afincd") && i.split("=").length === 2) result = i.split("=")[1] || ""
    })
    return result
  }
  const fetchData = async () => {
    if (!id) return
    setIsLoading(true)
    getProductDetail(id)
      .then((res) => {
        if (res?.data?.data?.shopDetailDTO) {
          if (!res?.data?.data?.shopDetailDTO?.is_market_public) {
            navigate("/not-found")
            return
          }
        }
        if (res?.data?.data) {
          if (res?.data?.data?.is_admin_deleted) {
            navigate("/not-found")
            return
          }
          setProDetail({
            attachments: res?.data?.data?.attachments,
            id: res?.data?.data?.id,
            sum_review: res?.data?.data?.sum_review,
            price: res?.data?.data?.price,
            price_after_tax: res?.data?.data?.price_after_tax,
            original_price: res?.data?.data?.original_price,
            original_price_after_tax: res?.data?.data?.original_price_after_tax,
            point: res?.data?.data?.point,
            name: res?.data?.data?.name,
            catalogs: res?.data?.data?.catalogs,
            shopDetail: {
              shopImage: res?.data?.data?.shopDetailDTO?.avatar?.id,
              shopId: res?.data?.data?.shopDetailDTO?.id,
              rating: res?.data?.data?.shopDetailDTO?.rating,
              followers: res?.data?.data?.shopDetailDTO?.followers,
              name: res?.data?.data?.shopDetailDTO?.name,
              total_products: res?.data?.data?.shopDetailDTO?.total_products,
              location: res?.data?.data?.shop?.addresses[0],
              shopAddress: res?.data?.data?.shopDetailDTO?.province,
              badges: res?.data?.data?.shopDetailDTO?.badges?.length
                ? res?.data?.data?.shopDetailDTO?.badges[0]
                : undefined,
            },
            prices: res?.data?.data?.prices,
            stocks: res?.data?.data?.stocks,
            description: res?.data?.data?.description,
            attributes: res?.data?.data?.attributes,
            reviews: res?.data?.data?.reviews,
            quantity: res?.data?.data?.quantity,
            is_bookmark: res?.data?.data?.is_bookmark,
            products_related: res?.data?.data?.products_related,
            sold: res?.data?.data?.sold,
            products_combo: res?.data?.data?.products_combo,
            voucherShop: res?.data?.data?.shopDetailDTO?.vouchers,
            isFlashDeal:
              res?.data?.data?.is_flash_deal &&
              res?.data?.data?.flash_deal &&
              (res?.data?.data?.flash_deal?.max_sold || 0) -
                (res?.data?.data?.flash_deal?.sold || 0) >
                0,
            shopDetailDTO: res?.data?.data?.shopDetailDTO,
            limit_per_item: res?.data?.data?.limit_per_item,
          })
        }
        // tracking
        const afincd = detectCookie()
        if(id && afincd) {
          detectProductByParam(id, afincd).catch(err => console.log(err))
          detectProduct(id).catch(err => console.log(err))
        }
      })
      .catch((e) => {
        if (e?.response?.status === 404) navigate("/not-found")
      })
      .finally(() => setIsLoading(false))
  }
  const getNotes = async () => {
    new NoteControllerApi(configHeader(SERVICE.RATING))
      .notesTypeFirstPageGet("PRODUCT", 10, i18n.language || "en")
      .then((res) => {
        const result: NoteType[] = []
        if (res?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push(i as NoteType)
          })
        }
        setNotesFilter(result)
      })
      .catch((err) => console.log(err))
  }

  const getCatalog = useMemo(() => {
    if (proDetail?.catalogs && proDetail?.catalogs?.length) {
      return proDetail?.catalogs[0]?.global_id
    }
  }, [proDetail])

  const getTotalReviews = useMemo(() => {
    const result = {
      numberReviews: 0,
      total5StarReviews: 0,
      total4StarReviews: 0,
      total3StarReviews: 0,
      total2StarReviews: 0,
      total1StarReviews: 0,
      numberOfReviewsFor5Stars: 0,
      numberOfReviewsFor4Stars: 0,
      numberOfReviewsFor3Stars: 0,
      numberOfReviewsFor2Stars: 0,
      numberOfReviewsFor1Stars: 0,
    }
    if (proDetail && proDetail?.reviews && proDetail?.reviews.length) {
      proDetail?.reviews.forEach((i: ReviewsType) => {
        result.numberReviews += i?.count_review
        if (i.value === 5) {
          result.total5StarReviews = i?.average
          result.numberOfReviewsFor5Stars = i?.count_review
        }
        if (i.value === 4) {
          result.total4StarReviews = i?.average
          result.numberOfReviewsFor4Stars = i?.count_review
        }
        if (i.value === 3) {
          result.total3StarReviews = i?.average
          result.numberOfReviewsFor3Stars = i?.count_review
        }
        if (i.value === 2) {
          result.total2StarReviews = i?.average
          result.numberOfReviewsFor2Stars = i?.count_review
        }
        if (i.value === 1) {
          result.total1StarReviews = i?.average
          result.numberOfReviewsFor1Stars = i?.count_review
        }
      })
    }
    return result
  }, [proDetail])
  useEffect(() => {
    // GA POST ACTION
    if (!isLoading && proDetail) {
      gaPostAction(TAG_TYPE.VIEW_ITEM, {
        item_id: proDetail.id,
        item_name: proDetail.name,
        price: mathRoundNumber(proDetail.price_after_tax),
        item_category: proDetail.catalogs[0].name_global || "",
        quantity: 1,
      })
    }
  }, [isLoading, proDetail])

  if (isLoading) {
    return (
      <div className="h-full w-full">
        <SkeletonDetails />
      </div>
    )
  }

  const handleReloadData = () => {
    fetchData()
  }

  const updateFilterComment = (value: number) => {
    const result = value === starFilterSelected ? 0 : value
    setStarFilterSelected(result)
    clickIntoCommentView()
  }
  const updateNoteFilter = (value: NoteType) => {
    clickIntoCommentView()
    const result =
      value.id === noteSelected?.id ? undefined : (value as NoteType)
    setNoteSelected(result)
  }
  const clickIntoCommentView = () => {
    commentViewRef.current?.scrollIntoView({
      behavior: "smooth",
    })
  }

  const handleClickVariant = (img?: string) => {
    setImgSelected(img)
  }
  return (
    <>
      <Helmet>
        <title>{proDetail?.name}</title>
        <meta data-rh="true" name="description" content={proDetail?.name} />
        <meta
          data-rh="true"
          property="og:description"
          content={proDetail?.name}
        />
        <meta
          data-rh="true"
          name="twitter:description"
          content={proDetail?.name}
        />
        <meta
          data-rh="true"
          property="og:image"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          data-rh="true"
          property="og:image:type"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          data-rh="true"
          property="og:image:secure_url"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          data-rh="true"
          name="twitter:image"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
      </Helmet>
      <div className="mx-auto mt-3 max-w-[1200px] px-3">
        <ProductDetailCatalog
          catalogs={proDetail?.catalogs || []}
          shopDetail={proDetail?.shopDetail}
        />
        <div className="mb-4 flex flex-col">
          <div className="flex w-full max-w-[1200px] flex-col gap-2 lg:flex-row">
            <div className="top-[72px] flex h-fit w-full flex-col gap-3 lg:sticky lg:w-fit lg:min-w-[385px] lg:max-w-[485px]">
              <ProductDetailGallery
                Images={proDetail?.attachments || []}
                imgSelected={imgSelected}
              />
              <ShopOnProductDetail
                bloomData={bloomData}
                handleGetBloomData={handleGetBloomData}
                typeCard={CardCustomType.profile}
                imgUrl={proDetail?.shopDetail?.shopImage}
                shopName={proDetail?.shopDetail?.name}
                rating={proDetail?.shopDetail?.rating}
                shopId={proDetail?.shopDetail?.shopId}
                location={proDetail?.shopDetail?.shopAddress || ""}
                followers={proDetail?.shopDetail?.followers}
                isShopOwner={isShopOwner}
                badges={proDetail?.shopDetail?.badges}
                {...proDetail}
              />

              <ProductDetailAddOnDeal
                isShopOwner={isShopOwner}
                products_combo={proDetail?.products_combo}
                shopId={proDetail?.shopDetail?.shopId || ""}
              />
            </div>
            <div ref={elRef} className="flex w-full flex-1 flex-col gap-2">
              {proDetail ? (
                <ProductDetailCustom
                  {...proDetail}
                  isShopOwner={isShopOwner}
                  bloomData={bloomData}
                  handleGetBloomData={handleGetBloomData}
                  clickIntoCommentView={clickIntoCommentView}
                  onClickVariant={handleClickVariant}
                  handleReload={handleReloadData}
                  isRender={isRender}
                />
              ) : (
                <div className="h-auto w-full">
                  <EmptyPage message="Not found data. Please try again later." />
                </div>
              )}
            </div>
          </div>
          <div className={`${proDetail?.products_related && "py-4"}`}>
            <ProductDetailRelatedShop
              name_shop={proDetail?.shopDetail?.name}
              products_related={proDetail?.products_related}
              bloomData={bloomData}
              handleGetBloomData={handleGetBloomData}
            />
          </div>
          <div className="py-4">
            <ProductDetailInformation
              productDetailInformation={proDetail?.description as string}
              productDetailDescription={
                proDetail?.attributes as AttributesType[]
              }
            />
          </div>
          <div className="relative rounded-3 bg-white">
            <div
              ref={commentViewRef}
              className="absolute top-[-70px] left-[0px]"
            ></div>
            <p className="px-4 py-2 text-20 font-semibold text-gray-800">
              {capitalizedFirst(t("product-details.custiomer-review"))}
            </p>
            <div className="flex flex-col md:flex-row">
              <div className="w-full border-r  border-solid border-gray-100 md:w-[25%]">
                <RantingsSummaryCustom
                  totalStarRating={proDetail?.point || 0}
                  totalNumberOfReviews={proDetail?.sum_review || 0}
                  total5StarReviews={getTotalReviews.total1StarReviews}
                  total4StarReviews={getTotalReviews.total4StarReviews}
                  total3StarReviews={getTotalReviews.total3StarReviews}
                  total2StarReviews={getTotalReviews.total2StarReviews}
                  total1StarReviews={getTotalReviews.total1StarReviews}
                  numberOfReviewsFor5Stars={
                    getTotalReviews.numberOfReviewsFor5Stars
                  }
                  numberOfReviewsFor4Stars={
                    getTotalReviews.numberOfReviewsFor4Stars
                  }
                  numberOfReviewsFor3Stars={
                    getTotalReviews.numberOfReviewsFor3Stars
                  }
                  numberOfReviewsFor2Stars={
                    getTotalReviews.numberOfReviewsFor2Stars
                  }
                  numberOfReviewsFor1Stars={
                    getTotalReviews.numberOfReviewsFor1Stars
                  }
                  updateFilterComment={updateFilterComment}
                ></RantingsSummaryCustom>
                <div className="mt-1 flex flex-wrap px-3">
                  {notesFilter.map((note) => (
                    <div
                      onClick={() => updateNoteFilter(note)}
                      key={note.id}
                      className={`mb-2 mr-2 cursor-pointer rounded-5 border px-2 py-[7px] text-14 font-medium transition ${
                        noteSelected?.id == note.id &&
                        "bg-yellow-500 text-white"
                      }`}
                    >
                      {note.content}
                    </div>
                  ))}
                </div>
              </div>
              <div className="min-h-[unset] w-full py-2 md:min-h-[400px] md:w-[75%] md:py-0">
                <CommentView
                  starFilter={starFilter}
                  noteFilter={noteSelected?.id || ""}
                  scrollInToView={clickIntoCommentView}
                />
              </div>
            </div>
          </div>
          {proDetail && (
            <ProductDetailRelated
              point={3}
              catalogId={getCatalog}
              id={id}
              bloomData={bloomData}
              handleGetBloomData={handleGetBloomData}
            />
          )}
        </div>
      </div>
    </>
  )
}
