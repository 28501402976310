/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { getProductSearchFilter } from "src/services/shopping-homepage-service/shopping-search-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import CustomCard from "src/components/card-custom/card-custom/custom-card"
import { ProductDetailRelatedProps } from "./custom-card-props"
import { CustomCardProps } from "src/components/card-custom/card-custom/custom-card-props"
import { useInView } from "react-intersection-observer"

export default function ProductDetailRelated(props: ProductDetailRelatedProps) {
  const { t } = useTranslation()
  // const { catalogId, point, id, bloomData, handleGetBloomData } = props
  const { catalogId, id, bloomData, handleGetBloomData } = props
  const [products, setProducts] = useState<CustomCardProps[]>([])
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [totalProduct, setTotalProduct] = useState(10)
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    fetchData()
    setIsFirstLoad(false)
  }, [])

  const fetchData = async () => {
    const filters = encodeURIComponent(`str_catalog==${catalogId},id!=${id}`)
    // const filters = encodeURIComponent(`str_catalog==${catalogId},point>=${point},id!=${id}`)
    setIsLoading(true)
    try {
      const productsRes = await getProductSearchFilter(page, 10, filters, "")
      setTotalProduct(productsRes?.data?.data?.totalElement ?? 10)
      setIsLoading(false)
      // eslint-disable-next-line no-unsafe-optional-chaining
      setProducts((prev) => [...prev, ...productsRes?.data?.data?.data])
      setPage((prev) => prev + 1)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (inView && !isFirstLoad && page < 4) {
      handleLoadMore()
    }
  }, [inView])

  const handleLoadMore = () => {
    fetchData()
  }

  if (products?.length === 0) {
    return null
  }

  const detectStructView = (index: number) => {
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = products.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach(i => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === "ONGOGING" || i?.flash_deal_status === "UPCOMING" ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }


  return (
    <div className="py-4">
      <span className="pb-4 text-20 font-semibold text-gray-800">
        {capitalizedFirst(t("product-details.product-you-may-like"))}
      </span>
      <div className="relative mt-3">
        <div className="grid grid-cols-2 gap-2 md:grid-cols-5">
          {products?.map((pro, index) => (
            <div key={pro.id}>
              <div ref={ref}></div>
              <CustomCard
                key={`${pro.id}-${index}`}
                id={pro.id}
                typeCard={CardCustomType.product}
                imgUrl={pro.avatar?.id}
                avatar={pro.avatar}
                name={pro.name}
                point={pro.point}
                price={pro.price}
                price_after_tax={pro.price_after_tax}
                original_price={pro.original_price}
                original_price_after_tax={pro.original_price_after_tax}
                sum_review={pro.sum_review}
                quantity={pro.quantity}
                productId={pro.id}
                shop_name={pro.shop?.name}
                sub_label={pro.sub_label}
                shop={pro.shop}
                is_bookmark={pro.is_bookmark}
                handleReload={handleGetBloomData}
                bloom_filter={bloomData}
                sold={pro?.sold}
                is_flash_deal={pro.is_flash_deal}
                flash_deal_status={pro?.flash_deal_status}
                flash_deals={pro?.flash_deals}
                flash_deals_time={pro?.flash_deals_time}
                shop_id={pro?.shop_id}
                vouchers={pro?.vouchers}
                structView={detectStructView(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {!isLoading && (
        <div className="flex items-center justify-center">
          {products?.length < totalProduct ? (
            <div
              onClick={handleLoadMore}
              className="cursor-pointer py-1 text-14 font-medium text-blue-700"
            >
              {capitalizedFirst(t("shoppingPage.view-more"))}
            </div>
          ) : null}
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center py-4">
          <i className="pi pi-spin pi-spinner text-blue-500" style={{ fontSize: "1.5rem" }}></i>
        </div>
      )}
    </div>
  )
}
