export interface ProductType {
  productPrice: number
  productImg: string
  productName: string
  quantity: number
  variation: string
  index?: number
  id: string
  sku: string
}

export interface BillingDetailType {
  subtotal: number
  total: number
  delivery: number
  discount: number
  deliveryDiscount: number
  tax: number
}

export const STATE_VIEW = {
  INIT: "INIT",
  COMPLETE: "COMPLETE",
  FAIL: "FAIL",
  NOT_FOUND: "NOT_FOUND",
}

export interface Payment {
  payment_method: { code: string; name: string }
  vouchers: { id: string; name: string }[]
}
