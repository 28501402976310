import { Galleria } from "primereact/galleria"
import React, { useEffect, useState } from "react"
import ImgCustom from "../img-custom"
import { IMAGE_TYPE, SERVICE } from "src/constants/common"
import { configHeader } from "src/config/interceptors"
import { ShopControllerApi } from "@soctrip/axios-shop-service"
import { useToast } from "src/hooks/use-toast"
import { useNavigate } from "react-router-dom"
import { ROUTERS } from "src/constants/router"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { getClientBanners } from "src/services/banners-service"
import { slideshowDataInterface } from "./type"
import { handleCheckTime } from "src/utils/common"

export default function GalleriaHomePage() {
  const showToast = useToast()
  const navigate = useNavigate()
  const authStore = useSelector((state: RootState) => state.auth)
  const [slideshowData, setSlideshowData] = useState<slideshowDataInterface[]>([])

  const handleGoToShop = () => {
    if (authStore?.value) {
      new ShopControllerApi(configHeader(SERVICE.SHOP))
        .shopsMyShopGet()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data?.id) {
            window.open(
              `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/food-management/food-builder`,
              "_self"
            )
          } else {
            navigate("/shopping" + ROUTERS.REQUEST_PAGE)
          }
        })
        .catch((err) => console.log(err))
    } else {
      showToast({ detail: "Please login before continue", severity: "warn" })
    }
  }

  const handleGetBannersSlide = async () => {
    await getClientBanners("SHOP_PROMOTION_SLIDE_BANNER")
      .then((response) => {
        const results: slideshowDataInterface[] = []
        if (response?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data: any[] = response?.data?.data
          data?.map((item) => {
            if (handleCheckTime(item?.start_time, item?.end_time) && item.published) {
              const temp: slideshowDataInterface = {
                id: item?.medias?.[0]?.id,
                title: item?.reference_link?.[0]?.text,
                content: item?.reference_link?.[1]?.text,
                url: item?.url,
              }
              results.push(temp)
            }
          })
        }
        setSlideshowData(results)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    handleGetBannersSlide()
  }, [])

  return (
    <>
      {slideshowData?.length ? (
        <div className="h-full w-full">
          <Galleria
            value={slideshowData}
            showIndicators
            showIndicatorsOnItem
            showThumbnails={false}
            autoPlay
            circular
            transitionInterval={2500}
            item={(item) => {
              return (
                <a className="h-[300px] w-full" href={item?.url}>
                  <ImgCustom
                    className="relative h-[300px] w-full cursor-pointer rounded-4 object-cover object-center md:h-[300px]"
                    url={item?.id}
                    alt={item?.alt}
                    type={IMAGE_TYPE.WEB}
                  />
                  {item?.title ? (
                    <div
                      style={{ background: "rgba(0, 0, 0, 0.20)" }}
                      className="absolute bottom-0 left-6 top-1/2 flex h-fit min-w-[150px] max-w-[400px] -translate-y-1/2 transform flex-col gap-2 rounded-4 px-3 pt-3 pb-3 text-white"
                    >
                      <div className="font-[Judson700] text-24 leading-9 md:text-[32px]">
                        {item?.title}
                      </div>
                      <div className="text-12 font-normal leading-18 tracking-normal">
                        {item?.content}
                      </div>
                      {item?.isAction && (
                        <div>
                          <button
                            onClick={handleGoToShop}
                            type="button"
                            className="h-[44px] rounded-3 border border-white py-1 px-3 text-14 font-semibold leading-5"
                          >
                            {"Let's start"}
                          </button>
                        </div>
                      )}
                    </div>
                  ) : null}
                </a>
              )
            }}
          />
        </div>
      ) : null}
    </>
  )
}
